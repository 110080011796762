import { useEffect, useState } from "react";
import ReactHtmlParser from "html-react-parser";

const ClientSideRenderHTML = ({ html }: { html: string }) => {
  const [clientSideRenderedHtml, setClientSideRenderedHtml] = useState("");
  useEffect(() => setClientSideRenderedHtml(html), [html]);
  return <>{ReactHtmlParser(clientSideRenderedHtml)}</>;
};

export { ClientSideRenderHTML };
