import { StockLevels, type Store } from "../types";
import {
  coordinatesDistance,
  requestUserCoordinates,
} from "@/react-utils/xxl-geolocation";

export type CncInfo = {
  pickupTimeMessage: string;
  specialOpeningHoursMessage: string;
};

export const getDistanceFromUserLocation = (
  userCoordinates: GeolocationPosition,
  location: {
    latitude: number;
    longitude: number;
  }
) => {
  const { latitude, longitude } = location;
  const distanceFromUserLocation = coordinatesDistance.get(
    userCoordinates.coords.latitude,
    userCoordinates.coords.longitude,
    latitude,
    longitude
  );
  return distanceFromUserLocation;
};

export const sortDataByGeolocation = <T extends Store>(
  stores: T[],
  userCoordinates: GeolocationPosition
): T[] => {
  return stores.sort((storeA, storeB) => {
    const locationA = storeA.location;
    const locationB = storeB.location;

    if (locationA === null || locationB === null) {
      return 0;
    }

    const distanceToA = getDistanceFromUserLocation(userCoordinates, locationA);
    const distanceToB = getDistanceFromUserLocation(userCoordinates, locationB);

    if (distanceToA === undefined || distanceToB === undefined) {
      return 0;
    }

    return distanceToA - distanceToB;
  });
};

export const sortDataAlphabetically = (stores?: Store[]) =>
  stores?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

export const hasStock = ({ stockLevel }: Store): boolean =>
  stockLevel === StockLevels.IN_STOCK || stockLevel === StockLevels.LOW_STOCK;

export const isStoreDifferentThanSelected = (
  storeFromCart: Store | undefined,
  selectedStoreId: string | undefined
) => storeFromCart !== undefined && storeFromCart.id !== selectedStoreId;

export const getSortedStoresList = async (
  stores: Store[]
): Promise<Store[]> => {
  try {
    const userCoordinates = await requestUserCoordinates();
    return sortDataByGeolocation(stores, userCoordinates);
  } catch (_error) {
    return sortDataAlphabetically(stores);
  }
};

export const storeHasStock = ({ collectableStockLevel }: Store) =>
  collectableStockLevel !== StockLevels.OUT_OF_STOCK;
