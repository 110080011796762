import type * as CSSType from "csstype";
import "keen-slider/keen-slider.min.css";
import { forceCheck } from "react-lazyload";
import { useKeenSlider } from "keen-slider/react";
import type { KeenSliderOptions } from "keen-slider/react";
import React, { cloneElement, useEffect, useRef, useState } from "react";
import type { XxlMoveToSlideEventPayload } from "react-app/src/global";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import {
  addXXLEventListener,
  removeXXLEventListener,
} from "react-app/src/utils/xxl-event";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { Arrow } from "./Arrow";
import { ArrowLeftSmall, ArrowRightSmall } from "./ArrowSmall";
import type { SlidesConfig } from "./Slider.helper";
import {
  KEEN_SLIDER_CLASSES,
  MutationPlugin,
  calcNrOfDots,
  getTeaserOneIndex,
  getTeaserTwoIndex,
  initLoop,
  slidesDefaults,
} from "./Slider.helper";
import {
  ArrowsContainer,
  Container,
  FadeWrapper,
  Indicator,
  IndicatorContainer,
  ListItem,
  SliderList,
  type ArrowsNavigationProps,
} from "./Slider.styled";
import type { Variant } from "./ArrowSmall.styled";

const DOTS_INIT = 0;
const DEFAULT_TEST_ID = "xxl-slider";

const Navigation = {
  None: "NONE",
  Arrows: "ARROWS",
  ArrowsSmall: "ARROWS_SMALL",
  Dots: "DOTS",
  DotsAndArrows: "DOTS_AND_ARROWS",
} as const;

type NavigationMargin = {
  left?: number;
  right?: number;
  vertical?: number;
};
type Loop =
  | {
      shouldLoop: false;
    }
  | {
      shouldLoop: true;
      /**
       * Set this value to enable auto loop.
       */
      autoLoopTimeInMs?: number;
    };
type NavigationType = (typeof Navigation)[keyof typeof Navigation];

type SliderProps = {
  items: JSX.Element[];
  dotsContainerStyle?: React.CSSProperties;
  dragSpeed?: number;
  loadInitiallyOnlyFirstSlide?: boolean;
  loop?: Loop;
  mode?: KeenSliderOptions["mode"];
  navigation?: NavigationType;
  navigationMargin?: NavigationMargin;
  slidesConfig?: SlidesConfig;
  teasers?: {
    sliderContentMaxWidth: number;
  };
  testId?: string;
  showArrowsInContainer?: boolean;
  arrowsContainerProps?: ArrowsNavigationProps;
  initial?: number;
  SliderListStyles?: React.CSSProperties;
  disabled?: boolean;
  arrowSmallVariant?: Variant;
  containerPadding?: string;
};

const Slider = ({
  mode = "snap",
  loop = {
    shouldLoop: false,
  },
  slidesConfig,
  items,
  testId = DEFAULT_TEST_ID,
  navigation = Navigation.Dots,
  navigationMargin,
  loadInitiallyOnlyFirstSlide = false,
  dragSpeed = 0.5,
  dotsContainerStyle,
  teasers,
  showArrowsInContainer = false,
  arrowsContainerProps,
  initial = DOTS_INIT,
  SliderListStyles,
  disabled = false,
  arrowSmallVariant,
  containerPadding,
}: SliderProps) => {
  const [isJsLoaded, setIsJsLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [teaserConfig, setTeaserConfig] = useState<{
    isVisible: boolean;
    mainContainerStyle?: React.CSSProperties;
    sliderContainerMaxWidth?: CSSType.Property.MaxWidth;
  }>({
    isVisible: false,
  });
  const elementRef = useRef<HTMLDivElement>(null);
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const { mobile, desktop } = slidesDefaults;
  const slides = slidesConfig ?? (isMobile ? mobile : desktop);
  const [loaded, setLoaded] = useState(false);
  const nrOfDots = calcNrOfDots(items, slides);
  const dots = nrOfDots > 0 ? [...Array(nrOfDots).keys()] : [];

  const updateTeasers = () => {
    if (!isNotNullOrUndefined(teasers)) {
      return;
    }

    const { sliderContentMaxWidth } = teasers;
    const screenWidth =
      typeof window !== "undefined" ? document.documentElement.clientWidth : 0;
    const isVisible = screenWidth > sliderContentMaxWidth;
    const sideSpace = (screenWidth - sliderContentMaxWidth) / 2;
    const offset = sliderContentMaxWidth - sideSpace;
    const visualBannersCount = 3;

    setTeaserConfig({
      isVisible,
      mainContainerStyle: isVisible
        ? {
            display: "flex",
            flexDirection: "row",
            width: `${visualBannersCount * sliderContentMaxWidth}px`,
            position: "relative",
            left: `-${offset}px`,
            gap: "12px",
          }
        : undefined,
      sliderContainerMaxWidth: `${sliderContentMaxWidth}px`,
    });
  };

  const [sliderRef, instanceRef] = useKeenSlider<HTMLUListElement>(
    {
      drag: !disabled,
      mode,
      loop: loop.shouldLoop,
      slides,
      dragSpeed,
      initial,
      slideChanged(slider) {
        forceCheck(); // Ensure lazy loaded product images are displayed
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
        setCurrentSlide(DOTS_INIT);

        updateTeasers();
      },
      updated() {
        updateTeasers();
      },
    },
    [
      (slider) => {
        if (loop.shouldLoop !== true || loop.autoLoopTimeInMs === undefined) {
          return;
        }

        initLoop(slider, loop.autoLoopTimeInMs);
      },
      MutationPlugin,
    ]
  );

  useEffect(() => {
    const callback = ({
      detail: { index },
    }: CustomEvent<XxlMoveToSlideEventPayload>) => {
      const { perView = 0 } = slidesConfig ?? {};
      const maxIndexSliderCanNavigateTo = items.length - perView;
      instanceRef.current?.moveToIdx(
        Math.min(maxIndexSliderCanNavigateTo, index)
      );
    };

    addXXLEventListener("MOVE_SLIDER_TO_INDEX", callback);

    return () => removeXXLEventListener("MOVE_SLIDER_TO_INDEX", callback);
  }, [instanceRef, items, slidesConfig]);

  const handleLeftClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    instanceRef.current?.prev();
  };

  const handleRightClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    instanceRef.current?.next();
  };

  const handleClick = (idx: number) => {
    instanceRef.current?.moveToIdx(idx);
  };

  const isSliderReady = loaded && instanceRef.current !== null;
  const shouldShowDots =
    (navigation === Navigation.Dots ||
      navigation === Navigation.DotsAndArrows) &&
    isSliderReady &&
    dots.length > 0;
  const shouldShowArrows =
    (navigation === Navigation.Arrows ||
      navigation === Navigation.ArrowsSmall ||
      navigation === Navigation.DotsAndArrows) &&
    isSliderReady;
  const nrOfSlides = items.length - slides.perView;
  const shouldShowLeftArrow =
    shouldShowArrows && (currentSlide > 0 || loop.shouldLoop);
  const shouldShowRightArrow =
    shouldShowArrows && (nrOfSlides > currentSlide || loop.shouldLoop);

  useEffect(() => {
    const container = instanceRef.current?.container;
    if (!loaded || container === undefined) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      instanceRef.current?.update();
    });

    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, [instanceRef, loaded]);

  useEffect(() => {
    setIsJsLoaded(true);

    if (!isNotNullOrUndefined(teasers)) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      updateTeasers();
    });

    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, []);

  if (items.length === 0) {
    return null;
  }

  const lastItemIndex = items.length - 1;

  const Arrows = (): JSX.Element => (
    <>
      {shouldShowLeftArrow && (
        <>
          {navigation === "ARROWS" || navigation === "DOTS_AND_ARROWS" ? (
            <Arrow.Left
              onClick={handleLeftClick}
              horizontalMargin={
                showArrowsInContainer ? undefined : navigationMargin?.left
              }
              verticalMargin={
                showArrowsInContainer ? undefined : navigationMargin?.vertical
              }
            />
          ) : (
            <ArrowLeftSmall
              onClick={handleLeftClick}
              margin={
                showArrowsInContainer ? undefined : navigationMargin?.left
              }
              disabled={false}
              variant={arrowSmallVariant}
            />
          )}
        </>
      )}

      {shouldShowRightArrow && (
        <>
          {navigation === "ARROWS" || navigation === "DOTS_AND_ARROWS" ? (
            <Arrow.Right
              onClick={handleRightClick}
              horizontalMargin={
                showArrowsInContainer ? undefined : navigationMargin?.right
              }
              verticalMargin={
                showArrowsInContainer ? undefined : navigationMargin?.vertical
              }
            />
          ) : (
            <ArrowRightSmall
              onClick={handleRightClick}
              margin={
                showArrowsInContainer ? undefined : navigationMargin?.right
              }
              disabled={false}
              variant={arrowSmallVariant}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <div style={teaserConfig.mainContainerStyle}>
      {teaserConfig.isVisible && (
        <FadeWrapper fadeDirection="left">
          {cloneElement(items[getTeaserOneIndex(currentSlide, lastItemIndex)], {
            key: "teaserOne",
          })}
        </FadeWrapper>
      )}
      <Container containerPadding={containerPadding}>
        <div
          style={
            teaserConfig.isVisible
              ? { maxWidth: teaserConfig.sliderContainerMaxWidth }
              : undefined
          }
          ref={elementRef}
        >
          <SliderList
            ref={sliderRef}
            className={KEEN_SLIDER_CLASSES.Slider}
            data-testid={testId}
            style={SliderListStyles}
          >
            {items.map((item, index) => (
              <ListItem
                key={item.key ?? index}
                className={KEEN_SLIDER_CLASSES.Slide}
                style={{
                  display: isJsLoaded || index === 0 ? "list-item" : "none",
                }}
              >
                {loadInitiallyOnlyFirstSlide ? (
                  <>
                    {index === 0 ? (
                      item
                    ) : (
                      <>
                        {currentSlide === index ? (
                          item
                        ) : (
                          <div style={{ display: "none" }}>{item}</div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  item
                )}
              </ListItem>
            ))}
          </SliderList>
          {shouldShowDots && (
            <IndicatorContainer
              className={KEEN_SLIDER_CLASSES.Dots}
              style={dotsContainerStyle}
            >
              {dots.map((idx) => (
                <Indicator
                  key={idx}
                  aria-label={`slider-indicator-${idx + 1}`}
                  onClick={() => handleClick(idx)}
                  isActive={currentSlide === idx}
                />
              ))}
            </IndicatorContainer>
          )}
          {showArrowsInContainer ? (
            <ArrowsContainer
              className="arrows-container"
              arrowsContainerProps={arrowsContainerProps}
            >
              <Arrows />
            </ArrowsContainer>
          ) : (
            <Arrows />
          )}
        </div>
      </Container>
      {teaserConfig.isVisible && (
        <FadeWrapper fadeDirection="right">
          {cloneElement(items[getTeaserTwoIndex(currentSlide, lastItemIndex)], {
            key: "teaserTwo",
          })}
        </FadeWrapper>
      )}
    </div>
  );
};

export { Slider };
