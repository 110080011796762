import { xxlTheme } from "@/react-app/styles/xxl-theme";
import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${xxlTheme.spaces.micro};
`;

export const StickyFormContent = styled.div<{
  shouldShowStickyContent: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${xxlTheme.zIndex.navigation};
  transition: all 200ms ease-in-out;
  width: 100%;

  ${({ shouldShowStickyContent = false }) =>
    !shouldShowStickyContent && "bottom: -100%;"}
`;
