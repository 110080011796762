import { cookieCategories } from "react-app/src/utils/Cookie";
import {
  checkIfRequiredConsentsSelected,
  invokeAfterConsent,
} from "./invoke-after-consent";

const gtmScript = (gtmSrc: string) =>
  function (w = window, d = document, s = "script") {
    const dlStart = {
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    };

    w["dataLayer"] = w["dataLayer"] ?? [];
    w["dataLayer"].push(dlStart);
    const [f] = d.getElementsByTagName(s),
      j = d.createElement(s) as HTMLScriptElement;
    j.async = true;
    j.src = gtmSrc;

    if (f.parentNode !== null) {
      f.parentNode.insertBefore(j, f);
    }
  };

const initGtm = (id: string, serverGtmScriptUrl: string): void => {
  const gtmSrc =
    serverGtmScriptUrl.trim() !== ""
      ? serverGtmScriptUrl
      : `//www.googletagmanager.com/gtm.js?id=${id}`;

  const cookieConsentList = [
    cookieCategories.STATISTIC,
    cookieCategories.MARKETING,
  ];

  const areConsentsSelected =
    checkIfRequiredConsentsSelected(cookieConsentList);

  if (areConsentsSelected) {
    gtmScript(gtmSrc)();
  } else {
    invokeAfterConsent({
      callback: gtmScript(gtmSrc),
      cookieConsentList,
    });
  }
};

export { initGtm };
