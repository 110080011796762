import * as React from "react";
import { RadiobuttonWrapper } from "../../styled";
import { Fieldset, Legend } from "./ExpertReviewRadioButtons.styled";
import { KeyCode } from "../../utils/xxl-keyboard-code";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";

export type ExpertReviewProps = {
  onCheckboxSelected: (value: boolean) => void;
  isRequired?: boolean;
};

export const ExpertReviewRadioButtons: React.FunctionComponent<
  ExpertReviewProps
> = ({ isRequired, onCheckboxSelected }) => {
  const { t } = useTranslations();
  const [selectedValue, setSelectedValue] = React.useState<boolean>();
  const valueTrue = "true";
  const valueFalse = "false";

  const updateSelectedValue = (value: string): void => {
    const newValue = Boolean(value === valueTrue);
    setSelectedValue(newValue);
    onCheckboxSelected(newValue);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    value: string
  ): void => {
    const key = e.key;

    switch (key) {
      case KeyCode.SPACE:
      case KeyCode.ENTER: {
        updateSelectedValue(value);
        break;
      }
    }
  };

  return (
    <Fieldset>
      <Legend isRequired={isRequired}>
        {t("bundle.allow.changes.header")}
      </Legend>
      <RadiobuttonWrapper
        onKeyDown={(e): void => {
          handleKeyDown(e, valueTrue);
        }}
        tabIndex={0}
      >
        <input
          type="radio"
          name="allowChanges"
          id="allowChangesYes"
          checked={selectedValue === true}
          value={valueTrue}
          onChange={(e): void => {
            updateSelectedValue(e.target.value);
          }}
        />
        <label htmlFor="allowChangesYes">
          {t("bundle.allow.changes.message")}
        </label>
      </RadiobuttonWrapper>
      <RadiobuttonWrapper
        onKeyDown={(e): void => {
          handleKeyDown(e, valueFalse);
        }}
        tabIndex={0}
      >
        <input
          type="radio"
          name="allowChanges"
          id="allowChangesNo"
          checked={selectedValue === false}
          value={valueFalse}
          onChange={(e): void => {
            updateSelectedValue(e.target.value);
          }}
        />
        <label htmlFor="allowChangesNo">
          {t("bundle.allow.changes.message.no")}
        </label>
      </RadiobuttonWrapper>
    </Fieldset>
  );
};
