import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { Text } from "@/react-components/Text/Text";
import { useTranslationStoreStockStatus } from "../../Cnc/helpers";
import type { StockLevel } from "../../types";
import { StoreStockStatusIndicator } from "../StoreStockStyles";

export const StockStatusIndicatorComponent = ({
  stockStatus,
}: {
  stockStatus: StockLevel;
}) => {
  const tStoreStock = useTranslationStoreStockStatus(stockStatus);
  return (
    <XxlStack direction={"row"} alignItems={"center"}>
      <StoreStockStatusIndicator stockStatus={stockStatus} />
      <Text typography="small">{tStoreStock}</Text>
    </XxlStack>
  );
};
