import { useState, useEffect } from "react";
import { CART_LOADING_STATE } from "../types";
import { useCartContext } from "@/react-components/Cart/CartState";

export const useCartLoadingState = () => {
  const [cartLoadingState, setCartLoadingState] = useState<
    keyof typeof CART_LOADING_STATE
  >(CART_LOADING_STATE.READY);
  const {
    state: { isAddingToCart },
  } = useCartContext();
  useEffect(() => {
    if (isAddingToCart === false) {
      setCartLoadingState("READY");
    }
  }, [isAddingToCart]);

  return [cartLoadingState, setCartLoadingState] as const;
};
