import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { StyledCheckbox } from "@/react-app/styled/Checkbox/Checkbox.styled";
import { XxlButton } from "@/react-components/Common/XxlButton";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Text } from "@/react-components/Text/Text";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import { useState, type ChangeEvent, type ChangeEventHandler } from "react";
import { getPreferredStores } from "../../helpers";
import type { Store } from "../../types";
import { Dialog } from "../Dialog/Dialog";
import { hasStock } from "../StoreStock.helper";
import { StoreList } from "./StoreList";
import {
  BodyContainer,
  Footer,
  FormWrapper,
  LoadingIconWrapper,
  StoreInput,
} from "./StoreStockDialogStyles";
import { Loader } from "@xxl/icons";

type Props = {
  isDialogOpen: boolean;
  preferredStores?: Store[];
  sortedStores: Store[];
  toggleDialog: () => void;
  isLoading?: boolean;
};

const StoreStockDialog = ({
  isDialogOpen,
  sortedStores,
  toggleDialog,
  isLoading = false,
}: Props) => {
  const { t } = useTranslations();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const [onlyShowStoresWithStock, setOnlyShowStoresWithStock] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const filteredSortedStores = sortedStores.filter((store) => {
    if (onlyShowStoresWithStock && !hasStock(store)) {
      return false;
    }
    if (filterValue.length === 0) {
      return true;
    }
    return store.name
      .toLocaleLowerCase()
      .includes(filterValue.toLocaleLowerCase());
  });

  const preferredStores = getPreferredStores(filteredSortedStores);
  const remainingStores = filteredSortedStores.filter(
    (store) => !preferredStores.map((s) => s.id).includes(store.id)
  );

  const handleFilterInStock = (event: ChangeEvent<HTMLInputElement>) => {
    setOnlyShowStoresWithStock(event.target.checked);
  };

  const onFilterInputChange: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(target.value);
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={toggleDialog}
      heading={t("product.details.storestock.status")}
      fullScreen={isMobile}
    >
      <BodyContainer>
        <FormWrapper>
          <StoreInput
            onChange={onFilterInputChange}
            value={filterValue}
            disabled={false}
            placeholder={t("storeselect.search.placeholder")}
          />
          <StyledCheckbox
            testId="store-stock-show-instock"
            handleChange={handleFilterInStock}
            label={t("product.details.storestock.showall")}
          />
        </FormWrapper>
        {isLoading && (
          <LoadingIconWrapper>
            <Loader />
          </LoadingIconWrapper>
        )}
        {preferredStores.length > 0 && (
          <>
            <XxlStack pt={"24px"} />
            <Text typography="baseBold">
              {t("storeselect.selected.stores.header")}
            </Text>
            <StoreList stores={preferredStores} />
          </>
        )}
        {remainingStores.length > 0 && (
          <>
            <XxlStack pt={"24px"} />
            <Text typography="baseBold">
              {t("storefinder.warehouses.all.stores.checkbox.label")}
            </Text>
            <StoreList stores={remainingStores} />
          </>
        )}
      </BodyContainer>
      <Footer>
        <XxlStack width={isMobile ? "auto" : "124px"}>
          <XxlButton
            size="large"
            variant="secondary"
            onClick={toggleDialog}
            data-testid="store-stock-action-btn"
          >
            {t("general.close")}
          </XxlButton>
        </XxlStack>
      </Footer>
    </Dialog>
  );
};

export { StoreStockDialog };
