import React, { type RefObject } from "react";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { setInnerHtml } from "@/react-utils/xxl-set-inner-html";
import type { PaymentWidgetData } from "@/utils/server-side-cache/server-side-cache";
import { isWalley, useCartContext } from "@/react-components/Cart/CartState";

const DATA_ACTION_COLOR = "#99f500";
const DATA_WIDGET = "part-payment";

const createWalleyScript = (
  url: string,
  publicToken: string,
  price: number,
  dw: string,
  dac: string
) => `
  <script
    src="${url}"
    data-token="${publicToken}"
    data-widget=${dw}
    data-amount="${Math.round(price * 100)}"
    data-action-color=${dac}
  />
`;

export const useWalleyWidget = (
  ref: RefObject<HTMLDivElement>,
  pw: PaymentWidgetData | null,
  price: number
) => {
  const { state: cartState } = useCartContext();
  const [updateWalleyWidget, setUpdateWalleyWidget] = React.useState(false);

  React.useEffect(() => {
    if (isNotNullOrUndefined(pw)) {
      const { url, publicToken } = pw;
      if (ref.current !== null) {
        const scriptHTML = createWalleyScript(
          url,
          publicToken,
          price,
          DATA_WIDGET,
          DATA_ACTION_COLOR
        );

        setInnerHtml(ref.current, scriptHTML, true);
        setUpdateWalleyWidget(true);
      }
    }

    if (updateWalleyWidget) {
      window.walley?.checkout.api.update();
    }

    return () => {
      setUpdateWalleyWidget(false);
    };
  }, [pw, price, ref, updateWalleyWidget]);
  return isWalley(cartState.paymentProvider);
};
