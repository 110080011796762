type CartDiscountTotals = {
  discountType: string;
  totalDiscountAmount: number;
};

const CUSTOMER_GROUP = "CustomerGroup"; // Means Employees

export const getEmployeeDiscount = (discounts: CartDiscountTotals[]) =>
  discounts.find((discount) => discount.discountType === CUSTOMER_GROUP)
    ?.totalDiscountAmount ?? 0;

/**
 *  Get the total discount amount for all discounts that are not customer group (aka employee) discounts
 *  @param discounts - The discounts to calculate the total discount amount for
 *  @returns The total discount amount for all discounts that are not customer group (aka employee) discounts
 */
export const getRemainingDiscountTotal = (discounts: CartDiscountTotals[]) =>
  discounts.reduce((acc, cur) => {
    if (cur.discountType !== CUSTOMER_GROUP) {
      return (acc += cur.totalDiscountAmount);
    }
    return acc;
  }, 0);
