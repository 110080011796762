import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import type { Store } from "../../types";
import { StoreListComponent } from "./StoreListAccordion";
import { StoreSelectionWrapper } from "./StoreStockDialogStyles";

export const StoreList = ({ stores }: { stores: Store[] }) => {
  const { t } = useTranslations();
  return (
    <StoreSelectionWrapper>
      <StoreListComponent.Accordion>
        {stores.map((store, idx) => (
          <>
            <StoreListComponent.Heading
              idx={idx}
              storeName={store.name}
              stockLevel={store.stockLevel}
              generalAddressAndHoursText={t(
                "general.address.and.opening.hours"
              )}
              data-testid={`store-list-accordion-heading-${idx}`}
            />
            <StoreListComponent.Body idx={idx} store={store} />
          </>
        ))}
      </StoreListComponent.Accordion>
    </StoreSelectionWrapper>
  );
};
