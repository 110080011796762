import { useState } from "react";
import { Slider } from "react-app/src/components/Common/Slider";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { NextImage } from "../../common/NextImage/NextImage";
import { INITIAL_IMAGE_SLIDE_MOBILE } from "../constants";
import { ImageDialog } from "./ImageDialog";
import { getSliderImages } from "./ProductImageSliderMobile.helper";
import { UpsaleLinkComponent } from "./UpsaleLinkComponent";
import type { ProductImageSliderInnerComponentProps } from "./types";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { xxlTheme } from "@/react-app/styles/xxl-theme";

export const ProductImageSliderMobile = ({
  productImages = [],
  productName,
  children,
  shouldShowUpsaleLink,
}: ProductImageSliderInnerComponentProps) => {
  const { t } = useTranslations();
  const [initialSlideIndex, setInitialSlideIndex] = useState(
    INITIAL_IMAGE_SLIDE_MOBILE
  );
  const [open, setOpen] = useState(false);

  const handleClickOpen = (img: string) => {
    setOpen(true);
    const index = productImages.findIndex((image) => image === img);
    setInitialSlideIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const images = getSliderImages(productImages);
  const priorityImages = new Set([images.length - 1, 0, 1]);

  const sliderItems = images.map((productImage, idx) => (
    <XxlStack
      key={`${idx}-${productImage}`}
      maxHeight={"280px"}
      alignItems={"center"}
      justifyContent={"center"}
      style={{ background: xxlTheme.colors.xxlLightGrey }}
      py={"10px"}
    >
      <NextImage
        src={productImage}
        alt={`${productName} - ${t("product.details.alt.image.full")} ${idx + 1}`}
        height={280}
        width={280}
        style={{ height: "260px" }}
        onClick={() => handleClickOpen(productImage)}
        priority={priorityImages.has(idx)}
        sizes="100vw"
      />
    </XxlStack>
  ));

  shouldShowUpsaleLink && sliderItems.push(<UpsaleLinkComponent />);

  return (
    <>
      {/* {campaignComponent !== null && (
          <SliderBanner.Splash>{campaignComponent}</SliderBanner.Splash>
        )} */}
      {children}
      <Slider
        items={sliderItems}
        slidesConfig={{ perView: 1.2, spacing: 4, origin: "center" }}
        loop={{ shouldLoop: true }}
      />
      <ImageDialog
        initialSlideIndex={initialSlideIndex}
        handleClose={handleClose}
        productImages={productImages}
        open={open}
      />
    </>
  );
};
