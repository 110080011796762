import { useEffect, useRef, useState } from "react";
import { useSessionSource } from "react-app/src/contexts/Session";
import { useStateValue } from "cotton-box-react";
import type { PriceComparatorWidgetConf } from "./PriceComparatorWidget.type";

export type PriceComparatorWidgetProps = {
  /** @param config PriceComparatorWidget configuration */
  config: PriceComparatorWidgetConf;
  /** @param productCode the Size Code, format like: 1147574_1_Size_1 */
  productCode: string;
};

export const PriceComparatorWidget = ({
  config: { market, shopId },
  productCode,
}: PriceComparatorWidgetProps) => {
  // The price widget will be updated with its own content. Using this flag to avoid render diff on server vs client.
  const [shouldRender, setShouldRender] = useState(false);
  const widgetRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = useStateValue(useSessionSource);

  useEffect(() => {
    setShouldRender(true);
    return () => {
      setShouldRender(false);
    };
  }, []);

  useEffect(() => {
    const elementExists = widgetRef.current !== null;

    if (shouldRender && elementExists) {
      window.prisjakt_cheapest?.reload();
    }
  }, [shouldRender, isLoggedIn]);

  if (!shouldRender) {
    return null;
  }

  return (
    // This outer div is needed because the widget injects a link which will mess up the layout.
    <div>
      <div
        ref={widgetRef}
        className="prisjakt_cheapest"
        data-shop={shopId}
        data-product={productCode}
        data-market={market}
        data-format="small3"
      />
    </div>
  );
};
