import type { Store } from "@xxl/content-api";

const COMMA_SIGN_SEPARATOR = ", ";
const SPACE_REGEX = /([^,\s])\s+/g;
const SPACE_REGEX_REPLACER = "$1-";

const specialCharTranslationMap: ReadonlyMap<string, string> = new Map<
  string,
  string
>([
  ["æ", "ae"],
  ["å", "a"],
  ["ä", "a"],
  ["ö", "o"],
  ["ø", "o"],
  ["ü", "u"],
  ["ß", "ss"],
]);

const getFullStoreUrl = (
  storeFinderUrl: string,
  storePrefixUrl: string,
  storeUrl: string
) => {
  return `${storeFinderUrl}/${storePrefixUrl}${storeUrl}`;
};

const generateUrlWithStoreSeoSettings = (
  seoCity: string,
  seoLocation?: string
): string => {
  let storeMainPath = seoCity;

  if (seoLocation !== undefined) {
    storeMainPath = storeMainPath.concat("/", seoLocation);
  } else {
    storeMainPath = storeMainPath.concat("/", storeMainPath);
  }

  return storeMainPath;
};

const convertSpecialChars = (text: string): string => {
  specialCharTranslationMap.forEach((value: string, key: string) => {
    const allOccurrencesOfSpecialChar = new RegExp(key, "g");
    text = text.replace(allOccurrencesOfSpecialChar, value);
  });

  return text;
};

const generateStoreSimplifiedName = (storeName: string): string => {
  let convertedName = convertSpecialChars(storeName.toLowerCase());
  convertedName = convertedName.replace(SPACE_REGEX, SPACE_REGEX_REPLACER);

  return convertedName;
};

const generateUrlWithSimpleStoreName = (storeName: string): string => {
  let storeMainPath = generateStoreSimplifiedName(storeName);
  storeMainPath = storeMainPath.replace(COMMA_SIGN_SEPARATOR, "/");
  if (!storeMainPath.includes("/")) {
    storeMainPath = storeMainPath.concat("/", storeMainPath);
  }
  return storeMainPath;
};

const GenerateStoreUrlPath = (store: Store): string => {
  let storeUrl = "";
  if (store.seoSettings?.seoCity !== undefined) {
    storeUrl = generateUrlWithStoreSeoSettings(
      store.seoSettings.seoCity,
      store.seoSettings.seoLocation
    );
  } else {
    if (store.name !== undefined) {
      storeUrl = generateUrlWithSimpleStoreName(store.name);
    }
  }

  return storeUrl;
};

export { GenerateStoreUrlPath, getFullStoreUrl };
