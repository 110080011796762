import styled from "@emotion/styled/macro";

type RadioButtonWrapperProps = {
  isDisabled?: boolean;
};

const SIZE = 18;
const INNER_CIRCLE_PADDING = 4;
const BORDER_SIZE = 2;

const styling = ({ checked }: { checked: boolean }) => `
  /* outer circle */
  &::before {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: ${SIZE}px;
    height: ${SIZE}px;
    background: var(--color-xxl-white);
    border: ${BORDER_SIZE}px solid var(--color-xxl-gray);
    border-radius: 100%;
    content: "";
  }

  /* inner circle */
  &::after {
    position: absolute;
    top: 4px;
    left: 4px;
    width: ${SIZE - INNER_CIRCLE_PADDING - BORDER_SIZE * 2}px;
    height: ${SIZE - INNER_CIRCLE_PADDING - BORDER_SIZE * 2}px;
    background: var(--color-xxl-web-gray);
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    content: "";
    transform: scale(${checked ? 1 : 0});
    opacity: ${checked ? 1 : 0};
  }
`;

const Radiobutton = styled.div<{ checked: boolean }>`
  ${({ checked }) => styling({ checked })}
  height: ${SIZE}px;
  width: ${SIZE}px;
`;

const RadiobuttonWrapper = styled.div<RadioButtonWrapperProps>`
  input {
    display: none;
    margin-bottom: 10px;

    & + label {
      position: relative;
      display: inline-block;
      margin: 0 0 0 20px;
      padding-left: 28px;
      color: currentColor;
      font-size: 1.1rem;
      font-family: var(--font-family-regular);
      line-height: 20px;
      cursor: ${({ isDisabled = false }) =>
        isDisabled ? "default" : "pointer"};

      &:focus {
        outline: grey dotted 1px;
      }

      ${styling({ checked: false })}

      & > a {
        color: #000;
        font-family: var(--font-family-medium);
        text-decoration: none;
      }
    }

    &:not(:checked) + label::after {
      transform: scale(0);
      opacity: 0;
    }

    &:checked + label::after {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export { Radiobutton, RadiobuttonWrapper };
