import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { WriteReviewLink } from "./WriteReview";
import { StarRating } from "react-app/src/components/RatingsAndReviews/Reviews/StarRating";

type Props = {
  averageRating: number | null;
  onClick?: () => void;
  recommendationPercentage?: number | null;
  shouldShowWriteReviewLink: boolean;
};
export const AverageRating = ({
  averageRating,
  onClick = undefined,
  recommendationPercentage,
  shouldShowWriteReviewLink,
}: Props) => {
  if (averageRating === null) {
    return shouldShowWriteReviewLink ? <WriteReviewLink /> : null;
  }

  return (
    <StarRating
      onClick={onClick}
      data-testid="rr-average-rating-wrapper"
      value={averageRating}
      readOnly={true}
      recommendationPercentage={recommendationPercentage}
      sx={{
        fontSize: `${xxlTheme.typography.smallRegular.fontSize}px`,
      }}
    />
  );
};
