import { useSharedData } from "../contexts/SharedData";

export const useAmplifyConfig = () => {
  const {
    data: {
      configuration: {
        amplifyConfig: { aws_appsync_apiKey, aws_appsync_graphqlEndpoint },
      },
    },
  } = useSharedData();
  return { aws_appsync_apiKey, aws_appsync_graphqlEndpoint };
};
