const BACKGROUND_COLOR = "efefef";
const BACKGROUND_TRANSPARENT = "transparent";
const IMAGE_QUALITY_DEFAULT = 75;
const RESIZE_PARAM = "resize";
const BACKGROUND_COLOR_PARAM = "bgcolor";

type BgColor = typeof BACKGROUND_COLOR | typeof BACKGROUND_TRANSPARENT; // Add more colors here when needed

type FilespinOptions = {
  width: number;
  height: number;
  bgcolor?: BgColor;
  quality?: number; // 0-100
  format?: "jpg" | "png" | "webp" | "gif";
  trim?: number; // 0-10
  rotate?: 90 | 180 | 270;
  flip?: "h" | "v";
  grayscale?: boolean;
  blur?: number | boolean; // 0-150, default: 20
  roundcorner?: boolean;
  chalk?: boolean;
};

export const createFsQueryParams = (fsOptions: FilespinOptions) => {
  const { bgcolor = BACKGROUND_COLOR, quality, width, height } = fsOptions;

  const p = new URLSearchParams({
    quality: quality?.toString() ?? IMAGE_QUALITY_DEFAULT.toString(),
    ...(bgcolor !== BACKGROUND_TRANSPARENT && {
      bgcolor,
    }),
    [RESIZE_PARAM]: `${width},${height}`,
  });

  for (const [k, v] of Object.entries(fsOptions)) {
    if (
      k === "width" ||
      k === "height" ||
      (k === BACKGROUND_COLOR_PARAM && v === BACKGROUND_TRANSPARENT)
    ) {
      continue;
    } else {
      p.set(k, v.toString());
    }
  }

  return p;
};

const sanitizeBaseUrl = (s: string) => s.split("?")[0]; // Removes any query params from the baseUrl before adding the new params

type GetFileSpinUrl = {
  baseUrl: string;
} & FilespinOptions;

export const getFileSpinUrl = (args: GetFileSpinUrl) => {
  const { baseUrl, ...options } = args;
  return `${sanitizeBaseUrl(baseUrl)}?${createFsQueryParams(options).toString()}`;
};
