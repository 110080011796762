import {
  isEmpty,
  isNotNullOrUndefined,
  isNotUndefined,
  isNullOrUndefined,
} from "@xxl/common-utils";
import type { CountdownTimerSettings } from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";
import type {
  Classification,
  ProductLink,
  ProductPriceDisplayData,
} from "@xxl/pim-api";
import {
  Channel,
  StockStatus,
  type ProductData,
} from "@xxl/product-search-api";
import { useState } from "react";
import { StockLevels } from "react-app/src/components/ClickAndCollect/api/api";
import { PERSONALIZED_PRODUCT_LIST_QUERY_NAME } from "react-app/src/components/Product/constants";
import type { Trackers } from "react-app/src/contexts/Tracking";
import type { GetProductSizesStockStatusQuery } from "react-app/src/generated/graphql-code-generator";
import { colorsAsCssVariable } from "react-app/src/styles/theme/colors";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import type { CampaignCountdownProps } from "./ProductImageSlider/CampaignSplash/CampaignCountdown/CampaignCountdown";
import type { StrictClassification, StrictPriceDisplay } from "./types";

const { IN_STOCK, LOW_STOCK, OUT_OF_STOCK } = StockLevels;

export const createTimerProps = (
  settings: CountdownTimerSettings | null,
  fontColor?: string
): CampaignCountdownProps | null => {
  if (settings === null) {
    return null;
  }
  const { date, startDate, isOnlyHours } = settings;

  if (date === undefined) {
    log.error("Countdown property 'date' is undefined");
    return null;
  }

  return {
    convertDaysToHours: isOnlyHours ?? false,
    endDate: new Date(date),
    fontColor: fontColor ?? colorsAsCssVariable.xxlBlack,
    startDate: startDate !== undefined ? new Date(startDate) : undefined,
  };
};

// eslint-disable-next-line import/no-unused-modules -- used in tests
export const isStrictPriceDisplay = (
  ppd: unknown
): ppd is StrictPriceDisplay => {
  const { salesPrice, salesPriceFormatted, type } =
    ppd as ProductPriceDisplayData;
  return (
    salesPrice !== undefined &&
    salesPriceFormatted !== undefined &&
    type !== undefined
  );
};

export const createFrontendPriceDisplays = (
  priceDisplays: ProductPriceDisplayData[]
): StrictPriceDisplay[] => {
  return priceDisplays.map((ppd) => {
    if (isStrictPriceDisplay(ppd)) {
      return ppd;
    }

    throw Error("Price display is missing required properties.");
  });
};

export const createClassifications = (
  classifications: Classification[]
): StrictClassification[] => {
  if (classifications.length === 0) {
    return [];
  }

  return classifications
    .map(({ name, value, valueType, id, unit }) => {
      if (
        isNullOrUndefined(name) ||
        isNullOrUndefined(value) ||
        isNullOrUndefined(valueType) ||
        isNullOrUndefined(id)
      ) {
        log.error(
          `Missing classification properties. ${typeof name}, ${typeof value}, ${typeof valueType}, ${typeof id},`
        );
        return;
      }
      return {
        id,
        name,
        value,
        valueType,
        unit: unit ?? null,
      };
    })
    .filter(isNotUndefined);
};

export const getColorTheme = (priceDisplay: {
  colourTheme?: {
    backgroundColour?: string;
    foregroundColour?: string;
    name?: string;
  };
  colorTheme?: {
    backgroundColor?: string;
    foregroundColor?: string;
    name?: string;
  };
}) => {
  const colors = {
    name: "",
    backgroundColor: "",
    foregroundColor: "",
  };
  if ("colourTheme" in priceDisplay) {
    if (
      priceDisplay.colourTheme?.backgroundColour !== undefined &&
      priceDisplay.colourTheme.foregroundColour !== undefined
    ) {
      colors.backgroundColor = priceDisplay.colourTheme.backgroundColour;
      colors.foregroundColor = priceDisplay.colourTheme.foregroundColour;
      colors.name = priceDisplay.colourTheme.name ?? "";
      return colors;
    }
  }
  if (isNotNullOrUndefined(priceDisplay.colorTheme)) {
    if (
      priceDisplay.colorTheme.backgroundColor !== undefined &&
      priceDisplay.colorTheme.foregroundColor !== undefined &&
      priceDisplay.colorTheme.foregroundColor !== ""
    ) {
      colors.backgroundColor = priceDisplay.colorTheme.backgroundColor;
      colors.foregroundColor = priceDisplay.colorTheme.foregroundColor;
      colors.name = priceDisplay.colorTheme.name ?? "";
      return colors;
    }
  }
  return colors;
};

export const getComponentSpacing = ({
  isLaptopSize,
}: {
  isLaptopSize: boolean;
}) => (isLaptopSize ? xxlTheme.spaces.large : xxlTheme.spaces.smallRegular);

export const useIsSizeSelectDrawerOpen = () => {
  const [isSizeSelectDrawerOpen, setIsSizeSelectDrawerOpen] = useState(false);
  const toggleIsSizeSelectDrawerOpen = () =>
    setIsSizeSelectDrawerOpen(!isSizeSelectDrawerOpen);
  return {
    isSizeSelectDrawerOpen,
    setIsSizeSelectDrawerOpen,
    toggleIsSizeSelectDrawerOpen,
  };
};

export const getProductListName = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(PERSONALIZED_PRODUCT_LIST_QUERY_NAME);
};

export const convertProductLink = (productLink?: ProductLink) =>
  isNotNullOrUndefined(productLink) &&
  isNotNullOrUndefined(productLink.title) &&
  isNotNullOrUndefined(productLink.url)
    ? { title: productLink.title, url: productLink.url }
    : null;

export const pushProductViewEvent = ({
  trackers,
  brandName,
  googleCategory,
  productCode,
  productName,
  productStyle,
  salesChannelAvailability,
  salesPrice,
  listName,
}: {
  trackers: Trackers;
  brandName: string;
  googleCategory: string;
  productCode: string;
  productName: string;
  productStyle: string;
  salesChannelAvailability: string;
  salesPrice?: number;
  listName: string | null;
}) => {
  const product = {
    name: productName,
    id: productCode,
    price: salesPrice ?? 0,
    brand: brandName,
    category: googleCategory,
    variant: productStyle,
    salesChannelAvailability,
  };
  trackers.sendProductViewEvent({
    product,
    listName: listName ?? "",
  });
};

const getSalesChannelAvailabilityForElevate = (product: ProductData) => {
  if (product.isGraveyard) {
    return "Archived";
  }

  const selectedVariant =
    product.variants.find(({ isSelected }) => Boolean(isSelected)) ??
    product.variants.at(0);

  const isAvailableOnline = Boolean(
    selectedVariant?.availability.find(
      ({ channel }) => channel === Channel.ONLINE
    )?.stockStatus !== StockStatus.OUTOFSTOCK
  );

  const isAvailableInStore = Boolean(
    selectedVariant?.availability.find(
      ({ channel, stockStatus }) =>
        channel === Channel.STORE && stockStatus !== StockStatus.OUTOFSTOCK
    )
  );

  if (!isAvailableInStore && isAvailableOnline) {
    return "Online only";
  }
  if (isAvailableInStore && !isAvailableOnline) {
    return `Store only ${
      product.isOnlyAvailableInStoreNoClickAndCollect === true
        ? "without"
        : "with"
    } Click and Collect`;
  }
  if (isAvailableInStore && isAvailableOnline) {
    return "Online and store";
  }
  return "Unknown";
};

type pushElevateProductViewEventProps = {
  trackers: Trackers;
  elevateProduct: ProductData;
};
export const pushElevateProductViewEvent = ({
  trackers,
  elevateProduct,
}: pushElevateProductViewEventProps) => {
  const listName = getProductListName() ?? "";
  const googleCategory = elevateProduct.categoryBreadcrumbs
    .map(({ name }) => name)
    .join(" / ");
  const brandName = elevateProduct.brand?.name ?? "";
  const productName = elevateProduct.title;
  const productCode = elevateProduct.code;
  const salesPrice = elevateProduct.price.selling.range.min.value;
  const productSize =
    elevateProduct.variants.find(({ isSelected }) => isSelected === true)
      ?.sizeCode ??
    elevateProduct.variants.at(0)?.sizeCode ??
    "";
  const salesChannelAvailability =
    getSalesChannelAvailabilityForElevate(elevateProduct);
  const product = {
    name: productName,
    id: productCode,
    price: salesPrice,
    brand: brandName,
    category: googleCategory,
    variant: productSize,
    salesChannelAvailability,
  };
  trackers.sendProductViewEvent({
    product,
    listName: listName,
  });
};

export const getSalesChannelAvailability = ({
  isEverySizeOutOfStockOnlineAndInStores,
  multiChannelAvailability,
  onlyAvailableInStoreNoClickAndCollect,
}: {
  isEverySizeOutOfStockOnlineAndInStores: boolean;
  multiChannelAvailability: string[];
  onlyAvailableInStoreNoClickAndCollect: boolean;
}) => {
  const EXPECTED_NUMBER_OF_ITEMS = 2;
  if (isEverySizeOutOfStockOnlineAndInStores) {
    return "Archived";
  }
  if (
    isEmpty(multiChannelAvailability) ||
    multiChannelAvailability.length > EXPECTED_NUMBER_OF_ITEMS
  ) {
    return "Unknown";
  }
  if (multiChannelAvailability.every((item) => item === "ONLINE")) {
    return "Online only";
  }
  if (multiChannelAvailability.every((item) => item === "STORE")) {
    return `Store only ${
      onlyAvailableInStoreNoClickAndCollect ? "without" : "with"
    } Click and Collect`;
  }
  return "Online and store";
};

const getInitialStockStatus = ({
  key,
  productSizesStockStatus,
}: {
  key: "onlineStockStatus" | "storeStockStatus";
  productSizesStockStatus: GetProductSizesStockStatusQuery["productSizesStockStatus"];
}) => {
  if (productSizesStockStatus.some((status) => status[key] === "IN_STOCK")) {
    return IN_STOCK;
  }
  if (productSizesStockStatus.some((status) => status[key] === "LOW_STOCK")) {
    return LOW_STOCK;
  }
  return OUT_OF_STOCK;
};

export const getInitialStockLevels = ({
  productSizesStockStatus,
}: {
  productSizesStockStatus: GetProductSizesStockStatusQuery["productSizesStockStatus"];
}) =>
  isEmpty(productSizesStockStatus)
    ? null
    : {
        onlineStockStatus: getInitialStockStatus({
          key: "onlineStockStatus",
          productSizesStockStatus,
        }),
        storeStockStatus: getInitialStockStatus({
          key: "storeStockStatus",
          productSizesStockStatus,
        }),
      };

export const hasOnlineStock = (
  productSizesStockStatus?: GetProductSizesStockStatusQuery["productSizesStockStatus"]
): boolean =>
  !isEmpty(productSizesStockStatus) &&
  !productSizesStockStatus.every(({ onlineStockStatus }) =>
    [onlineStockStatus].every((status) => status === "OUT_OF_STOCK")
  );

export const hasStoreStock = (
  productSizesStockStatus?: GetProductSizesStockStatusQuery["productSizesStockStatus"]
): boolean =>
  !isEmpty(productSizesStockStatus) &&
  !productSizesStockStatus.every(({ storeStockStatus }) =>
    [storeStockStatus].every((status) => status === "OUT_OF_STOCK")
  );

export const checkIsEverySizeOutOfStockOnlineAndInStores = (
  productSizesStockStatus?: GetProductSizesStockStatusQuery["productSizesStockStatus"]
): boolean =>
  !isEmpty(productSizesStockStatus) &&
  productSizesStockStatus.every(({ onlineStockStatus, storeStockStatus }) =>
    [onlineStockStatus, storeStockStatus].every(
      (status) => status === "OUT_OF_STOCK"
    )
  );
