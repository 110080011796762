import { useSharedData } from "@/react-app/contexts/SharedData";
import type { PaymentWidgetQuery } from "@/react-app/generated/graphql-code-generator";
import { callGraphQL } from "@/react-app/graphql/graphqlApi";
import { paymentWidgetQuery } from "@/react-components/Cart/Api/graphqlQueries";
import { useClient } from "@/react-hooks/useClient/useClient";
import {
  getSiteHost,
  legacySiteUidToSiteUid,
} from "@/react-utils/xxl-shared-data";
import { useQuery } from "@tanstack/react-query";
import { log } from "@xxl/logging-utils";
import { QUERY_KEYS } from "./queryKeys";

export const usePaymentWidgetQuery = ({ shouldRunServerSide = false }) => {
  const {
    siteUid,
    configuration: {
      amplifyConfig: {
        aws_appsync_graphqlEndpoint: graphQlEndpoint,
        aws_appsync_apiKey: graphQlApiKey,
      },
    },
  } = useSharedData().data;
  const siteId = getSiteHost(legacySiteUidToSiteUid(siteUid));
  const isClientSide = useClient();
  const isEnabled = shouldRunServerSide ? true : isClientSide;

  const getPaymentWidgetData = async () => {
    try {
      const paymentWidgetResponse = await callGraphQL<PaymentWidgetQuery>(
        {
          query: paymentWidgetQuery,
          variables: {
            input: {
              site: siteId,
            },
          },
        },
        graphQlEndpoint,
        graphQlApiKey
      );

      return paymentWidgetResponse.data?.paymentWidget ?? null;
    } catch {
      log.error("Unable to fetch payment widget data");
      return null;
    }
  };

  return useQuery({
    queryKey: [QUERY_KEYS.PAYMENT_WIDGET],
    queryFn: getPaymentWidgetData,
    staleTime: Infinity,
    enabled: isEnabled,
  });
};
