import { Icon } from "react-app/src/components/Icon/Icon";
import { Text } from "react-app/src/components/Text/Text";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { colorsAsCssVariable } from "react-app/src/styles/theme/colors";
import { useAddToCartError } from "../../../hooks/useAddToCartError";
import { Message } from "./Message";

export const AddToCartWarning = () => {
  const { t } = useTranslations();
  const { addToCartError } = useAddToCartError();

  if (addToCartError === null) {
    return null;
  }

  return (
    <Message backgroundColor={colorsAsCssVariable.xxlWarning}>
      <Icon name="Info" />
      <Text typography="baseBold" data-testid="product-add-to-cart-error">
        {t(addToCartError)}
      </Text>
    </Message>
  );
};
