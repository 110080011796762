import styled from "@emotion/styled/macro";
import { resolutionSizes } from "../../../config";
import { fadeInAnimation } from "../../../styled/mixins";
import { typographyToCss, MQ } from "../../../styles/helpers";
import text from "../RatingAndReview.typography";
import { xxlTheme } from "../../../styles/xxl-theme";
import typography from "../../../styles/theme/typography";

const { colors, spaces } = xxlTheme;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  word-break: break-word;
  border-top: 1px solid var(--color-xxl-medium-gray);
  ${fadeInAnimation("reviewAppear", "200ms")}

  @media (max-width: ${resolutionSizes.tablet}px) {
    margin-top: 10px;
  }

  @media (max-width: ${resolutionSizes.mobile}px) {
    margin-top: 5px;
  }
`;

export const RatingInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    ${typographyToCss(text.reviews.reviewAuthor)};
    align-self: flex-start;
    margin: 0;
  }
`;

export const StarsAndName = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    padding-top: 2px;
  }

  @media (max-width: ${resolutionSizes.mobile}px) {
    flex-direction: column;

    p {
      order: 3;
      max-width: 100%;
      margin: 8px 0 0;
      padding: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const TextAndSizeFitness = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: ${resolutionSizes.mobile}px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 0;

  h3,
  p {
    word-break: normal;
  }

  h3 {
    ${typographyToCss(text.reviews.reviewHeading)};
    margin: 0 0 8px;
  }

  ${MQ("tablet")} {
    width: 60%;
  }

  ${MQ("desktop")} {
    width: 50%;
  }
`;

export const TextDescription = styled.p`
  ${typographyToCss(text.reviews.reviewText)};
  color: ${colors.xxlDarkGrey};
  margin: 0 0 12px;
`;

export const BuyerLabel = styled.span`
  ${typographyToCss(typography.tinyRegular)};
  color: ${colors.xxlDarkGrey};
  display: inline-flex;
  align-items: center;
`;

export const SizeFitnessContainerReviews = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;

  @media (max-width: ${resolutionSizes.tablet}px) {
    width: 35%;
  }

  @media (max-width: ${resolutionSizes.mobile}px) {
    width: 100%;
  }

  > * {
    width: 100%;
  }
`;

export const Recommended = styled.p`
  display: flex;
  ${typographyToCss(text.reviews.recommendedText)};
  margin: 0;
`;

export const TranslationInfo = styled.p`
  margin: 0 0 ${spaces.large};
  ${typographyToCss(text.reviews.reviewTranslationText)};

  ${MQ("laptop")} {
    margin: 0 0 ${spaces.smallRegular};
  }
`;
