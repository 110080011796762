import { legacySiteUidToSiteUid } from "@/react-utils/xxl-shared-data";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { getSortedStoresList } from "@/react-components/StoreStock";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { useQuery } from "@tanstack/react-query";
import type { Store } from "../types";
import { QUERY_KEYS } from "./queryKeys";

export const useStoresQuery = () => {
  const { siteUid } = useSharedData().data;
  const { contentApi } = useApiClients();

  const fetchStores = async () => {
    const response = await contentApi.getStores(
      legacySiteUidToSiteUid(siteUid)
    );
    return await getSortedStoresList(response.data.result as Store[]);
  };

  return useQuery({
    queryKey: [QUERY_KEYS.STORES],
    queryFn: fetchStores,
    staleTime: Infinity,
  });
};
