import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";
import { Text } from "@/react-components/Text/Text";
import { addXXLEventListener } from "@/react-utils/xxl-event";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { getPreferredStores } from "../helpers";
import { useGeoSortedStoresWithCollectableProductQuery } from "../queries/useStoresWithCollectableProductQuery";
import { StockLevels } from "../types";
import { hasStock } from "./StoreStock.helper";
import { StoreStockDialog } from "./StoreStockDialog/StoreStockDialog";
import { NotInStockMessageStyled } from "./StoreStockNotInStockMessage";
import { StoreStockLevelElement } from "./StoreStockStatus";
import {
  StoreStockChooseProductSizeMessage,
  StoreStockHeader,
  StoreStockListItem,
  StoreStockListStyled,
  StoreStockSeeAllStoresButton,
} from "./StoreStockStyles";

const { spaces } = xxlTheme;
const SORTES_STORE_DISPLAY_COUNT = 3;
const useToggleDialogEvent = (cb: () => void) => {
  useEffect(() => {
    addXXLEventListener("OPEN_STORE_STOCK_MODAL", cb);
    return () => removeEventListener("OPEN_STORE_STOCK_MODAL", cb);
  }, [cb]);
};

const StoreStockHeading = ({ text }: { text: string }) => {
  return (
    <StoreStockHeader>
      <Text typography="baseBold" role="heading">
        {text}
      </Text>
    </StoreStockHeader>
  );
};

type Props = {
  selectedVariantCode: string | null;
};
const StoreStock = ({ selectedVariantCode }: Props) => {
  const { t } = useTranslations();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
  useToggleDialogEvent(toggleDialog);

  const { data: sortedStores = [], isLoading: isLoadingStores } =
    useGeoSortedStoresWithCollectableProductQuery(selectedVariantCode);

  const isInStock = sortedStores.some(
    (store) => store.stockLevel !== StockLevels.OUT_OF_STOCK
  );

  if (isLoadingStores) {
    return (
      <XxlStack>
        <StoreStockHeading text={t("product.details.storestock.status")} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </XxlStack>
    );
  }

  if (selectedVariantCode === null) {
    return (
      <XxlStack>
        <StoreStockHeading text={t("product.details.storestock.status")} />
        <StoreStockChooseProductSizeMessage>
          {t("product.details.storestock.choose.product")}
        </StoreStockChooseProductSizeMessage>
      </XxlStack>
    );
  }

  const preferredStores = getPreferredStores(sortedStores);
  const storeList = new Set([...preferredStores, ...sortedStores]);
  const storeStockList = Array.from(storeList)
    .filter(hasStock)
    .slice(0, SORTES_STORE_DISPLAY_COUNT);
  return (
    <XxlStack>
      <StoreStockHeading text={t("product.details.storestock.status")} />
      {isInStock ? (
        <XxlStack gap={spaces.smallRegular}>
          <StoreStockListStyled>
            {storeStockList.map((store) => (
              <StoreStockListItem key={store.id}>
                {store.name}
                <StoreStockLevelElement stockLevel={store.stockLevel} />
              </StoreStockListItem>
            ))}
          </StoreStockListStyled>
          <StoreStockSeeAllStoresButton
            onClick={toggleDialog}
            data-testid="showAllStores"
          >
            <Icon name="Store" size={20} />
            <Text>{t("product.details.storestock.status.all")}</Text>
          </StoreStockSeeAllStoresButton>
        </XxlStack>
      ) : (
        <NotInStockMessageStyled
          testId="storestock-out-all"
          messageText={t("product.details.storestock.out.all")}
        />
      )}
      <StoreStockDialog
        sortedStores={sortedStores}
        isDialogOpen={isDialogOpen}
        toggleDialog={toggleDialog}
      />
    </XxlStack>
  );
};

export { StoreStock };
