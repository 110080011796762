import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { CreateClientApi } from "../PdpBody/Reviews/api/client";
import { QUERY_KEYS } from "./queryKeys";
import { NUMBER_OF_REVIEWS_TO_DISPLAY } from "../constants";
import { useAmplifyConfig } from "@/react-hooks/useAmplifyConfig";

const fetchReviews = async (
  apiKey: string,
  apiEndpoint: string,
  articleNumber: string,
  count: number,
  nextToken: string
) => {
  const api = CreateClientApi(apiKey, apiEndpoint);
  return await api.ReviewService.ListReviews(articleNumber, count, nextToken);
};

/**
@description Hook to get the reviews for a product.
@example const { data, isLoading, error } = useReviewsQuery
@param articleNumber The article number of the product. Example "1234567"
*/
export const useReviewsQuery = (
  articleNumber: string,
  count = NUMBER_OF_REVIEWS_TO_DISPLAY,
  nextToken = "",
  enabled = true
) => {
  const { aws_appsync_apiKey, aws_appsync_graphqlEndpoint } =
    useAmplifyConfig();
  return useQuery({
    queryKey: [QUERY_KEYS.REVIEWS, { count }, { articleNumber }, { nextToken }],
    enabled,
    placeholderData: keepPreviousData,
    queryFn: () =>
      fetchReviews(
        aws_appsync_apiKey,
        aws_appsync_graphqlEndpoint,
        articleNumber,
        count,
        nextToken
      ),
  });
};

const fetchExpertReview = async (
  productId: string,
  siteId: string,
  apiKey: string,
  apiEndpoint: string
) => {
  const api = CreateClientApi(apiKey, apiEndpoint);
  return await api.ReviewService.GetExpertReview({
    siteId,
    articleNumber: productId,
  });
};

export const useExpertReviewQuery = (productId: string) => {
  const { siteUid } = useSharedData().data;
  const { aws_appsync_apiKey, aws_appsync_graphqlEndpoint } =
    useAmplifyConfig();

  return useQuery({
    queryKey: [QUERY_KEYS.EXPERT_REVIEW],
    queryFn: () =>
      fetchExpertReview(
        productId,
        siteUid,
        aws_appsync_apiKey,
        aws_appsync_graphqlEndpoint
      ),
  });
};
