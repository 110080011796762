import { Apis } from "@/utils/api-helper";
import { getCategoryData } from "@/utils/apis/category-data";
import {
  CACHE_TTL_15_MIN,
  STALE_TTL_3_MIN,
} from "@/utils/apis/shared/constants";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { hasNoValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  BaseCampaignData,
  BrandData,
  CategoryLevelOneLink,
  CategoryLevelTwoLink,
  Image,
  MegaMenuContentData,
  Translation,
} from "@xxl/content-api";
import type {
  BaseBrandData,
  BaseCampaignData as CampaignData,
  ImageData,
  MegaMenuContentData as MegaMenuContent,
  MegaMenuLevelOneLink,
  MegaMenuLevelTwoLink,
} from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";
import { type CategoryData } from "@xxl/pim-api";
import { type ProductDiscountCount } from "@xxl/product-search-api";
import { getSiteHost } from "react-app/src/utils/xxl-shared-data";
import { isDateWithinRange } from "../date-helper";
import {
  getEnvVar,
  getLegacySiteUid,
  getSiteUid,
} from "../environment-variables";
import { getTranslations } from "../translations/translateMessage";
import { getProductDiscountCount } from "./elevate-api";

const mapImage = (image: Image | undefined): ImageData | undefined =>
  image?.url !== undefined
    ? {
        ...{ ...image, url: undefined },
        baseUrl: image.url,
      }
    : undefined;

const mapCampaign = (
  content: Image | undefined,
  campaigns: BaseCampaignData[] | undefined
): CampaignData | undefined => {
  const campaign =
    content === undefined && isNotNullOrUndefined(campaigns)
      ? campaigns.find(isDateWithinRange)
      : undefined;
  return isNotNullOrUndefined(campaign)
    ? {
        ...{ ...campaign, fromDate: undefined, toDate: undefined },
        bannerImage: mapImage(campaign.bannerImage),
        ...(isNotNullOrUndefined(campaign.slug)
          ? {
              url: `${getEnvVar(
                "REQUEST_MAPPING_CAMPAIGNHUBPAGE"
              )}/${encodeURIComponent(campaign.slug)}`,
            }
          : undefined),
      }
    : undefined;
};

const mapLink = (
  link: CategoryLevelTwoLink,
  categoryLinks: CategoryData[],
  translations: Translation[],
  discountedCategoryData: ProductDiscountCount[]
): MegaMenuLevelTwoLink => ({
  displayName: link.displayName,
  category:
    link.categoryCode !== undefined
      ? getCategoryData(
          link.categoryCode,
          categoryLinks,
          translations,
          discountedCategoryData
        )
      : undefined,
  content: mapImage(link.content),
});

const mapBrandLinks = (brandLinks: BrandData[]): BaseBrandData[] => {
  return brandLinks.map(({ brandCode, brandName }) => ({
    brandCode,
    brandName,
    url: `/b/${brandCode ?? ""}/${brandName ?? ""}`,
  }));
};

const mapLinks = async (
  rawLinks: CategoryLevelOneLink[]
): Promise<MegaMenuLevelOneLink[]> => {
  const pageUid = getLegacySiteUid();
  const siteId = getSiteUid();
  const siteHost = getSiteHost(siteId);
  try {
    const categoriesString = rawLinks
      .map((link) => link.categoryCode)
      .join(",");
    const subCategoriesString = rawLinks
      .map((link) => link.links?.map((item) => item.categoryCode).join(","))
      .join(",");
    const [pimResponse, translations, discountedCategoryDataResponse] =
      await Promise.all([
        Apis.getInstance().pimApi.getCategories(
          pageUid,
          categoriesString + "," + subCategoriesString
        ),
        getTranslations(),
        getProductDiscountCount(siteHost, {
          usage: "server-side",
        }),
      ]);

    const pimCategoriesResponse = pimResponse.data.filter(
      (link) => link.code !== undefined
    );

    const links = Promise.all(
      rawLinks.map(
        (link): MegaMenuLevelOneLink => ({
          ...mapLink(
            link,
            pimCategoriesResponse,
            translations,
            discountedCategoryDataResponse
          ),
          links:
            link.links?.map((item) =>
              mapLink(
                item,
                pimCategoriesResponse,
                translations,
                discountedCategoryDataResponse
              )
            ) ?? [],
          campaign: mapCampaign(link.content, link.campaigns),
          brandLinks: mapBrandLinks(link.brandLinks ?? []),
          buttonText: link.buttonText,
          contentText: link.contentText,
          link: link.link,
        })
      )
    );

    return links;
  } catch (error) {
    const message =
      (error as { message?: string }).message ?? "error message undefined";
    log.error(
      "Error fetching mega menu links, to see more data turn on debug mode",
      message
    );
    log.debug("Error fetching mega menu links", error);
  }
  return [];
};

const mapToContent = async (
  menuContent: MegaMenuContentData
): Promise<MegaMenuContent> => ({
  campaignHubLink: menuContent.campaignHubLink,
  links: await mapLinks(menuContent.categoryLinks ?? []),
  content: mapImage(menuContent.content),
});

/**
 * Server-side only fetching of mega menu content
 */
const fetchMegaMenu = memoize(
  async () => {
    const {
      data: { result: [menuContent] = [] },
    } = await Apis.getInstance().contentApi.getMegaMenu(getSiteUid());
    if (hasNoValue(menuContent)) {
      return;
    }
    return await mapToContent(menuContent);
  },
  new Keyv({ namespace: "mega-menu" }),
  {
    ttl: CACHE_TTL_15_MIN,
    staleTtl: STALE_TTL_3_MIN,
  }
);

export { fetchMegaMenu };
export type { MegaMenuContent };
