import type {
  KeenSliderHooks,
  KeenSliderInstance,
  KeenSliderOptions,
  SliderInstance,
} from "keen-slider/react";

export type SlidesConfig = {
  perView: number;
  spacing: number;
  origin?: "auto" | "center";
  breakpoints?: Record<string, { slides: SlidesConfig }>;
  initial?: number;
};

type SlidesConfigDefault = {
  desktop: SlidesConfig;
  mobile: SlidesConfig;
};

export const slidesDefaults: SlidesConfigDefault = {
  mobile: {
    perView: 1,
    spacing: 0,
    origin: "auto",
  },
  desktop: {
    perView: 3,
    spacing: 12,
    origin: "center",
  },
};

export const calcNrOfDots = (items: JSX.Element[], slides?: SlidesConfig) => {
  if (slides === undefined) {
    return 0;
  }

  const { perView } = slides;
  const nrOfItems = items.length;
  return nrOfItems === perView ? 0 : nrOfItems - Math.floor(perView) + 1;
};

export const KEEN_SLIDER_CLASSES = {
  Slider: "keen-slider",
  Slide: "keen-slider__slide",
  Dots: "dots",
} as const;

export const initLoop = (
  slider: SliderInstance<
    KeenSliderOptions<object, object, KeenSliderHooks>,
    KeenSliderInstance<object, object, KeenSliderHooks>,
    KeenSliderHooks
  >,
  loopTime: number
): void => {
  let timeout: number;
  let mouseOver = false;

  function clearNextTimeout() {
    window.clearTimeout(timeout);
  }

  function nextTimeout() {
    clearTimeout(timeout);
    if (mouseOver) return;
    timeout = window.setTimeout(() => {
      slider.next();
    }, loopTime);
  }

  slider.on("created", () => {
    slider.container.addEventListener("mouseover", () => {
      mouseOver = true;
      clearNextTimeout();
    });
    slider.container.addEventListener("mouseout", () => {
      mouseOver = false;
      nextTimeout();
    });
    nextTimeout();
  });

  slider.on("dragStarted", clearNextTimeout);
  slider.on("animationEnded", nextTimeout);
  slider.on("updated", nextTimeout);
};

export const getTeaserOneIndex = (
  currentSlideIdx: number,
  lastIndex: number
) => {
  if (currentSlideIdx === 0) {
    return lastIndex;
  }

  return currentSlideIdx - 1;
};

export const getTeaserTwoIndex = (
  currentSlideIdx: number,
  lastIndex: number
) => {
  if (currentSlideIdx === lastIndex) {
    return 0;
  }

  return currentSlideIdx + 1;
};

export const MutationPlugin = (slider: KeenSliderInstance) => {
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(() => slider.update());
  });
  const config = { childList: true };
  slider.on("created", () => observer.observe(slider.container, config));
  slider.on("destroyed", () => observer.disconnect());
};
