import { MQ, interactionFeedback } from "@/react-app/styles/helpers";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlButton } from "@/react-components/Common/XxlButton/XxlButton";
import styled from "@emotion/styled/macro";
import { Button } from "@mui/material";
import { color } from "@xxl/theme";

export const DialogFooter = styled.div`
  padding: ${xxlTheme.spaces.smallRegular};
  background: ${color.white.hex};
  box-shadow: 0 -5px 5px -5px ${color.mediumGray.hex};
  position: sticky;
  bottom: 0;
  ${MQ("tablet")} {
    display: flex;
    justify-content: flex-end;
    gap: ${xxlTheme.spaces.smallRegular};
    padding: ${xxlTheme.spaces.smallRegular} ${xxlTheme.spaces.large};
  }
`;

export const DialogBtn = styled(XxlButton)`
  ${MQ("tablet")} {
    width: fit-content;
  }
`;

const ACCORDION_BORDER = `1px solid ${color.mediumGray.cssVariable}`;
export const ACCORDION_STYLES = {
  borderTop: ACCORDION_BORDER,
  boxShadow: "none",
  ".MuiAccordionDetails-root": {
    padding: `0px ${xxlTheme.spaces.extraLarge}`,
    height: "fit-content",
  },
  ".MuiAccordionSummary-root": {
    height: "fit-content",
  },
  "&:first-of-type": {
    borderTop: 0,
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.expanded": {
    margin: 0,
  },
  "&.Mui-disabled": {
    backgroundColor: "transparent",
  },
};

export const CloseButton = styled(Button)`
  cursor: pointer;
  color: ${xxlTheme.colors.xxlWebBlack};
  border: none;
  background: none;
  padding-top: 0;
  ${interactionFeedback(`background-color: ${xxlTheme.colors.xxlLightGrey};`)};
`;
