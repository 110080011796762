import { PRODUCT_API_CLIENT_IDS } from "@/react-app/constants";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSessionSource } from "@/react-app/contexts/Session";
import { useSharedData } from "@/react-app/contexts/SharedData";
import {
  getSiteHost,
  legacySiteUidToSiteUid,
} from "@/react-utils/xxl-shared-data";
import { skipToken, useQuery } from "@tanstack/react-query";
import {
  isNotNullOrUndefined,
  isNullOrUndefined,
  type Site,
} from "@xxl/common-utils";
import { useStateValue } from "cotton-box-react";
import {
  enhanceProductForMultipack,
  getSerializableProductPageResult,
  type SerializableProductPageResult,
} from "./../../../utils/search-api-utils/product-data-maps";
import { QUERY_KEYS } from "./queryKeys";
import { getCustomerKey, getSessionKey } from "@/react-utils/Cookie";

const PRICE_ID_KEY = {
  ANONYMOUS: "anonymous",
  MEMBER: "member",
} as const;

const getPriceID = (isLoggedIn: boolean) =>
  isLoggedIn ? PRICE_ID_KEY.MEMBER : PRICE_ID_KEY.ANONYMOUS;

// Remove "isElevatePDP: false" version when old PDP is removed. https://xxlsports.atlassian.net/browse/XD-16277
type UseElevateProductPageQueryProps =
  | {
      isElevatePdp: true;
      initialData: SerializableProductPageResult;
    }
  | {
      isElevatePdp: false;
      productCode: string;
      hasStock: boolean;
    };

export const useElevateProductPageQuery = (
  args: UseElevateProductPageQueryProps
) => {
  const { siteUid } = useSharedData().data;
  const isLoggedIn = useStateValue(useSessionSource);
  const { productApi } = useApiClients();
  const { isElevatePdp } = args;
  const hasStock = args.isElevatePdp === false ? args.hasStock : true;
  const productKey = args.isElevatePdp
    ? args.initialData?.baseProduct?.products[0].code
    : args.productCode;

  const fetchData = async () => {
    if (isNullOrUndefined(productKey)) {
      return Promise.reject(
        new Error("No article number found cannot fetch data")
      );
    }

    try {
      const customerKey = getCustomerKey();
      const baseProduct = await productApi.getBaseProduct({
        priceId: getPriceID(isLoggedIn),
        key: productKey,
        site: getSiteHost(legacySiteUidToSiteUid(siteUid)),
        clientId: PRODUCT_API_CLIENT_IDS.getBaseProductResultsClientSide,
        ...(isNotNullOrUndefined(customerKey) && {
          customerKey,
          sessionKey: getSessionKey(),
        }),
      });

      const productForSerialization = enhanceProductForMultipack({
        baseProduct,
        site: getSiteHost(legacySiteUidToSiteUid(siteUid)) as Site,
      });

      return getSerializableProductPageResult({
        baseProduct: productForSerialization,
      });
    } catch (error) {
      throw new Error(`Failed to fetch product page data.`);
    }
  };

  return useQuery({
    queryKey: [
      QUERY_KEYS.ELEVATE_PRODUCT_PAGE,
      { isLoggedIn, productKey, isElevatePdp },
    ],
    queryFn: hasStock ? fetchData : skipToken,
    placeholderData: args.isElevatePdp ? args.initialData : undefined,
    throwOnError: true,
  });
};
