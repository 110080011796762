import type { ProductData } from "@xxl/pim-api";
import { SALES_METHODS } from "./constants";

// Same logic exist in shipments-api
const isProductFrom3rdParty = (salesMethod = "") =>
  [SALES_METHODS.ON_ORDER, SALES_METHODS.VENDOR_STOCK].includes(salesMethod);

const getIsPurchaseAllowed = ({
  baseProductSoldIndividually = true,
  productType,
  serviceProduct = false,
  visibilityStatus = {},
}: Pick<
  ProductData,
  | "baseProductSoldIndividually"
  | "productType"
  | "serviceProduct"
  | "visibilityStatus"
>) => {
  const { dangerousGoods = false } = visibilityStatus;
  return (
    !serviceProduct &&
    productType !== "INSURANCE" &&
    !dangerousGoods &&
    baseProductSoldIndividually
  );
};

export { getIsPurchaseAllowed, isProductFrom3rdParty };
