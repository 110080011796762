import styled from "@emotion/styled/macro";
import { resolutionSizes } from "../../../../config";
import spaces from "../../../../styles/theme/spaces";
import { xxlTheme } from "../../../../styles/xxl-theme";

const { colors } = xxlTheme;

export const DetailsWrapper = styled.div<{ isNew?: boolean }>(
  ({ isNew }) => `
  display: flex;
  justify-content: space-between;
  ${
    isNew === true
      ? `
      margin-top: ${spaces.smallRegular};
      margin-bottom: ${spaces.large};
      &:last-of-type {
        margin-top: 0px;
        margin-bottom: ${spaces.smallRegular};
      }`
      : `padding-bottom: 10px`
  }
  
`
);

export const LocationAndPrice = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (max-width: ${resolutionSizes.tablet}px) {
    flex-grow: unset;

    img {
      margin-left: 20px;
    }
  }
`;

export const DeliveryName = styled.div`
  display: flex;
`;

export const Paragraph = styled.p`
  width: 100%;
  margin: 0 0 5px;
  color: ${colors.xxlWebBlack};
  font-family: var(--font-family-regular);
  line-height: 19px;
`;

export const Location = styled(Paragraph)`
  flex-grow: 1;
  margin-bottom: 3px;
  color: ${colors.xxlWebBlack};
  font-family: var(--font-family-bold);
  line-height: 1;
`;

export const Image = styled.img`
  align-self: flex-start;
  width: auto;
  max-height: 12px;
  margin: 0 5px 5px;
`;
