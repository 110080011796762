import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { getPreferredStoresCookie } from "@/react-app/utils/Cookie";
import {
  BOX_UNIT,
  PACKAGE_UNIT,
  PIECE_UNIT,
} from "@/react-components/Product/product-helper";
import { getCartDetailsCookie } from "@/react-utils/Cookie/XXLCookie";
import type { CountdownTimerSettings } from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";
import type {
  AvailabilityData,
  ImageData,
  ProductData,
  VariantData,
} from "@xxl/product-search-api";
import type { CampaignCountdownProps, StockLevel, Store } from "./types";
import { StockLevels } from "./types";

const PRIMARY_IMAGE_TYPE = "primary";
export const getPrimaryImg = (imgData: ImageData[]) =>
  imgData.find((i) => i.tags.includes(PRIMARY_IMAGE_TYPE));

export const getComponentSpacing = (isLaptopSize: boolean) =>
  isLaptopSize ? xxlTheme.spaces.large : xxlTheme.spaces.smallRegular;

export const getStoreWithStockCount = (a: AvailabilityData[], ccBuffer = 1) => {
  let resultCount = 0;
  for (let i = 0; i < a.length; i++) {
    if (a[i].channel === "STORE" && a[i].stockNumber > ccBuffer) {
      resultCount++;
    }
  }
  return resultCount;
};

const StockStatusLevel = {
  INSTOCK: 2,
  LOWSTOCK: 1,
  OUTOFSTOCK: 0,
} as const;

export const getHighestStockStatus = (
  stores: Store[],
  variant: VariantData
) => {
  const tempSet = new Set<AvailabilityData>();
  const pStoreIds = new Set(stores.map((ps) => ps.id));
  for (const availability of variant.availability) {
    if (pStoreIds.has(availability.key)) {
      tempSet.add(availability);
    }
  }

  let highestStatus: StockLevel = StockLevels.OUT_OF_STOCK; // Start with the lowest possible status
  for (const store of Array.from(tempSet)) {
    if (StockStatusLevel[store.stockStatus] > StockStatusLevel[highestStatus]) {
      highestStatus = store.stockStatus;
    }
  }
  return highestStatus;
};

export const getCurrentStoreIdFromCookie = () =>
  getCartDetailsCookie()?.collectStore;

export const getPreferredStores = (stores: Store[]) => {
  const psCookie = getPreferredStoresCookie();
  if (psCookie === null || stores.length === 0) {
    return [] as Store[];
  }
  return Array.from(
    new Set(stores.filter(({ id }) => psCookie.ids.includes(id)))
  );
};

export const createTimerProps = (
  settings: CountdownTimerSettings | null,
  fontColor?: string
): CampaignCountdownProps | null => {
  if (settings === null) {
    return null;
  }
  const { date, startDate, isOnlyHours } = settings;

  if (date === undefined) {
    log.error("Countdown property 'date' is undefined");
    return null;
  }

  return {
    convertDaysToHours: isOnlyHours ?? false,
    endDate: new Date(date),
    fontColor: fontColor ?? xxlTheme.colors.xxlWebBlack,
    startDate: startDate !== undefined ? new Date(startDate) : undefined,
  };
};

export const getSelectedOrFirstVariantSizeCode = (variants: VariantData[]) => {
  const selectedVariant = variants.filter(({ isSelected }) => isSelected);
  return selectedVariant[0]?.sizeCode ?? variants[0]?.sizeCode;
};

export const checkIsArchiveProduct = ({
  isGraveyard,
  isDiscontinued,
}: ProductData) => isGraveyard && !isDiscontinued;

export const getSalesUnit = ({ type, isAmmunition }: ProductData) =>
  isAmmunition === true
    ? BOX_UNIT
    : type === "MULTIPACK"
      ? PACKAGE_UNIT
      : PIECE_UNIT;

export const isFrontendSalesUnit = (unit?: string): boolean =>
  unit !== undefined && [PIECE_UNIT, BOX_UNIT, PACKAGE_UNIT].includes(unit);
