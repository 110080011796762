import styled from "@emotion/styled/macro";
import { ButtonStyledAsLink } from "../../../styled";

export const OptionsWrapper = styled.div`
  padding-left: 0;
`;

export const ErrorMessage = styled.p`
  margin-top: 0;
  font-size: 0.8rem;
  font-family: var(--font-family-regular);
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

export const Content = styled.div<{ hasFullPadding: boolean }>`
  min-height: 51px;
  ${({ hasFullPadding }): string =>
    hasFullPadding
      ? "padding: var(--space-regular)"
      : "padding: var(--space-regular) 0"};
`;

export const DeliveryMessage = styled.p`
  width: 100%;
  margin: 8px 0 12px;
`;

export const DeliveryToggleButton = styled(ButtonStyledAsLink)`
  text-decoration: none;
  font-weight: 700;
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 4px;
  align-items: flex-end;
  svg {
    height: 14px;
    width: 14px;
    transition: all 0.2s ease-in-out;
  }
  svg[data-open="true"] {
    transform: rotate(180deg);
  }
`;
