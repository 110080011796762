import type { Translate } from "@/react-app/contexts/Translations/TranslationsContext";
import type { PriceInfoData } from "@xxl/product-search-api";
import {
  BOX_UNIT,
  PACKAGE_UNIT,
} from "react-app/src/components/Product/product-helper";

export const getLabel = (priceInfoData: PriceInfoData, t: Translate) => {
  const { labelType } = priceInfoData;

  if (labelType === "TranslatedLabel") {
    return priceInfoData.label;
  }

  if (labelType === "TranslationKey") {
    return t(priceInfoData.translationKey);
  }

  return undefined;
};

export const isSoldInPackages = (unit?: string): boolean =>
  unit === BOX_UNIT || unit === PACKAGE_UNIT;
