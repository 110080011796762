import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { legacySiteUidToSiteUid } from "@/react-utils/xxl-shared-data";
import { useQuery } from "@tanstack/react-query";
import type {
  ProductTooltipContentData,
  ProductTooltipsContentResponse,
} from "@xxl/content-api";
import { log } from "@xxl/logging-utils";
import type { AxiosResponse } from "axios";
import { QUERY_KEYS } from "./queryKeys";

type UseProductTooltipsQueryParams = {
  isEnabled: boolean;
};

export const useProductTooltipsQuery = ({
  isEnabled = false,
}: UseProductTooltipsQueryParams) => {
  const { siteUid } = useSharedData().data;
  const { contentApi } = useApiClients();

  const fetchProductTooltips = async () => {
    try {
      const response: AxiosResponse<ProductTooltipsContentResponse> =
        await contentApi.getProductTooltips(legacySiteUidToSiteUid(siteUid));
      const tooltips: ProductTooltipContentData[] = response.data.result ?? [];
      return tooltips;
    } catch (error) {
      log.error("Unable to fetch product tooltips");
      log.debug("Unable to fetch product tooltips: ", error);
      return [];
    }
  };

  return useQuery({
    enabled: isEnabled,
    queryFn: fetchProductTooltips,
    queryKey: [QUERY_KEYS.PRODUCT_TOOLTIPS, siteUid],
    staleTime: Infinity,
  });
};
