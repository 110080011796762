import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery/useXxlMediaQuery";
import type { Store as StoreDetails } from "@xxl/content-api";
import { getPreferredStores } from "../helpers";
import type { Store } from "../types";
import { StoreListDesktop } from "./StoreListDesktop";
import { StoreListMobile } from "./StoreListMobile";

type Props = {
  stores: Store[];
  handleStoreSelect: (id: string) => void;
  selectedStoreId?: string;
  selectedStoreDetails?: StoreDetails;
};
export const StoreListComponent = (props: Props) => {
  const { stores, handleStoreSelect, selectedStoreId, selectedStoreDetails } =
    props;
  const isMobile = useXxlMediaQuery("MobileMediaQuery");

  const preferredStores = getPreferredStores(stores);
  const remainingStores = stores.filter(
    (store) => !preferredStores.map((s) => s.id).includes(store.id)
  );

  if (isMobile === true) {
    return (
      <StoreListMobile
        preferredStores={preferredStores}
        remainingStores={remainingStores}
        handleStoreSelect={handleStoreSelect}
        selectedStoreId={selectedStoreId}
        selectedStoreDetails={selectedStoreDetails}
      />
    );
  }

  return (
    <StoreListDesktop
      preferredStores={preferredStores}
      remainingStores={remainingStores}
      handleStoreSelect={handleStoreSelect}
      selectedStoreId={selectedStoreId}
    />
  );
};
