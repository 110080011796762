import { hasValue } from "@xxl/common-utils";
import type {
  BadgesData,
  PriceData,
  ProductData,
} from "@xxl/product-search-api";
import type { GetFrontendPriceDisplayDataProps } from "../../PriceDisplay/types";
import { formatPrice } from "../../xxl-price-format";

const getPriceSplash = (badges: BadgesData | undefined): string | undefined => {
  const campaignRibbon = badges?.secondary.find(
    ({ attribute }) => attribute === "custom.campaignRibbon"
  )?.content;
  if (typeof campaignRibbon === "object") {
    return campaignRibbon.text;
  } else if (hasValue(campaignRibbon)) {
    return campaignRibbon;
  }
  const primarySplash = badges?.primary.find(
    ({ theme }) => theme === "DISCOUNT"
  )?.content;
  if (typeof primarySplash === "object") {
    return primarySplash.text;
  } else if (hasValue(primarySplash)) {
    return primarySplash;
  } else {
    return undefined;
  }
};

const getPriceDisclaimer = (
  badges: BadgesData | undefined,
  price: PriceData
): string | undefined => {
  const content = badges?.secondary.find(
    ({ attribute }) => attribute === "custom.campaignDisclaimerText"
  )?.content;
  if (typeof content === "object") {
    return content.text;
  } else if (hasValue(content)) {
    return content;
  }
  if (price.alternate?.labelType === "TranslationKey") {
    return price.alternate.translationKey;
  }
  if (price.alternate?.labelType === "TranslatedLabel") {
    return price.alternate.label;
  }
  return undefined;
};

export const getPriceDisplayFromElevateData = (
  { badges, price }: Pick<ProductData, "badges" | "price">,
  isLoggedIn: boolean
): GetFrontendPriceDisplayDataProps["priceDisplay"] => ({
  previousPriceFormatted:
    price.alternate?.range.min.value !== undefined
      ? formatPrice(price.alternate.range.min.value)
      : undefined,
  priceDisclaimer: getPriceDisclaimer(badges, price),
  salesPrice: price.selling.range.min.value,
  salesPriceFormatted: formatPrice(price.selling.range.min.value),
  userGroupId: isLoggedIn ? "registeredcustomers" : undefined,
  priceSplash: getPriceSplash(badges),
  type: "REGULAR", // TODO
});
