import { AutomatedRibbon } from "@/react-components/Product/ProductBadge/AutomatedRibbon";
import { CampaignRibbon } from "@/react-components/Product/ProductBadge/CampaignRibbon";
import {
  getAutomatedBadges,
  getCampaignRibbon,
} from "@/react-utils/ProductData/badges-helper";
import { isNotEmpty } from "@xxl/common-utils";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import {
  AutomatedBadgeWrapper,
  CampaignRibbonWrapper,
} from "./ProductImage.styles";
import { ProductImageSliderDesktop } from "./ProductImageSliderDesktop";
import { ProductImageSliderMobile } from "./ProductImageSliderMobile";
import type {
  ProductImageSliderInnerComponentProps,
  ProductImageSliderProps,
} from "./types";

const ImageSliderMap = {
  desktop: ProductImageSliderDesktop,
  mobile: ProductImageSliderMobile,
} as const;

const ImageSliderComponent = (props: ProductImageSliderInnerComponentProps) => {
  const isDesktopSize = useXxlMediaQuery("LaptopMediaQuery");
  const Component = ImageSliderMap[isDesktopSize ? "desktop" : "mobile"];
  return <Component {...props}>{props.children}</Component>;
};

type ProductImageSliderPropsWithOptionalDefaults = Omit<
  ProductImageSliderProps,
  "shouldShowUpsaleLink" | "shouldReplaceThumbnailsWithArrows"
> & {
  shouldReplaceThumbnailsWithArrows?: boolean;
  shouldShowUpsaleLink?: boolean;
};

export const ProductImageSlider = ({
  badges,
  imageInfo,
  productName,
  shouldReplaceThumbnailsWithArrows = false,
  shouldShowUpsaleLink = true,
}: ProductImageSliderPropsWithOptionalDefaults) => {
  const productImages: string[] = imageInfo.images.reduce(
    (acc: string[], image) => [...acc, ...image.sources.map(({ url }) => url)],
    []
  );

  const automatedBadges = getAutomatedBadges(badges);
  const campaignRibbon = getCampaignRibbon(badges);

  return (
    <ImageSliderComponent
      productImages={productImages}
      productName={productName}
      shouldReplaceThumbnailsWithArrows={shouldReplaceThumbnailsWithArrows}
      shouldShowUpsaleLink={shouldShowUpsaleLink}
    >
      {isNotEmpty(automatedBadges) && (
        <AutomatedBadgeWrapper>
          {automatedBadges.map((badge) => (
            <AutomatedRibbon badgeType={badge} key={badge} />
          ))}
        </AutomatedBadgeWrapper>
      )}

      {campaignRibbon !== null && (
        <CampaignRibbonWrapper>
          <CampaignRibbon badge={campaignRibbon} direction="left" />
        </CampaignRibbonWrapper>
      )}
    </ImageSliderComponent>
  );
};
