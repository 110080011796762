import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react";
import { xxlTheme } from "../../styles/xxl-theme";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { Icon } from "../Icon/Icon";
import ReactHtmlParser from "html-react-parser";

const scroll = keyframes`
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - ${xxlTheme.spaces.regular}));
    }
`;

const MarqueeContainer = styled.div`
  display: flex;
  overflow: hidden;
  min-height: fit-content;
  user-select: none;
  gap: ${xxlTheme.spaces.regular};
  grid-area: marquee;
`;

const MarqueeContent = styled.ul`
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  padding: 0;
  gap: ${xxlTheme.spaces.regular};

  animation: ${scroll} 25s linear infinite;
  @media (prefers-reduced-motion: reduce) {
    animation: ${scroll} 100s linear infinite;
  }
`;
const MarqueeItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  gap: ${xxlTheme.spaces.micro};

  a {
    text-decoration: underline;
  }
`;

const ICON_SIZE = 24;

const IconNameMap = {
  FreeReturnExchange: "FreeReturnExchange",
  Pricematch: "Pricematch",
  DeliveryTruck: "DeliveryTruck",
} as const;

const usps = [
  [IconNameMap.FreeReturnExchange, "product.details.usp1"],
  [IconNameMap.Pricematch, "product.details.usp2"],
  [
    IconNameMap.DeliveryTruck,
    "product.details.usp3",
    "product.details.usp3.more",
  ],
] as const;

const UspMarquee = () => {
  const { t } = useTranslations();

  return (
    <MarqueeContainer>
      <MarqueeContent>
        {usps.map(([name, usp, uspMore]) => (
          <MarqueeItem key={usp}>
            <Icon name={name} size={ICON_SIZE} />
            {ReactHtmlParser(t(usp))}{" "}
            {uspMore !== undefined && ReactHtmlParser(t(uspMore))}
          </MarqueeItem>
        ))}
      </MarqueeContent>

      {/* duplicated content to create infinite loop. */}
      <MarqueeContent aria-hidden="true">
        {usps.map(([name, usp, uspMore]) => (
          <MarqueeItem key={usp}>
            <Icon name={name} size={ICON_SIZE} />
            {ReactHtmlParser(t(usp))}{" "}
            {uspMore !== undefined && ReactHtmlParser(t(uspMore))}
          </MarqueeItem>
        ))}
      </MarqueeContent>
    </MarqueeContainer>
  );
};

export { UspMarquee };
