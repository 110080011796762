import { useSharedData } from "@/react-app/contexts/SharedData";
import type { Breadcrumb } from "@xxl/product-search-api";
import { FITSTATION_ENABLED_CATEGORY_CODES } from "../utils/constants";

type VolumentalMetaData = {
  isFitstationEnabled: boolean;
  volumentalId: string;
};

type UseVolumentalMetaDataProps = { breadcrumbs?: Breadcrumb[] };

export const useVolumentalMetaData = ({
  breadcrumbs,
}: UseVolumentalMetaDataProps) => {
  const {
    featureToggles: { toggle_fitstation },
    volumentalId,
  } = useSharedData().data;

  const isFitstationEnabled =
    Array.isArray(breadcrumbs) &&
    breadcrumbs.some(({ path }) =>
      FITSTATION_ENABLED_CATEGORY_CODES.has(path)
    ) &&
    toggle_fitstation;

  const volumentalMetaData: VolumentalMetaData = {
    isFitstationEnabled,
    volumentalId,
  };

  return volumentalMetaData;
};
