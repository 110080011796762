import React from "react";
import { useClient } from "../../hooks/useClient/useClient";
import { Snowfall } from "react-snowfall";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";

const Snow = () => {
  const isClient = useClient();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isTablet = useXxlMediaQuery("MobileAndTabletMediaQuery");

  return isClient ? (
    <Snowfall
      radius={[0.1, 0.6]}
      snowflakeCount={isMobile ? 50 : isTablet ? 75 : 100}
      speed={[0.01, 0.03]}
      wind={[-0.5, -0.3]}
    />
  ) : null;
};

export default React.memo(Snow);
