import type { SizeOptionsComponent } from "@/components/MiniPdp/types";
import { useMediaQuery } from "@mui/material";
import { isNotEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import { getPriceDisplay } from "@xxl/prices-utils";
import type { OnSelectSizeProps } from "next-js-app/src/components/ProductDetailsPage/ProductForm/SizeOptions";
import type { MouseEvent, ReactNode } from "react";
import { useState } from "react";
import { XxlButton } from "react-app/src/components/Common/XxlButton/XxlButton";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Icon } from "react-app/src/components/Icon/Icon";
import {
  getPriceDisplaysForPackages,
  isSoldInPackages,
} from "react-app/src/components/Product/product-helper";
import { XXLLoader } from "react-app/src/components/XXLLoader";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import type { FrontendProductData } from "react-app/src/hooks/useProductData/useProductData.types";
import spaces from "react-app/src/styles/theme/spaces";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { hasUndeductedRewardDiscount } from "react-app/src/utils/PriceDisplay/price-display";
import { laptopMediaQuery } from "react-app/src/utils/xxl-screen";
import { SHOULD_BE_POSSIBLE_TO_ADD_REMINDER } from "src/components/ConfiguratorForProductBundles/constants";
import { CampaignComponent } from "src/components/ProductDetailsPage/CampaignComponent";
import { ProductDescriptions } from "src/components/ProductDetailsPage/Descriptions/ProductDescriptions";
import { OverviewGridArea } from "src/components/ProductDetailsPage/ProductDetailsPage.styles";
import { SizeOptions } from "src/components/ProductDetailsPage/ProductForm/SizeOptions";
import { ProductImageSlider } from "src/components/ProductDetailsPage/ProductImageSlider/ProductImageSlider";
import { ProductOverview } from "src/components/ProductDetailsPage/ProductOverview/ProductOverview";
import type { PreSelectSizeStrategy } from "src/components/ProductDetailsPage/ProductSizeSelector/ProductSizeSelector";
import { SizeGuide } from "src/components/ProductDetailsPage/SizeGuide";
import type { CampaignInfo } from "src/components/ProductDetailsPage/types";
import { createBadges } from "src/utils/pdp-page-helper";
import {
  convertProductLink,
  createClassifications,
  createFrontendPriceDisplays,
  getColorTheme,
  getComponentSpacing,
  useIsSizeSelectDrawerOpen,
} from "../ProductDetailsPage/ProductDetailsPage.helper";
import { ProductStyleSelector } from "../ProductDetailsPage/ProductStyleSelector/ProductStyleSelector";
import { SeeFullProductPage } from "./MiniPdp.styles";

const IMAGE_MAX_WIDTH_ONE_PRODUCT_IMAGE = 420;
const IMAGE_MAX_WIDTH_MULTIPLE_PRODUCT_IMAGES = 460;
const PRODUCT_STYLES_MAX_WIDTH = 491;

type AddToCartButtonProps = {
  productData: FrontendProductData;
  selectedSizeData: OnSelectSizeProps | null;
};

type Props = {
  addToCartButton: (props: AddToCartButtonProps) => ReactNode;
  campaignInfo: CampaignInfo | null;
  isQuickShop: boolean;
  isLoggedIn: boolean;
  onChangeProductStyle: ({
    code,
    event,
  }: {
    code: string;
    event: MouseEvent;
  }) => void;
  preSelectedSizeCodeOrEan?: string;
  preSelectSizeStrategy?: PreSelectSizeStrategy;
  productData: FrontendProductData | null;
  shouldBePossibleToChangeQuantity: boolean;
  shouldBePossibleToSelectOutOfStock: boolean;
  sizeOptionsComponent: SizeOptionsComponent;
};

const MiniPdpContent = ({
  addToCartButton,
  campaignInfo,
  isQuickShop,
  isLoggedIn,
  onChangeProductStyle,
  preSelectedSizeCodeOrEan,
  preSelectSizeStrategy,
  productData,
  shouldBePossibleToChangeQuantity,
  shouldBePossibleToSelectOutOfStock,
  sizeOptionsComponent,
}: Props) => {
  const isLaptopSize = useMediaQuery(laptopMediaQuery);
  const componentSpacing = getComponentSpacing({ isLaptopSize });
  const { t } = useTranslations();
  const {
    data: {
      siteUid,
      featureToggles: { toggle_products_as_package_quantity },
    },
  } = useSharedData();
  const [selectedSizeData, setSelectedSizeData] =
    useState<OnSelectSizeProps | null>(null);
  const { isSizeSelectDrawerOpen, toggleIsSizeSelectDrawerOpen } =
    useIsSizeSelectDrawerOpen();
  if (productData === null) {
    return (
      <XxlStack
        justifyContent="center"
        minHeight={`${isLaptopSize ? 50 : 80}vh`}
      >
        <XXLLoader />
      </XxlStack>
    );
  }
  const {
    averageRating,
    badges = [],
    baseProductName,
    brand = {},
    categoryCode,
    classifications = [],
    code,
    description = null,
    frontendPriceDisplay,
    isPurchaseAllowed,
    multiChannelAvailability = [],
    priceDisplay,
    priceDisplays,
    productImages = [],
    productLink,
    quantityToggleEnabled = false,
    sizeOptions = [],
    styleOptions = [],
    units,
    url,
    usp,
    isRewardsProduct,
    cheapestPriceForRewardsProduct,
  } = productData;

  const { iconUrl, label = "", priceSplash = "", type } = priceDisplay;
  const strictClassifications = createClassifications(classifications);
  const bannerData = {
    colorTheme: getColorTheme(priceDisplay),
    ribbonPrice: priceSplash,
    splashLabel: label,
    variant: type,
  };

  const isBoxSelling = isSoldInPackages(units);
  const strictPriceDisplay = isNotNullOrUndefined(priceDisplays)
    ? createFrontendPriceDisplays(priceDisplays)
    : undefined;
  const simplePriceDisplay = isNotNullOrUndefined(strictPriceDisplay)
    ? getPriceDisplay({
        isLoggedIn,
        priceDisplays: strictPriceDisplay,
      })
    : undefined;
  const priceToDisplay =
    toggle_products_as_package_quantity && isBoxSelling
      ? getPriceDisplaysForPackages(simplePriceDisplay, units)
      : simplePriceDisplay;

  return (
    <XxlStack gap={spaces.large}>
      <XxlStack gap={spaces.large} direction={isLaptopSize ? "row" : "column"}>
        <XxlStack
          maxWidth={
            isLaptopSize
              ? productImages.length > 1
                ? IMAGE_MAX_WIDTH_MULTIPLE_PRODUCT_IMAGES
                : IMAGE_MAX_WIDTH_ONE_PRODUCT_IMAGE
              : "none"
          }
          width="100%"
        >
          <ProductImageSlider
            productImages={productImages}
            productName={baseProductName ?? ""}
            badges={createBadges(badges, multiChannelAvailability, siteUid)}
            bannerData={bannerData}
            campaignComponent={
              campaignInfo !== null && isNotEmpty(iconUrl) ? (
                <CampaignComponent
                  campaignInfo={campaignInfo}
                  iconUrl={iconUrl}
                />
              ) : null
            }
            shouldShowDesktopThumbnails={false}
          />
        </XxlStack>
        <XxlStack
          spacing={
            isLaptopSize ? xxlTheme.spaces.miniMicro : xxlTheme.spaces.micro
          }
          direction="column"
          flexGrow="1"
          width="100%"
          justifyContent="space-between"
        >
          <OverviewGridArea spacing={componentSpacing}>
            <ProductOverview
              brandName={brand.name ?? ""}
              brandUrl={brand.url ?? ""}
              brandLogoUrl={brand.logo ?? ""}
              baseProductName={baseProductName ?? ""}
              priceData={{
                hasUndeductedRewardDiscount: hasUndeductedRewardDiscount({
                  invertedPrice: priceDisplay.invertedPrice,
                  invertedPriceFormatted: priceDisplay.invertedPriceFormatted,
                  variant: priceDisplay.type,
                  isMultiPackProduct: productData.productType === "MULTIPACK",
                }),
                isDiscountedMainPrice:
                  frontendPriceDisplay.isDiscountedMainPrice,
                isInvertedPrice: frontendPriceDisplay.isInvertedPrice,
                latestPriceFormatted:
                  priceToDisplay?.latestPriceFormatted ??
                  priceDisplay.latestPriceFormatted,
                secondaryPriceFormatted: frontendPriceDisplay.otherPrice,
                salesPriceFormatted:
                  priceToDisplay?.salesPrice?.toString() ??
                  frontendPriceDisplay.salesPrice,
                secondaryPriceDisclaimer:
                  frontendPriceDisplay.otherPriceDisclaimer,
                priceDisclaimer: frontendPriceDisplay.salesPriceDisclaimer,
                userGroupId: frontendPriceDisplay.userGroupId,
              }}
              classifications={strictClassifications}
              averageRating={averageRating ?? null}
              shouldDisplaySeeFullDescriptionLink={false}
              shouldShowWriteReviewLink={false}
              productStyle={code}
              usp={isQuickShop ? usp : undefined}
              isRewardsProduct={isRewardsProduct}
              cheapestPriceForRewardsProduct={cheapestPriceForRewardsProduct}
            />
            <XxlStack mt={"6px"} />
          </OverviewGridArea>
          <style>{
            // Force drawer to appear above modal
            `.MuiDrawer-root { z-index: 1300; }`
          }</style>
          <XxlStack
            gap={
              isLaptopSize
                ? xxlTheme.spaces.smallRegular
                : xxlTheme.spaces.regular
            }
          >
            <XxlStack maxWidth={`${PRODUCT_STYLES_MAX_WIDTH}px`}>
              <ProductStyleSelector
                onClick={onChangeProductStyle}
                styleOptions={styleOptions}
              />
            </XxlStack>
            {isNotEmpty(brand.code) && (
              <SizeGuide brandCode={brand.code} categoryCode={[categoryCode]} />
            )}
            {sizeOptionsComponent === undefined ? (
              <SizeOptions
                isClickAndCollectEnabled={false}
                isPurchaseAllowed={isPurchaseAllowed}
                isSizeSelectDrawerOpen={isSizeSelectDrawerOpen}
                onSelectSize={setSelectedSizeData}
                preSelectedSizeCodeOrEan={preSelectedSizeCodeOrEan}
                preSelectSizeStrategy={preSelectSizeStrategy}
                styleCode={code ?? ""}
                quantityToggleEnabled={
                  shouldBePossibleToChangeQuantity && quantityToggleEnabled
                }
                sizeOptions={sizeOptions}
                shouldBePossibleToAddReminder={
                  SHOULD_BE_POSSIBLE_TO_ADD_REMINDER
                }
                shouldBePossibleToSelectOutOfStock={
                  shouldBePossibleToSelectOutOfStock
                }
                toggleIsSizeSelectDrawerOpen={toggleIsSizeSelectDrawerOpen}
              />
            ) : (
              sizeOptionsComponent
            )}

            {addToCartButton({ productData, selectedSizeData })}
          </XxlStack>
        </XxlStack>
      </XxlStack>
      {!isQuickShop && (
        <ProductDescriptions
          description={description}
          classifications={strictClassifications}
          servicePromotions={[]}
          productLink={convertProductLink(productLink)}
        />
      )}
      {typeof url === "string" &&
        (isLaptopSize ? (
          <SeeFullProductPage
            href={url}
            target={isQuickShop ? "_parent" : "_blank"}
            align={isQuickShop ? "end" : "start"}
          >
            {t("product.details.see.full.product.page")}
            <Icon
              name="CaretRight"
              size={16}
              transform={{ translateX: 0, translateY: 4 }}
            />
          </SeeFullProductPage>
        ) : (
          <XxlButton
            variant="secondary"
            onClick={() => window.open(url, isQuickShop ? "_parent" : "_blank")}
          >
            {t("product.details.see.full.product.page")}
          </XxlButton>
        ))}
    </XxlStack>
  );
};

export { MiniPdpContent };
export type { AddToCartButtonProps };
