import { Text } from "react-app/src/components/Text";
import {
  SlideLink,
  ImageContainer,
  ImageStrikeThrough,
} from "./ProductStyleSelector.styles";
import { IMAGE_SIZE } from "../constants";
import type { Style, StyleSelectorProps } from "./types";
import { NextImage } from "@/components/common/NextImage/NextImage";

export const StyleItem = ({
  style,
  onClick,
}: {
  style: Style;
  onClick: StyleSelectorProps["onClick"];
}) => {
  const {
    code,
    imageData,
    baseColor,
    isInStock,
    url,
    isSelected = false,
  } = style;

  return (
    <SlideLink
      href={url}
      disabled={!isInStock}
      onClick={(event) => {
        onClick({ code, event });
      }}
    >
      <ImageContainer isSelected={isSelected} isInStock={isInStock}>
        <NextImage
          src={imageData.imageUrl ?? ""}
          priority={true}
          height={IMAGE_SIZE.STYLE}
          width={IMAGE_SIZE.STYLE}
          alt={imageData.alt}
        />
        {!isInStock && <ImageStrikeThrough />}
      </ImageContainer>
      <Text
        typography={isSelected ? "tinyBold" : "tinyMedium"}
        color={!isInStock ? "darkGray" : "webBlack"}
        strikethrough={!isInStock}
      >
        {baseColor}
      </Text>
    </SlideLink>
  );
};
