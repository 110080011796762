import type { StockLevel, Store } from "../../types";
import { StockLevels } from "../../types";

export const getHighestStockStatus = (stores: Store[]) =>
  stores.reduce<StockLevel>((highestStock, { collectableStockLevel }) => {
    if (
      highestStock === StockLevels.IN_STOCK ||
      collectableStockLevel === StockLevels.IN_STOCK
    ) {
      return StockLevels.IN_STOCK;
    }

    if (
      highestStock === StockLevels.OUT_OF_STOCK &&
      collectableStockLevel === StockLevels.LOW_STOCK
    ) {
      return StockLevels.LOW_STOCK;
    }

    return highestStock;
  }, StockLevels.OUT_OF_STOCK);
