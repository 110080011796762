import { isNotEmpty } from "@xxl/common-utils";
import type { CampaignBadgeData } from "@xxl/product-search-api";
import {
  useTranslations,
  type Translate,
} from "../../../contexts/Translations/TranslationsContext";
import { xxlTheme } from "../../../styles/xxl-theme";
import type { TranslationKey } from "../../../translations";
import { NewRibbon, type RibbonDirection } from "../NewRibbon/NewRibbon";
import type { ColorTheme } from "../product-helper";

const { colors } = xxlTheme;
const getColorTheme: (badge: CampaignBadgeData) => ColorTheme = (badge) => ({
  backgroundColor: badge.content?.backgroundColor ?? colors.xxlGreen,
  foregroundColor: badge.content?.frontColor ?? colors.xxlBlack,
});

const getLabel = (badge: CampaignBadgeData, t: Translate) => {
  if (typeof badge.content?.text === "string") {
    return badge.content.text;
  }
  if (typeof badge.content?.scharven === "string") {
    return t(`badge.${badge.content.scharven}` as TranslationKey);
  }
  return null;
};

type BadgeProps = {
  badge: CampaignBadgeData;
  direction?: RibbonDirection;
};

export const CampaignRibbon = ({ badge, direction = "left" }: BadgeProps) => {
  const { t } = useTranslations();

  const colorTheme = getColorTheme(badge);
  const label = getLabel(badge, t);
  const shouldRenderLabel = label !== null && isNotEmpty(label);

  return (
    shouldRenderLabel && (
      <NewRibbon colorTheme={colorTheme} label={label} direction={direction} />
    )
  );
};
