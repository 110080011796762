import { useSharedData } from "@/react-app/contexts/SharedData";
import type { EcomSiteUidLegacy } from "@/react-app/global";
import { legacySiteUidToSiteUid } from "@/react-utils/xxl-shared-data";
import { useQuery } from "@tanstack/react-query";
import { getPromiseResult } from "../../../utils/get-server-side-props-helper";
import { getSimpleCampaignDataMemoized } from "../../../utils/pdp-page-helper";
import { QUERY_KEYS } from "./queryKeys";

type FetchCampaignDataProps = {
  campaignId?: string;
  siteUid: EcomSiteUidLegacy;
};

const fetchCampaignData = async ({
  campaignId,
  siteUid,
}: FetchCampaignDataProps) => {
  const [campaignDataResult] = await Promise.allSettled([
    campaignId !== undefined
      ? getSimpleCampaignDataMemoized(
          campaignId,
          legacySiteUidToSiteUid(siteUid)
        )
      : null,
  ]);

  const campaignData = getPromiseResult(
    campaignDataResult,
    "Failed to fetch campaign timer."
  );

  return campaignData;
};

export const useCampaignDataQuery = (campaignId?: string) => {
  const { siteUid } = useSharedData().data;

  return useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_DATA, campaignId],
    enabled: campaignId !== undefined,
    queryFn: () => fetchCampaignData({ campaignId, siteUid }),
  });
};
