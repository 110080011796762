import type { PropsWithChildren } from "react";
import { Icon } from "react-app/src/components/Icon/Icon";
import { LineDivider, RemindMe } from "./styles";

export const RemindMeComponent = ({
  hasReminder,
  onClick,
  shouldBePossibleToAddReminder,
  shouldShowRemindMe,
  children,
}: PropsWithChildren<{
  hasReminder: boolean;
  onClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  shouldBePossibleToAddReminder: boolean;
  shouldShowRemindMe: boolean;
}>) => {
  if (!shouldBePossibleToAddReminder || !shouldShowRemindMe) return null;
  return (
    <RemindMe onClick={onClick}>
      <LineDivider />
      {hasReminder && <Icon name="Check" />}
      <Icon name="Email" size={12} />
      {children}
    </RemindMe>
  );
};
