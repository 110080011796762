import type { KeenSliderInstance } from "keen-slider/react";
import { useKeenSlider } from "keen-slider/react";
import React from "react";
import { KEEN_SLIDER_CLASSES } from "react-app/src/components/Common/Slider/Slider.helper";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { IMAGE_SIZE } from "../../constants";
import {
  PRODUCT_IMAGE_HEIGHT,
  ThumbnailImageContainer,
} from "../ProductImage.styles";
import {
  ThumbnailPlugin,
  createThumbnailSrc,
} from "../ProductImageSlider.helper";
import { NextImage } from "../../../common/NextImage/NextImage";

export type ThumbnailSliderProps = {
  productImages: string[];
  instanceRef: React.MutableRefObject<KeenSliderInstance | null>;
  currentSlide: number;
};

export const ThumbnailSlider = ({
  productImages,
  instanceRef,
  currentSlide,
}: ThumbnailSliderProps) => {
  const { t } = useTranslations();

  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      vertical: true,
      slides: {
        spacing: 4,
        perView: 5.5,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  return (
    <div
      ref={thumbnailRef}
      className={KEEN_SLIDER_CLASSES.Slider}
      style={{ height: PRODUCT_IMAGE_HEIGHT, width: IMAGE_SIZE.THUMBNAIL }}
    >
      {productImages.map((productImage, index) => (
        <ThumbnailImageContainer
          selected={currentSlide === index}
          key={`${index} - ${productImage}`}
          onClick={() => instanceRef.current?.moveToIdx(index)}
          className={KEEN_SLIDER_CLASSES.Slide}
        >
          <NextImage
            src={createThumbnailSrc(productImage)}
            priority={true}
            width={IMAGE_SIZE.THUMBNAIL}
            height={IMAGE_SIZE.THUMBNAIL}
            alt={t("product.image")}
          />
        </ThumbnailImageContainer>
      ))}
    </div>
  );
};
