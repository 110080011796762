import type { DeliveryOption } from "@xxl/shipments-api";
import * as React from "react";
import { DeliveryCostAndTime } from ".";
import {
  DeliveryName,
  DetailsWrapper,
  Image,
  Location,
  LocationAndPrice,
} from "./DeliveryOptions.styled";

type DeliveryOptionsProps = {
  options: DeliveryOption;
  isRedesigned?: boolean;
};

export const DeliveryOptions: React.FunctionComponent<DeliveryOptionsProps> = ({
  options,
  isRedesigned = false,
}) => {
  const { currency, deliveryEarliest, deliveryLatest, imageUrl, name, price } =
    options;
  return (
    <DetailsWrapper isNew={isRedesigned} data-testid="deliveryOption">
      <LocationAndPrice>
        <DeliveryName>
          <Location>{name}</Location>
        </DeliveryName>
        <DeliveryCostAndTime
          {...{ price, currency, deliveryEarliest, deliveryLatest }}
        />
      </LocationAndPrice>
      {typeof imageUrl !== "undefined" && imageUrl !== "" && (
        <Image
          data-private={true}
          src={imageUrl}
          alt={`${name} logo`}
          width={24}
          height={12}
        />
      )}
    </DetailsWrapper>
  );
};
