import styled from "@emotion/styled/macro";
import type * as CSSType from "csstype";
import type { MediaQueryTypography } from "react-app/src/styles/helpers";
import {
  interactionFeedback,
  typographyToCss,
} from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const typography: MediaQueryTypography = {
  fontSize: 12,
  lineHeight: 1,
  tablet: {
    fontSize: 15,
  },
};

export const Description = styled.p<{ fontColor: CSSType.Property.Color }>`
  margin: 0;
  color: ${({ fontColor }) => fontColor};
  width: 100%;
  ${typographyToCss(typography)}
`;

export const Text = styled.p<{ fontColor: CSSType.Property.Color }>`
  margin: 0;
  color: ${({ fontColor }) => fontColor};
  align-self: center;

  font-family: var(--font-family-medium);
  ${typographyToCss(typography)}
`;

export const IconWrapper = styled.div`
  margin-left: auto;
`;

export const iconId = "xxl-campaign-caret";

export const Link = styled.a`
  ${interactionFeedback(`
    ${`#${iconId}`} {
      font-weight: bold;
      scale: 1.4;
    }
  `)}
`;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${xxlTheme.spaces.large};
  flex-grow: 1;
`;

export const MessageHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${xxlTheme.spaces.miniMicro};
`;
