import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { useMediaQuery } from "@mui/material";
import { Button } from "react-app/src/components/Common";
import {
  interactionFeedback,
  MQ,
  typographyToCss,
  xxlScrollbars,
} from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";
import {
  laptopMediaQuery,
  mobileMediaQuery,
  tabletMediaQuery,
} from "../../utils/xxl-screen";
import { GRID_COLUMNS } from "../Filter/FilterBar/constants";
import { PRODUCT_LISTING_SMALL_BANNER_CLASS_NAME } from "../ProductList/constants";
import {
  fourGridMargin,
  sixGridMargin,
} from "../ProductList/ProductList.styled";
import {
  DEFAULT_GRID_VALUE_DESKTOP,
  PRODUCT_VARIANT_THUMB_SIZE,
} from "./constants";
import typography from "./NewProduct.typography";
import { stockStatusBulletColor } from "./product-helper";
import type { StockStatus as StockStatusType } from "../../utils/data-types";

const { colors, spaces, zIndex } = xxlTheme;
const {
  xxlBlack,
  xxlDarkGrey,
  xxlLightGrey,
  xxlMediumGrey,
  xxlRed,
  xxlWebBlack,
  xxlWebGrey,
} = colors;

// REUSED TYPES
// ------------
type ProductListProps = {
  columnAmount?: number;
  isInCarousel?: boolean;
};

type ProductCardContainerProps = {
  isHoverable?: boolean;
  isLoading?: boolean;
  isFocused: boolean;
  showHighlightedLabel: boolean;
  showSalesPriceDisclaimer: boolean;
  showStoreStock: boolean;
  showDeliveryTag?: boolean;
  isClickable?: boolean;
  as?: "div" | "li";
  showAddToCartRow?: boolean;
} & ProductListProps;

// VARIABLES
// ---------
/* Info about ProductCardContainer height
   - Hoverable cards have BIGGER HEIGHT since we need to RESERVE SPACE for the extra elements added wehen hovering to avoid a "jumping glitch" in the next row
   - We calculate the height based on elements displayed. Some of this calculations have to apply to the banners inside the product list.
   - To avoid so much space between the rows, caused by the reserved white space for hoverable cards, we use a NEGATIVE MARGIN from row 2 and so on
*/
export const nonHoverableCardHeight = 470;
export const cardHeightMobile = 421;
const bigImageContainerHeightDesktop = "380px";
const smallImageContainerHeightDesktop = "298px";
const carouselSmallImageContainerHeightDesktop = "298px";
const carouselBigImageContainerHeightDesktop = "325px";
const imageContainerHeightMobile = "240px";
const smallQuickshopButtonWidth = "148px";
const bigQuickshopButtonWidth = "248px";
const smallScaleDownRatio = 0.85;
const bigScaleDownRatio = 0.7;
const smallScaleUpRatio = 1.1;
const bigScaleUpRatio = 1.1;
const orginalPriceMinHeightMobile = "16px";
const orginalPriceMinHeightDesktop = "20px";
const priceDisclaimerHeight = 20;
const highlightedLabelHeight = 26;
const deliveryCardHeight = 24;
const showStoreStockHeight = 15;
const hoverBackgroundColor = "#F2FFE3";
const quickShopIconDestopWidth = "25px";
const quickShopIconMobileSize = "27px";
const iconColorInactive = colors.xxlDarkGrey;
const iconColorActive = colors.xxlWebBlack;

const quickShopButtonSize = {
  mobile: "32px",
  desktop: "30px",
} as const;

const productCardHeights = {
  mobile: {
    one: 548,
    two: 419,
  },
  desktop: {
    four: 594,
    six: 548,
    hover: 739,
  },
} as const;

// No idea why this works but it does
const heightStylingForHoverEffect = {
  gridOfSix: 20,
  gridOfFour: 40,
} as const;

// No idea why this works but it does
const productVariantTopPosition = {
  gridFour: 524,
  gridSix: 445,
} as const;

// STYLED COMPONENTS, HELPERS & ETC.
// ---------------------------------
const addStoreStockHeight = (
  cardHeight: number,
  columnAmount: number,
  isMobile: boolean,
  isTablet: boolean,
  isLaptop: boolean,
  showStoreStock: boolean
): number => {
  if (
    showStoreStock &&
    ((isMobile && columnAmount === GRID_COLUMNS.two) ||
      (isTablet && columnAmount === GRID_COLUMNS.four && !isLaptop) ||
      (isLaptop && columnAmount === GRID_COLUMNS.six))
  ) {
    return (cardHeight += showStoreStockHeight);
  }

  return cardHeight;
};

const calculateCardHeight = (
  columnAmount: number,
  isHoverable: boolean,
  isMobile: boolean,
  isTablet: boolean,
  isLaptop: boolean,
  showHighlightedLabel: boolean,
  showDeliveryTag: boolean,
  showSalesPriceDisclaimer: boolean,
  showStoreStock: boolean
) => {
  let cardHeight = isHoverable
    ? columnAmount === GRID_COLUMNS.four
      ? productCardHeights.desktop.four + heightStylingForHoverEffect.gridOfFour
      : productCardHeights.desktop.six - heightStylingForHoverEffect.gridOfSix
    : nonHoverableCardHeight;

  if (showHighlightedLabel) {
    cardHeight += highlightedLabelHeight;
  }

  if (showSalesPriceDisclaimer) {
    cardHeight += priceDisclaimerHeight;
  }

  if (showDeliveryTag) {
    cardHeight += deliveryCardHeight;
  }

  cardHeight = addStoreStockHeight(
    cardHeight,
    columnAmount,
    isMobile,
    isTablet,
    isLaptop,
    showStoreStock
  );

  return cardHeight;
};

export const ReviewsWrapper = styled.div`
  min-height: ${spaces.small};
`;

export const CarouselProductCardContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  text-align: left;
`;

export const ProductCardContainerBaseStyling = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: relative;
  text-align: left;
`;

export const ProductCardContainer = styled(
  ProductCardContainerBaseStyling
)<ProductCardContainerProps>(({
  columnAmount,
  isFocused,
  isHoverable = false,
  showHighlightedLabel,
  showDeliveryTag = false,
  showSalesPriceDisclaimer,
  showStoreStock,
  isClickable = true,
  showAddToCartRow = false,
}) => {
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isTablet = useMediaQuery(tabletMediaQuery);
  const isLaptop = useMediaQuery(laptopMediaQuery);

  const cardHeight = calculateCardHeight(
    columnAmount ?? DEFAULT_GRID_VALUE_DESKTOP,
    isHoverable,
    isMobile,
    isTablet,
    isLaptop,
    showHighlightedLabel,
    showDeliveryTag,
    showSalesPriceDisclaimer,
    showStoreStock
  );

  return css`
    min-height: ${showAddToCartRow ? "auto" : `${cardHeightMobile}px`};
    pointer-events: ${isClickable ? "all" : "none"};

    .${PRODUCT_LISTING_SMALL_BANNER_CLASS_NAME} {
      height: 100%;
    }

    ${MQ("tabletHorizontal")} {
      min-height: ${showAddToCartRow ? "auto" : `${cardHeight}px`};

      .${PRODUCT_LISTING_SMALL_BANNER_CLASS_NAME} {
        height: ${columnAmount === GRID_COLUMNS.four
          ? `calc(100% - ${fourGridMargin})`
          : `calc(100% - ${sixGridMargin})`};
      }

      ${isFocused &&
      interactionFeedback(
        `
          border: 1px solid ${xxlMediumGrey};
          transform: scale(${
            columnAmount === GRID_COLUMNS.four
              ? smallScaleUpRatio
              : bigScaleUpRatio
          });
          z-index: ${zIndex["image-banner"]};
          transform-origin: center;
          transition-timing-function: ease-in;

          .product-info-container {
            background-color: ${hoverBackgroundColor};
            padding: ${spaces.miniMicro};
          }
        `
      )}
    }
    ${showAddToCartRow &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}
  `;
});

export const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${xxlWebBlack};
  padding-top: ${spaces.miniMicro};
  position: relative;
`;

type ProductLinkProps = {
  hasFixedHeight?: boolean;
};

export const ProductLink = styled.a<ProductLinkProps>(
  ({ hasFixedHeight = true }) => css`
    display: flex;
    flex-direction: column;
    height: ${hasFixedHeight ? "100%" : "auto"};
    position: relative;
  `
);

export const BasicInfo = styled.section<ProductListProps>(
  ({ columnAmount }) => css`
    height: 35px;
    display: flex;
    flex-direction: column;
    gap: ${spaces.line};
    color: ${xxlWebBlack};
    margin-bottom: ${spaces.miniMicro};

    ${MQ("tabletHorizontal")} {
      height: ${columnAmount === GRID_COLUMNS.four ? "46px" : "38px"};
    }
  `
);

type CardButtonIconType = {
  isFocused?: boolean;
  hasUsp?: boolean;
};

export const CardButtonIcons = styled.div<CardButtonIconType>(
  ({ isFocused = true, hasUsp = true }) => css`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30px;
    display: flex;
    flex-flow: row-reverse;
    background-color: ${isFocused && hasUsp
      ? `transparent`
      : colors.xxlLightGrey};
    z-index: 2;
    margin-bottom: ${spaces.miniRegular};

    ${MQ("tabletHorizontal")} {
      padding-right: ${spaces.miniMicro};
    }

    & button {
      padding: ${spaces.miniMicro};

      ${MQ("tabletHorizontal")} {
        padding: 0 0 0 ${spaces.micro};
      }
    }
  `
);

export const QuickShopButton = styled(Button)`
  height: ${quickShopButtonSize.mobile};
  padding: 0 ${spaces.line} 0 ${spaces.micro};
  border: none;
  cursor: pointer;
  background: transparent;
  color: ${iconColorInactive};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: auto;
    width: ${quickShopIconMobileSize};
    margin-left: -2px;
    stroke-width: 0;
  }

  ${MQ("tabletHorizontal")} {
    height: ${quickShopButtonSize.desktop};

    svg {
      width: ${quickShopIconDestopWidth};
    }

    ${interactionFeedback(`
      color: ${iconColorActive};
      & svg {
        transform: scale(1.1);
      }
    `)}
  }
`;

export const Brand = styled.span<ProductListProps>(
  ({ columnAmount }) => css`
    height: ${spaces.regular};
    ${typographyToCss(typography.defaultBrandText)};

    ${MQ("tabletHorizontal")} {
      height: ${columnAmount === GRID_COLUMNS.four
        ? spaces.smallLarge
        : spaces.regular};
      ${typographyToCss(
        columnAmount === GRID_COLUMNS.four
          ? typography.bigBrandText
          : typography.defaultBrandText
      )};
    }
  `
);

export const Name = styled.span<ProductListProps>`
  ${typographyToCss(typography.defaultNameText)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type ImageContainerProps = { isFocused?: boolean } & ProductListProps;

export const ImageContainer = styled.div<ImageContainerProps>(
  ({
    columnAmount = GRID_COLUMNS.two,
    isInCarousel = false,
    isFocused = false,
  }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    min-height: ${imageContainerHeightMobile};
    background-color: ${xxlLightGrey};
    justify-content: center;
    padding: ${isInCarousel ? `0 ${spaces.miniMicro}` : 0};

    ${MQ("tabletHorizontal")} {
      height: ${columnAmount === GRID_COLUMNS.four
        ? isInCarousel
          ? carouselBigImageContainerHeightDesktop
          : isFocused
            ? "340px"
            : bigImageContainerHeightDesktop
        : isInCarousel
          ? carouselSmallImageContainerHeightDesktop
          : isFocused
            ? "280px"
            : smallImageContainerHeightDesktop};
      overflow: hidden;
      padding: 0 ${spaces.miniMicro};
    }

    // Image styling when NO carousel is present
    // -----------------------------------------
    figure {
      display: block;
      align-self: center;
      width: 100%;
      margin: auto;
      overflow: hidden;
      text-align: center;
      height: 100%;

      img {
        ${MQ("tabletHorizontal")} {
          height: ${columnAmount === GRID_COLUMNS.four
            ? "380px"
            : isInCarousel
              ? "298px"
              : "100%"};
          object-fit: ${isInCarousel ? "scale-down" : "contain"};
        }
      }
    }

    // Image styling when carousel is present in ProductCard OR ProductCard is in a carousel
    // -------------------------------------------------------------------------------------
    img {
      display: block;
      width: 100%;
      height: ${columnAmount === GRID_COLUMNS.two || isInCarousel
        ? "240px"
        : "344px"};
      object-fit: ${columnAmount === GRID_COLUMNS.two
        ? "scale-down"
        : "contain"};
    }
  `
);

export const PriceWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spaces.miniMicro};
`;

type PriceProps = ProductListProps & {
  isDiscountPrice: boolean;
};

export const CurrentPrice = styled.span<PriceProps>(
  ({ columnAmount, isDiscountPrice }) => css`
    ${typographyToCss(typography.smallCurrentPrice)};
    color: ${isDiscountPrice ? xxlRed : xxlBlack};

    ${MQ("tabletHorizontal")} {
      ${typographyToCss(
        columnAmount === GRID_COLUMNS.four
          ? typography.bigCurrentPrice
          : typography.smallCurrentPrice
      )}
    }
  `
);

export const OriginalPrice = styled.span<ProductListProps>(
  ({ columnAmount }) => css`
    ${typographyToCss(typography.smallOriginalPrice)};
    display: flex;
    gap: ${spaces.micro};
    color: ${xxlBlack};
    min-height: ${orginalPriceMinHeightMobile};

    ${MQ("tabletHorizontal")} {
      min-height: ${orginalPriceMinHeightDesktop};
      ${typographyToCss(
        columnAmount === GRID_COLUMNS.four
          ? typography.bigOriginalPrice
          : typography.smallOriginalPrice
      )}
    }
  `
);

export const SalesPriceDisclaimer = styled.span`
  ${typographyToCss(typography.smallDisclaimerPrice)};
  color: ${xxlDarkGrey};
  min-height: ${spaces.smallRegular};
  padding-bottom: ${spaces.miniMicro};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${MQ("tabletHorizontal")} {
    ${typographyToCss(typography.bigDisclaimerPrice)};
  }
`;
export const RibbonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  margin-bottom: 10px;
  gap: ${spaces.micro};
`;

export const PositionTopLeft = styled.div`
  left: 0;
  position: absolute;
  top: 0;
`;

export const PriceSplash = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const ColorsAndRatingBaseContainer = styled.div`
  ${typographyToCss(typography.extraInfoText)};
  color: ${xxlWebGrey};
`;

type ColorsAndRatingContainerProps = {
  hasColorsAndRating: boolean;
};
export const ColorsAndRatingContainer = styled(
  ColorsAndRatingBaseContainer
)<ColorsAndRatingContainerProps>(
  ({ hasColorsAndRating }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: ${spaces.regular};
    margin-bottom: ${spaces.miniMicro};

    > span:first-of-type:after {
      content: "${hasColorsAndRating ? "/" : ""}";
      padding: 0 ${spaces.line};
    }
  `
);

type HighlightedLabelProps = {
  colorThemeName?: string;
  isTransparent: boolean;
} & ProductListProps;

const getColorTheme = (backgroundColor: string, textColor: string) => `
  background-color: ${backgroundColor};
  color: ${textColor};
`;

const productCarouselColorTheme = (colorThemeName: string) => {
  const name = colorThemeName.toLocaleLowerCase();
  switch (name) {
    case "black":
      return getColorTheme(colors.xxlMediumGrey, colors.xxlWebBlack);
    case "blue":
      return getColorTheme(colors.xxlLightBlue, colors.xxlDarkBlue);
    case "fuschia":
      return getColorTheme(colors.xxlLightFuchsia, colors.xxlDarkFuchsia);
    case "orange":
      return getColorTheme(colors.xxlLightOrange, colors.xxlDarkOrange);
    case "red":
    case "xmas red":
      return getColorTheme(colors.xxlLightRed, colors.xxlRed);
    case "turqoise":
      return getColorTheme(colors.xxlLightTurquoise, colors.xxlDarkTurquoise);
    case "yellow":
      return getColorTheme(colors.xxlLightAmber, colors.xxlDarkAmber);
    case "green":
    default:
      return getColorTheme(colors.xxlLightGreen, colors.xxlDarkGreen);
  }
};

export const HighlightedLabel = styled.div<HighlightedLabelProps>(
  ({ colorThemeName, isTransparent }) => css`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: ${isTransparent ? "32px" : "26px"};
    width: max-content;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: ${spaces.miniMicro};
    ${typographyToCss(typography.highlightedLabel)};
    ${isTransparent
      ? "background-color: transparent"
      : productCarouselColorTheme(colorThemeName ?? "")}
    margin-bottom: ${spaces.miniMicro};
  `
);

type ProductVariantsProps = {
  columnAmount?: number;
  isFocused: boolean;
  showHighlightedLabel: boolean;
  showSalesPriceDisclaimer: boolean;
};

export const ProductVariants = styled.div<ProductVariantsProps>(
  ({
    columnAmount,
    isFocused,
    showHighlightedLabel,
    showSalesPriceDisclaimer,
  }) => {
    let topPosition =
      columnAmount === GRID_COLUMNS.four
        ? productVariantTopPosition.gridFour
        : productVariantTopPosition.gridSix;

    if (showSalesPriceDisclaimer) {
      topPosition += priceDisclaimerHeight;
    }

    if (!showHighlightedLabel) {
      topPosition -= highlightedLabelHeight;
    }

    return css`
      position: absolute;
      top: ${topPosition}px;
      left: ${spaces.miniMicro};
      width: calc(100% - (${spaces.miniMicro} * 2));
      display: ${isFocused ? "flex" : "none"};
      gap: ${spaces.miniMicro};
      overflow-x: auto;
      overflow-y: hidden;
      ${xxlScrollbars(spaces.micro)};
      padding-bottom: ${spaces.micro};
    `;
  }
);

export const ProductVariantsSkeleton = styled.div<{ isFocused: boolean }>(
  ({ isFocused }) => css`
    min-height: 60px;
    display: ${isFocused ? "block" : "none"};
  `
);

export const ProductVariantThumb = styled.a<ProductListProps>(
  ({ columnAmount }) => {
    const isFourColumnLayout = columnAmount === GRID_COLUMNS.four;
    const ratio = isFourColumnLayout ? smallScaleDownRatio : bigScaleDownRatio;
    const size = PRODUCT_VARIANT_THUMB_SIZE * ratio;

    return css`
      height: ${size}px;
      width: ${size}px;
      border: 1px solid ${xxlMediumGrey};
      background-color: ${xxlLightGrey};
    `;
  }
);

type FetchPriorityImageProps = {
  fetchpriority?: "high" | "low" | "auto";
};

export const ProductVariantImage = styled.img<FetchPriorityImageProps>(
  () => css`
    max-height: 100%;
    font-size: 8px;
    overflow: hidden;
  `
);

export const ProductSpecifications = styled.ul<ProductListProps>(
  ({ columnAmount }) => css`
    list-style-type: circle;
    width: ${columnAmount === GRID_COLUMNS.four
      ? bigQuickshopButtonWidth
      : smallQuickshopButtonWidth};
    padding: 0 0 ${spaces.smallRegular}
      ${columnAmount === GRID_COLUMNS.four ? "15px" : "7px"};
    position: absolute;
    bottom: 0;
    background-color: ${colors.xxlLightGrey}CC;
    width: 100%;
    z-index: 1;
    min-height: 35px;
  `
);

export const ProductSpecification = styled.li<ProductListProps>(
  ({ columnAmount }) => css`
    list-style: disc;
    list-style-position: inside;
    ${typographyToCss(typography.specifications)};
    transform: scale(
      ${columnAmount === GRID_COLUMNS.four
        ? smallScaleDownRatio
        : bigScaleDownRatio}
    );
    width: calc(
      ${columnAmount === GRID_COLUMNS.four
          ? bigQuickshopButtonWidth
          : smallQuickshopButtonWidth} *
        ${columnAmount === GRID_COLUMNS.four
          ? smallScaleUpRatio
          : bigScaleUpRatio}
    );
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: -${spaces.large};
    color: ${xxlWebGrey};
  `
);

type StockStatusesProps = ProductListProps & {
  columnAmount?: number;
};

export const StockStatuses = styled.div<StockStatusesProps>(
  ({ columnAmount }) => css`
    display: flex;
    gap: ${columnAmount === GRID_COLUMNS.one ? spaces.miniMicro : 0};
    align-items: flex-start;
    flex-direction: column;

    ${MQ("desktop")} {
      gap: ${columnAmount === GRID_COLUMNS.four ? spaces.miniMicro : 0};
    }
  `
);

export const StockStatus = styled.span`
  display: inline-flex;
  gap: ${spaces.micro};
  align-items: baseline;
  ${typographyToCss(typography.stockStatus)};
`;

type StockStatusBulletProps = {
  level: StockStatusType;
};
export const StockStatusBullet = styled.span<StockStatusBulletProps>(
  ({ level }) => css`
    width: ${spaces.mini};
    height: ${spaces.mini};
    border-radius: 50%;
    background-color: ${stockStatusBulletColor(level)};
    margin-top: ${spaces.hair};
  `
);

export const DeliveryInfo = styled.div`
  display: flex;
  gap: ${spaces.micro};
  align-items: center;
  padding-top: ${spaces.miniMicro};
`;

export const DeliveryTime = styled.span`
  ${typographyToCss(typography.deliveryTime)};
`;

const smallIconWidth = "40px";
const bigIconWidth = "50px";
export const CampaignIcon = styled.div<ProductListProps>(
  ({ columnAmount }) => css`
    position: absolute;
    top: 0;
    padding: ${columnAmount === GRID_COLUMNS.one ? spaces.mini : spaces.micro};
    width: ${columnAmount === GRID_COLUMNS.one ? bigIconWidth : smallIconWidth};

    ${MQ("tabletHorizontal")} {
      padding: ${spaces.mini};
      width: ${columnAmount === GRID_COLUMNS.four
        ? bigIconWidth
        : smallIconWidth};
    }

    img {
      width: 100%;
    }
  `
);

type WidgetProps = {
  isPDP?: boolean;
};

const priceTypography = {
  pdp: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: -0.1,
  },
  productCard: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 10,
    lineHeight: 1,
    letterSpacing: -0.1,
  },
};

const { pdp, productCard } = priceTypography;

export const PriceDescriptionWidget = styled.span<WidgetProps>(
  ({ isPDP = false }) => css`
    ${typographyToCss(isPDP ? pdp : productCard)};
    align-self: flex-end;
    width: 100%;
    margin-left: ${isPDP ? spaces.mini : spaces.micro};
    margin-bottom: ${isPDP ? spaces.micro : 0};
    color: ${xxlWebBlack};
  `
);
