import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";

type CheckIconProps = {
  isSmall?: boolean;
  noMargins?: boolean;
};

export const CheckIcon = styled.span<CheckIconProps>(
  ({ isSmall, noMargins }) => css`
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--color-xxl-web-black);
    border-radius: 50%;
    position: relative;
    margin-right: var(--space-mini);
    flex-shrink: 0;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 5px;
      border-bottom: 2px solid var(--color-xxl-white);
      border-left: 2px solid var(--color-xxl-white);
      transform: rotate(-45deg);
      top: 5px;
      left: 4px;

      ${isSmall &&
      css`
        top: 3px;
        left: 2px;
        width: 5px;
        height: 2px;
        border-width: 1px;
      `}
    }

    ${noMargins &&
    css`
      position: absolute;
      top: -1px;
      left: -1px;
      margin-right: 0;
    `}

    ${isSmall &&
    css`
      width: 10px;
      height: 10px;
      margin-right: var(--space-micro);
    `}
  `
);
