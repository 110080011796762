import * as React from "react";
import { LoadMoreButtonWrapper } from "./LoadMoreReviews.styled";
import { LoadMoreButton } from "../../LoadMoreButton/LoadMoreButton";
import { InputErrorMessage } from "../../../styled/";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";

type LoadMoreReviewsProps = {
  onClick: () => Promise<void>;
};

export const LoadMoreReviews: React.FunctionComponent<LoadMoreReviewsProps> = ({
  onClick: triggerLoadMoreReviews,
}) => {
  const { t } = useTranslations();
  const [reviewsError, setGetReviewsError] = React.useState<string | null>(
    null
  );
  const handleClick = async (): Promise<void> => {
    try {
      await triggerLoadMoreReviews();
      setGetReviewsError(null);
    } catch (error) {
      setGetReviewsError(t("product.ratings-and-reviews.show.more.error"));
    }
  };
  return (
    <LoadMoreButtonWrapper>
      <LoadMoreButton
        text={t("product.ratings-and-reviews.show.more")}
        onClick={handleClick}
      />
      {reviewsError ? (
        <InputErrorMessage>{reviewsError}</InputErrorMessage>
      ) : null}
    </LoadMoreButtonWrapper>
  );
};
