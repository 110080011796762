import ReactHtmlParser from "html-react-parser";
import { DeliveryTruck } from "@xxl/icons";
import React from "react";
import { DeliveryMessage } from "../DeliveryInfo.styled";
import { SkeletonWrapper } from "../../../Common";

type MainMessageProps = {
  messageSetDangerously: string | null;
  isLoading?: boolean;
};

const MainMessage = ({
  isLoading = false,
  messageSetDangerously,
}: MainMessageProps) => (
  <>
    <DeliveryTruck fontSize={24} />
    {messageSetDangerously !== null && (
      <SkeletonWrapper isLoading={isLoading}>
        <DeliveryMessage data-testid="deliveryMessage">
          {ReactHtmlParser(messageSetDangerously)}
        </DeliveryMessage>
      </SkeletonWrapper>
    )}
  </>
);

export { MainMessage };
