import { resolutionSizes } from "@/react-app/config";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import {
  DESKTOP_BUTTON_SIZE,
  MOBILE_BUTTON_SIZE,
  type ButtonSizeType,
} from "@/react-components/Common/XxlButton/XxlButton";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Text } from "@/react-components/Text";
import styled from "@emotion/styled/macro";
import { CaretUp } from "@xxl/icons";
import type { PropsWithChildren } from "react";

const {
  colors: { xxlMediumGrey, xxlRed },
} = xxlTheme;

const StyledSelectButton = styled.button<{
  disabled: boolean;
  hasError: boolean;
  size: ButtonSizeType;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${xxlTheme.typography.mediumRegular.fontSize}px;
  color: ${xxlTheme.colors.xxlBlack};
  background-color: ${xxlTheme.colors.xxlWhite};
  padding: 0 1rem;
  border: 1px solid ${({ hasError }) => (hasError ? xxlRed : xxlMediumGrey)};

  ${xxlTheme.typography.standardRegular};

  outline-color: ${xxlTheme.colors.xxlGreen};

  @media (min-width: ${resolutionSizes.laptop}px) {
    height: ${({ size }) => DESKTOP_BUTTON_SIZE[size]};
  }
  height: ${({ size }) => MOBILE_BUTTON_SIZE[size]};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const SelectCaret = styled(CaretUp)<{ disabled: boolean; open: boolean }>`
  display: ${({ disabled }) => (disabled ? "none" : "block")};
  transform: ${({ open }) => (open ? "rotate(0deg)" : "rotate(180deg)")};
  transition: transform 0.2s ease-in-out;
  width: 32px;
`;

const VariantSelectContent = ({
  children,
  text,
}: PropsWithChildren<{ text: string }>) => (
  <>
    <Text data-testid="selected-size">{text}</Text>
    {children}
  </>
);

type Props = {
  disabled?: boolean;
  hasError?: boolean;
  onClick: () => void;
  open: boolean;
  displayText: string;
  quantityToggleEnabled: boolean;
  isOneSize: boolean;
};
export const VariantInputs = ({
  disabled = false,
  hasError = false,
  onClick,
  displayText,
  open,
  quantityToggleEnabled,
  isOneSize,
  children,
}: PropsWithChildren<Props>) => (
  <XxlStack direction={"row"} gap={xxlTheme.spaces.micro}>
    {quantityToggleEnabled ? children : null}
    <StyledSelectButton
      disabled={disabled}
      hasError={hasError}
      onClick={onClick}
      size={"small"}
      data-testid="size-options-button"
    >
      <VariantSelectContent text={displayText}>
        {!isOneSize && (
          <SelectCaret fontSize={24} disabled={disabled} open={open} />
        )}
      </VariantSelectContent>
    </StyledSelectButton>
  </XxlStack>
);
