import React from "react";
import { XxlButton } from "react-app/src/components/Common/XxlButton";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { VariantSelectDrawer } from "./VariantSelect/VariantSelectDrawer";
import { Message, StickyFormContent } from "./styles";
import type { TProductForm } from "./types";

export const ProductFormPreSale = (
  props: TProductForm & {
    btnRef: React.MutableRefObject<null>;
    isRefInView: boolean;
  }
) => {
  const {
    // preSelectedSizeCode,
    // quantityToggleEnabled,
    btnRef,
    isRefInView,
    isVariantSelectOpen,
    product,
    toggleVariantSelectDrawer,
    selectedVariantCode,
    onVariantSelect,
  } = props;
  const { t } = useTranslations();
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const variantClickHandler = (variantCode: string) => {
    onVariantSelect({ action: "select-variant", variantCode });
    toggleVariantSelectDrawer();
  };
  const preSaleContent = (
    <>
      <XxlButton icon="Email" onClick={toggleVariantSelectDrawer}>
        {t("product.details.presale.form.button.label")}
      </XxlButton>
      <Message text={t("product.details.delivery.message.presale")} />
    </>
  );

  return (
    <>
      <XxlStack spacing={xxlTheme.spaces.micro} ref={btnRef}>
        <VariantSelectDrawer
          isLoading={false}
          product={product}
          onClose={toggleVariantSelectDrawer}
          isOpen={isVariantSelectOpen}
          heading={t("product.details.select.size")}
          onVariantClick={variantClickHandler}
          isVariantSelected={selectedVariantCode !== null}
        />

        {preSaleContent}
      </XxlStack>

      <StickyFormContent
        shouldShowStickyContent={!isRefInView && !isLaptopSize}
      >
        {preSaleContent}
      </StickyFormContent>
    </>
  );
};
