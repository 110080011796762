import { CONTENT_NAMES, type ContentName } from "@/react-app/api/elevate-api";
import { EMPTY_STRING, FILTER_SEPARATOR } from "@/react-app/constants";
import type { TranslationKey } from "@/react-app/translations";
import type { TabProps } from "@/react-components/Common/Tabs";
import type {
  BrandsDataProps,
  FaqDataProps,
  OpeningHours,
  StoresDataProps,
} from "@/react-components/Search/SearchState";
import type { SearchSuggestionsType } from "@/react-components/Search/SearchSuggestions";
import type { StoreWithId } from "@/react-components/Sort/AvailabilitySelector/DialogStoreSelect";
import type { GuidePreviewContentDataWithTrackingTicket } from "@/react-components/guideCarousel/GuideTeaser";
import { cookieNames, parseCookie } from "@/react-utils/Cookie";
import {
  hasNoValue,
  hasValue,
  isEmpty,
  isNotEmpty,
  isNotNull,
  isNotNullOrUndefined,
} from "@xxl/common-utils";
import type {
  Breadcrumb,
  ContentData,
  ContentListData,
  FacetData,
} from "@xxl/product-search-api";
import type { IncomingMessage, ServerResponse } from "http";
import isString from "lodash/isString";
import type { ParsedUrlQuery } from "querystring";
import type { EcomSiteUidLegacy, XXLCookie } from "react-app/src/global";
import { URL_PARAMETERS } from "../../components/ProductListV2/constants";
import { getStores } from "../apis/content-stores";
import {
  COOKIE_EXPIRATION_IN_SECONDS,
  createCookieString,
  createXxlCookie,
} from "../cookies/server-side-cookie-helper";
import type { queryParamType } from "../page-helper";
import {
  getCachedStoreData,
  setCachedStoreData,
} from "../server-side-cache/server-side-cache";
import { UNSUPPORTED_FACET_IDS, UNSUPPORTED_FACET_TYPES } from "./constants";
import type { CategoryData } from "@xxl/pim-api";
import { log } from "@xxl/logging-utils";

const {
  brand,
  digitalCampaignPage,
  faqEntry,
  guide,
  products,
  store: storeContentTabName,
} = CONTENT_NAMES;
const SUPPORTED_TABS = [
  brand,
  digitalCampaignPage,
  faqEntry,
  guide,
  products,
  storeContentTabName,
];

const isValidSearchQuery = (query: queryParamType): query is string =>
  isString(query) && isNotEmpty(query);

type TabWithTranslationKeyAndContent = Omit<TabProps, "name" | "id"> & {
  name: TranslationKey;
  id: ContentName;
  items: ContentData[];
};

const getTranslationKeyToContentName = (
  contentName: string
): TranslationKey => {
  switch (contentName) {
    case CONTENT_NAMES.brand:
      return "header.search.suggestions.brands" as TranslationKey;
    case CONTENT_NAMES.digitalCampaignPage:
      return "header.search.suggestions.campaignHub" as TranslationKey;
    case CONTENT_NAMES.category:
      return "header.search.suggestions.categories" as TranslationKey;
    case CONTENT_NAMES.faqEntry:
      return "header.search.suggestions.faq" as TranslationKey;
    case CONTENT_NAMES.guide:
      return "header.search.suggestions.guides" as TranslationKey;
    case CONTENT_NAMES.store:
      return "header.search.suggestions.stores" as TranslationKey;
    default:
      return "header.search.suggestions.other" as TranslationKey;
  }
};

const getSelectedFiltersFromUrlParameters = (urlParameters: {
  [key: string]: queryParamType;
}) => {
  const selectedFilters = Object.fromEntries(
    Object.entries(urlParameters)
      .filter(([key]) => key.includes(URL_PARAMETERS.facet.name))
      .filter((filter): filter is [string, string] => isString(filter.at(1)))
  );

  const selectedFiltersFormatted = Object.fromEntries(
    Object.entries(selectedFilters).map(([key, value]) => [
      key.replace(URL_PARAMETERS.facet.name, EMPTY_STRING),
      value.split(FILTER_SEPARATOR),
    ])
  );

  return {
    selectedFilters,
    selectedFiltersFormatted,
  };
};

const convertContentListsToTabs = (
  contentLists: ContentListData[],
  numberOfProducts = 0
): TabWithTranslationKeyAndContent[] => {
  const generatedTabList = contentLists
    .filter(({ id }) => SUPPORTED_TABS.some((tab) => tab === id))
    .map((contentList) => ({
      count: contentList.totalHits,
      id: contentList.id as ContentName,
      isActive: false,
      items: isNotEmpty(contentList.items) ? contentList.items : [],
      name: getTranslationKeyToContentName(contentList.id),
    }));

  return numberOfProducts > 0
    ? [
        {
          count: numberOfProducts,
          id: CONTENT_NAMES.products,
          isActive: false,
          items: [],
          name: "header.search.suggestions.products",
        },
        ...generatedTabList,
      ]
    : generatedTabList;
};

type RelatedSearchType = { q?: string }[] | undefined;
const prepareSearchSuggestions = (
  initialRelatedSearches: RelatedSearchType
): SearchSuggestionsType => {
  if (initialRelatedSearches === undefined) {
    return [];
  }

  return initialRelatedSearches
    .filter((relatedSearch) => Boolean(relatedSearch.q))
    .map((relatedSearch) => relatedSearch.q) as SearchSuggestionsType;
};

const getItemsImageBaseUrl = (item: ContentData) => {
  if (
    isEmpty(item.image) ||
    !Array.isArray(item.image) ||
    isEmpty(item.image[0].sources) ||
    !Array.isArray(item.image[0].sources) ||
    isEmpty(item.image[0].sources[0].url)
  ) {
    return "";
  }
  return item.image[0].sources[0].url;
};

const getCorrectTabNameOrFirstOrNull = (
  tabName: queryParamType,
  tabs: TabWithTranslationKeyAndContent[],
  noProductsFound: boolean
): ContentName | null => {
  if (
    isString(tabName) &&
    isNotEmpty(tabName) &&
    Object.values(CONTENT_NAMES).includes(tabName as ContentName)
  ) {
    return tabName as ContentName;
  }
  if (!noProductsFound) {
    return CONTENT_NAMES.products;
  }
  return tabs.find(({ items }) => isNotEmpty(items))?.id ?? null;
};

const getGuidesFromTabs = (
  tabs: TabWithTranslationKeyAndContent[]
): GuidePreviewContentDataWithTrackingTicket[] =>
  tabs
    .find((tab) => tab.id === CONTENT_NAMES.guide)
    ?.items.map((item) => ({
      image: {
        ...item.image,
        baseUrl: getItemsImageBaseUrl(item),
        link: item.link,
      },
      metaDescription: item.description,
      pageTitle: item.title,
      preamble: item.description,
      title: item.title,
      url: item.link,
      ticket: item.ticket,
    })) ?? [];

const getBrandsFromTabs = (
  tabs: TabWithTranslationKeyAndContent[]
): BrandsDataProps[] =>
  tabs
    .find((tab) => tab.id === CONTENT_NAMES.brand)
    ?.items.map((item) => ({
      brandUrl: item.link,
      code: false,
      id: item.title,
      logo: { alt: item.title, url: getItemsImageBaseUrl(item) },
      name: item.title,
      ticket: item.ticket,
    })) ?? [];

const getCampaignsFromTabs = (tabs: TabWithTranslationKeyAndContent[]) =>
  tabs
    .find((tab) => tab.id === CONTENT_NAMES.digitalCampaignPage)
    ?.items.map(({ custom, ticket, title }) => {
      const { contentCustomAttributes } = custom ?? {};
      const [contentCustomAttribute] = hasValue(contentCustomAttributes)
        ? contentCustomAttributes
        : [null];
      const { slug } = contentCustomAttribute ?? {};

      if (typeof slug !== "string") {
        return null;
      }

      return {
        slug,
        ticket,
        title,
      };
    })
    .filter(isNotNull) ?? [];

const getFaqsFromTabs = (
  tabs: TabWithTranslationKeyAndContent[]
): FaqDataProps[] =>
  tabs
    .find((tab) => tab.id === CONTENT_NAMES.faqEntry)
    ?.items.map((item) => ({
      id: item.title,
      title: item.title,
      url: item.link,
      preamble: item.description,
      ticket: item.ticket,
    })) ?? [];

const getStoresFromTabs = (
  tabs: TabWithTranslationKeyAndContent[]
): StoresDataProps[] =>
  tabs
    .find((tab) => tab.id === CONTENT_NAMES.store)
    ?.items.map(({ custom, link, ticket, title }) => {
      const { contentCustomAttributes } = custom ?? {};
      const [firstItem] = contentCustomAttributes ?? [];
      const openingHours =
        "openingHours" in firstItem
          ? (firstItem.openingHours as OpeningHours[])
          : [];

      return {
        id: title,
        name: title,
        openingHours,
        link,
        ticket,
      };
    }) ?? [];

const setCookies = async ({
  cookies,
  req,
  res,
}: {
  cookies: Partial<{ [key: string]: string }>;
  req: IncomingMessage & {
    cookies: Partial<{
      [key: string]: string;
    }>;
  };
  res: ServerResponse;
}) => {
  const xxlCookie = cookies[cookieNames.XXL];
  const cookiesToSet = [];
  const { host } = req.headers;
  // const domain = host?.split(".").slice(1).join(".").split(":")[0]; // reverts this change to check
  const domain =
    isNotNullOrUndefined(host) && host.startsWith("localhost.")
      ? host.replace("localhost.", "")
      : host;
  const sessionKey = cookies[cookieNames.SESSION_KEY] ?? null;
  const customerKey = parseCookie<XXLCookie>(
    xxlCookie,
    cookieNames.XXL
  )?.customerKey;
  const userKeys = {
    customerKey: isNotNullOrUndefined(customerKey) ? customerKey : "",
    sessionKey: isNotNullOrUndefined(sessionKey) ? sessionKey : "",
  };

  if (userKeys.sessionKey.length === 0) {
    userKeys.sessionKey = crypto.randomUUID();
    cookiesToSet.push(
      createCookieString({
        cookie: userKeys.sessionKey,
        domain,
        maxAge: "Session",
        name: cookieNames.SESSION_KEY,
      })
    );
  }

  if (userKeys.customerKey.length === 0) {
    const _xxlCookie = await createXxlCookie();
    userKeys.customerKey = _xxlCookie.customerKey;
    cookiesToSet.push(
      createCookieString({
        cookie: _xxlCookie,
        doubleEncode: true,
        domain,
        maxAge: COOKIE_EXPIRATION_IN_SECONDS,
        name: cookieNames.XXL,
      })
    );
  }

  if (cookiesToSet.length > 0) {
    res.setHeader("Set-Cookie", cookiesToSet);
  }

  return userKeys;
};

const getCachedOrFetchedStoreData = async (siteUid: EcomSiteUidLegacy) => {
  let cachedStoresData: Required<StoreWithId>[] | null = null;
  try {
    cachedStoresData = await getCachedStoreData();
  } catch (error) {
    log.error("Failed to retrieve cached stores data", error);
  }
  const stores = cachedStoresData ?? (await getStores(siteUid));

  if (cachedStoresData === null) {
    void setCachedStoreData(stores);
  }

  return stores.filter((store) => store.elevateEnabled);
};

const sanitizeFacetsForDisplay = (initialFacets: FacetData[]) => {
  if (isEmpty(initialFacets)) {
    return [];
  }

  return initialFacets
    .filter(
      (facet) =>
        isNotNullOrUndefined(facet) &&
        !UNSUPPORTED_FACET_TYPES.some(
          (unsupportedType) => unsupportedType === facet.type
        ) &&
        !UNSUPPORTED_FACET_IDS.some(
          (unsupportedId) => unsupportedId === facet.id
        )
    )
    .map(({ id, label, type, ...initialFacet }) => {
      if (hasNoValue(id) || hasNoValue(label) || hasNoValue(type)) {
        return null;
      }
      const facet = {
        ...initialFacet,
        id,
        label,
        type,
      };
      if ("sizeTypes" in facet) {
        const { sizeTypes = [], ...restOfFacet } = facet;
        const values = sizeTypes.flatMap(({ formats = [] }) =>
          formats.flatMap(({ values: v }) => v)
        );
        return {
          ...restOfFacet,
          values,
        };
      }
      return facet;
    })
    .filter(isNotNullOrUndefined);
};

const addUrlParameter = ({
  parsedQuery,
  parameterToAdd,
  parameterValue,
}: {
  parsedQuery: ParsedUrlQuery;
  parameterToAdd: string;
  parameterValue: string;
}) => {
  const param = parsedQuery[parameterToAdd];

  if (param !== undefined) {
    log.error(`Parameter "${parameterToAdd}" already exists.`);
    return parsedQuery;
  }

  parsedQuery[parameterToAdd] = parameterValue;
  return parsedQuery;
};

const getIsLoggedIn = (cookies: Partial<{ [key: string]: string }>) => {
  const xxlCookie = cookies[cookieNames.XXL];
  const { loggedIn: isLoggedIn = false } =
    parseCookie<XXLCookie>(xxlCookie ?? "", cookieNames.XXL) ?? {};
  return isLoggedIn;
};

const getPriceId = (isLoggedIn: boolean) =>
  isLoggedIn ? ("member" as const) : ("anonymous" as const);

// Elevate bug. It accepts undefined, "STORE", "ONLINE", "STORE|ONLINE", but the later is not included in type.
const getChannel = (channels: string | undefined) =>
  channels as "ONLINE" | "STORE" | undefined;

const hasValidBreadcrumbData = (b: {
  label?: string;
  path?: string;
  seoFriendlyURL?: string;
}): b is Breadcrumb =>
  hasValue(b.path?.match(/\d/)) &&
  "seoFriendlyURL" in b &&
  typeof b.seoFriendlyURL === "string" &&
  hasValue(b.seoFriendlyURL);

const hasProductCount = ({ productCount }: CategoryData) =>
  hasValue(productCount);
const categoryWithProducts = ({
  subCategories,
  ...category
}: CategoryData): CategoryData[] => {
  if (category.productCount === 0) {
    return [];
  }
  const filteredSubCategories = subCategories
    ?.filter(hasProductCount)
    .flatMap(categoryWithProducts);
  return [
    {
      ...category,
      ...(hasValue(filteredSubCategories)
        ? { subCategories: filteredSubCategories }
        : undefined),
    },
  ];
};

export {
  // eslint-disable-next-line import/no-unused-modules -- used in tests
  addUrlParameter,
  categoryWithProducts,
  convertContentListsToTabs,
  getBrandsFromTabs,
  getCampaignsFromTabs,
  getCachedOrFetchedStoreData,
  getChannel,
  getCorrectTabNameOrFirstOrNull,
  getFaqsFromTabs,
  getGuidesFromTabs,
  getIsLoggedIn,
  // eslint-disable-next-line import/no-unused-modules -- used in tests
  getItemsImageBaseUrl,
  getPriceId,
  getSelectedFiltersFromUrlParameters,
  getStoresFromTabs,
  hasProductCount,
  hasValidBreadcrumbData,
  isValidSearchQuery,
  prepareSearchSuggestions,
  sanitizeFacetsForDisplay,
  setCookies,
  type TabWithTranslationKeyAndContent,
};
