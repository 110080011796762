import type { ReviewTranslationData } from "react-app/src/components/RatingsAndReviews/Api/RatingsAndReviewsAPI";
import type { EcomSiteUidLegacy } from "react-app/src/global";
import { siteUidToLanguage } from "react-app/src/utils/xxl-language";
import {
  languageCodeToTranslationKeyMap,
  supportedLanguages,
} from "react-app/src/components/RatingsAndReviews/Reviews/Reviews";

const MAX_REVIEW_TEXT_LENGTH = 115;

export const clipText = (
  text: string,
  maxLength = MAX_REVIEW_TEXT_LENGTH
): string =>
  text.length > maxLength ? text.slice(0, maxLength - 3) + "..." : text;

export const formatDate = (date: number) =>
  new Date(date).toISOString().split("T")[0];

export const getTranslation = (
  translations: ReviewTranslationData[],
  siteUid: EcomSiteUidLegacy
) => {
  const siteSourceLanguage = siteUidToLanguage[siteUid];

  const originalLanguageReview = translations.find(
    ({ sourceLanguage, language }) =>
      sourceLanguage === siteSourceLanguage && language === siteSourceLanguage
  );

  if (originalLanguageReview !== undefined) {
    return originalLanguageReview;
  }

  const siteLanguageReview = translations.find(
    ({ language }) => language !== "fi" && language === siteSourceLanguage
  );

  /* Fallback to swedish in Norway */
  if (siteLanguageReview === undefined && siteUid === "xxl") {
    return translations.find(({ language }) => language === "sv");
  }

  return (
    siteLanguageReview ?? translations.find(({ language }) => language === "en")
  );
};

export const getTranslatedInfoText = (
  translation: ReviewTranslationData,
  siteUid: EcomSiteUidLegacy
) => {
  const { sourceLanguage } = translation;
  const siteSourceLanguage = siteUidToLanguage[siteUid];

  if (sourceLanguage === siteSourceLanguage) {
    return "";
  }

  const isSupportedLanguage = supportedLanguages.includes(sourceLanguage);
  const fallbackText = "product.ratings-and-reviews.translated.auto";

  return isSupportedLanguage
    ? languageCodeToTranslationKeyMap[sourceLanguage]
    : fallbackText;
};
