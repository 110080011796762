import spaces from "@/react-app/styles/theme/spaces";
import { Text } from "@/react-components/Text/Text";
import styled from "@emotion/styled/macro";
import * as React from "react";

const NotInStockMessage = styled(Text)`
  margin-bottom: ${spaces.smallRegular};
`;

type NotInStockMessageProps = {
  messageText: string;
  testId: string;
};

export const NotInStockMessageStyled: React.FunctionComponent<
  NotInStockMessageProps
> = ({ messageText, testId }) => (
  <NotInStockMessage data-testid={testId}>{messageText}</NotInStockMessage>
);
