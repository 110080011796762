import React from "react";
import { useStateValue } from "cotton-box-react";
import { useSessionSource } from "../../../contexts/Session";
import { useSharedData } from "../../../contexts/SharedData";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import type { ProductType } from "../../../utils/data-types";
import { getFrontendPriceDisplayData } from "../../../utils/PriceDisplay/price-display";
import type { GetFrontendPriceDisplayDataProps } from "../../../utils/PriceDisplay/types";
import {
  getPriceDisplaysForPackages,
  isSoldInPackages,
} from "../product-helper";
import { PriceDescriptionWidget } from "../Product.styled";
import { PriceWithLabelsV1 } from "./PriceWithLabelsV1";
import { PriceWithLabelsV2 } from "./PriceWithLabelsV2";
import { log } from "@xxl/logging-utils";
import type { PriceData } from "../../../utils/ProductData/product-card-data-helper";
import { PriceType } from "@xxl/product-search-api";

type PriceWithLabelsProps =
  | {
      productType: ProductType;
      version: 1;
      priceDisplay?: GetFrontendPriceDisplayDataProps["priceDisplay"];
      productCode?: string;
      showPackagePrice?: boolean;
      selectedColumnsNumber: number;
      t: Translate;
      units?: {
        code?: string;
        visible?: boolean;
      }[];
      widget?: JSX.Element;
      hasRewardsPrices?: boolean;
    }
  | {
      priceData: {
        selling: PriceData;
        alternate?: PriceData;
        disclaimer?: string;
        type?: PriceType;
      };
      selectedColumnsNumber: number;
      hasRewardPrices?: boolean;
      version: 2;
    };

const PriceWithLabels: React.FunctionComponent<
  PriceWithLabelsProps & { isCompact?: boolean }
> = (props) => {
  const {
    siteUid,
    featureToggles: { toggle_products_as_package_quantity },
  } = useSharedData().data;
  const isLoggedIn = useStateValue(useSessionSource);
  const isRewardsProduct =
    (props.version === 1 &&
      props.priceDisplay !== undefined &&
      props.priceDisplay.invertedPrice !== undefined) ||
    (props.version === 2 && props.priceData.type === PriceType.REWARD);
  const isCampaignProduct =
    (props.version === 1 &&
      props.priceDisplay !== undefined &&
      props.priceDisplay.previousPriceFormatted !== undefined &&
      props.priceDisplay.previousPriceFormatted.length > 0) ||
    (props.version === 2 && props.priceData.type === PriceType.CAMPAIGN);

  let cheapestLatestPrice = "";
  if (
    props.version === 1 &&
    props.priceDisplay !== undefined &&
    props.priceDisplay.invertedPrice !== undefined &&
    props.priceDisplay.salesPrice !== undefined &&
    props.priceDisplay.invertedPriceFormatted !== undefined &&
    props.priceDisplay.salesPriceFormatted !== undefined
  ) {
    cheapestLatestPrice =
      props.priceDisplay.invertedPrice > props.priceDisplay.salesPrice
        ? props.priceDisplay.invertedPriceFormatted
        : props.priceDisplay.salesPriceFormatted;
  } else if (
    props.version === 1 &&
    props.priceDisplay !== undefined &&
    props.priceDisplay.previousPriceFormatted !== undefined
  ) {
    cheapestLatestPrice = props.priceDisplay.previousPriceFormatted;
  }

  const showDisclaimer =
    siteUid === "xxl-fi" && !isRewardsProduct && !isCampaignProduct;

  if (props.version === 2) {
    const { selectedColumnsNumber, priceData } = props;
    return (
      <PriceWithLabelsV2
        selectedColumnsNumber={selectedColumnsNumber}
        priceData={priceData}
        showDisclaimer={showDisclaimer}
        hasRewardPrices={props.hasRewardPrices}
      />
    );
  }

  const {
    productCode,
    productType,
    priceDisplay,
    showPackagePrice = false,
    selectedColumnsNumber,
    t,
    units,
    isCompact = false,
  } = props;
  const isMultiPackProduct = productType === "MULTIPACK";
  const showPackagePriceValues = showPackagePrice || isMultiPackProduct;
  const isPackageSell = isSoldInPackages(units) || isMultiPackProduct;
  const priceDisplayWithPackages =
    isPackageSell &&
    toggle_products_as_package_quantity &&
    showPackagePriceValues
      ? getPriceDisplaysForPackages(priceDisplay, units)
      : priceDisplay;

  if (priceDisplayWithPackages === undefined) {
    log.error(
      `Price display is undefined for product with code ${productCode !== undefined ? productCode : "undefined"}`
    );
    return null;
  }

  const frontendPriceDisplay = getFrontendPriceDisplayData({
    isLoggedIn,
    priceDisplay: priceDisplayWithPackages,
    siteUid,
    t,
  });

  return (
    <PriceWithLabelsV1
      isMultiPackProduct={productType === "MULTIPACK"}
      priceDisplay={{
        hasLowerProductConfiguratorPriceThanSalesPrice:
          frontendPriceDisplay.hasLowerProductConfiguratorPriceThanSalesPrice,
        isDiscountedMainPrice: frontendPriceDisplay.isDiscountedMainPrice,
        isInvertedPrice: frontendPriceDisplay.isInvertedPrice,
        salesPrice: frontendPriceDisplay.salesPrice,
        otherPrice: frontendPriceDisplay.otherPrice,
        otherPriceDisclaimer: frontendPriceDisplay.otherPriceDisclaimer,
        salesPriceDisclaimer: frontendPriceDisplay.salesPriceDisclaimer,
        invertedPrice: priceDisplay?.invertedPrice,
        invertedPriceFormatted: priceDisplay?.invertedPriceFormatted,
        type: priceDisplay?.type,
      }}
      selectedColumnsNumber={selectedColumnsNumber}
      productCode={productCode}
      widget={
        isPackageSell &&
        toggle_products_as_package_quantity &&
        showPackagePriceValues ? (
          <PriceDescriptionWidget>
            {t("product.unit.per.box.description")}
          </PriceDescriptionWidget>
        ) : undefined
      }
      isCompact={isCompact}
      cheapestLatestPrice={cheapestLatestPrice}
      hasRewardsPrices={props.hasRewardsPrices}
      isRewardsProduct={isRewardsProduct || isCampaignProduct}
    />
  );
};

export { PriceWithLabels };
export type { PriceWithLabelsProps };
