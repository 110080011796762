import type { Unit } from "@xxl/pim-api";
import type { Dispatch } from "react";
import {
  ADD_TO_CART,
  CART_EDITION_START,
  CART_EDITION_STOP,
  SET_CART_COUNT,
  type Action,
} from "../CartState";
import { BOX_UNIT, PACKAGE_UNIT } from "../../Product/product-helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { SUCCESS_RESPONSE_STATUS, ERROR_RESPONSE_STATUS } from "../Api/CartAPI";
import { addBundleWithEanToCart } from "../Api/CartBundleAPI";
import type { UpdateQuantityGraphQLError } from "../Api/types";
import type { Trackers } from "../../../contexts/Tracking";
import * as XxlEvent from "../../../utils/xxl-event";
import type { CartEventData } from "../../../global";
import { QUANTITY_ONE } from "../../../constants";
import { log } from "@xxl/logging-utils";

export type addMultipackBundleToCartConfigProps = {
  bundleEan: string;
  bundledProductEan: string;
  units: Unit[];
  dispatch: Dispatch<Action>;
  trackers: Trackers;
  graphqlEndpoint: string;
  graphqlApiKey: string;
  trackingListName: string;
  quantity?: number;
};

export const addMultipackBundleToCart = async (
  options: addMultipackBundleToCartConfigProps
) => {
  const {
    bundleEan,
    bundledProductEan,
    units,
    dispatch,
    trackers,
    graphqlEndpoint,
    graphqlApiKey,
    trackingListName,
    quantity,
  } = options;
  const productsUnit = units.find(
    (item) =>
      (item.code === PACKAGE_UNIT || item.code === BOX_UNIT) &&
      String(item.visible) === "true"
  );

  try {
    dispatch({
      type: CART_EDITION_START,
    });
    dispatch({
      type: ADD_TO_CART,
    });
    const { status, data } = await addBundleWithEanToCart(
      bundleEan,
      [
        {
          ean: bundledProductEan,
          quantity: quantity ?? QUANTITY_ONE,
        },
      ],
      [],
      graphqlEndpoint,
      graphqlApiKey,
      productsUnit?.code
    );

    if (
      status === SUCCESS_RESPONSE_STATUS &&
      data.data?.addBundleProductsToCart !== undefined
    ) {
      const { items } = data.data.addBundleProductsToCart;

      const sameEanBundlesArray = items.filter(
        (item) => item.ean === String(bundleEan)
      );
      const sameBundlesIds = sameEanBundlesArray.map((item) => item.itemId.id);
      const highestBundleId = Math.max(...sameBundlesIds);
      const addedBundle = sameEanBundlesArray.find(
        ({ itemId }) => itemId.id === highestBundleId
      );
      if (isNotNullOrUndefined(addedBundle)) {
        trackers.sendAddMultipackBundleEvent({
          bundle: addedBundle,
          list: trackingListName,
        });
      }
      const cartEventData: CartEventData = {
        count: data.data.addBundleProductsToCart.totals.itemsCount,
      };

      dispatch({
        type: SET_CART_COUNT,
        payload: {
          count: data.data.addBundleProductsToCart.totals.itemsCount,
        },
      });
      XxlEvent.dispatchEvent<CartEventData>(
        XxlEvent.XXL_CART_UPDATE,
        cartEventData
      );
    } else if (status === ERROR_RESPONSE_STATUS) {
      const hasOutOfStockErrors =
        data.errors?.some(
          (err) =>
            "errorType" in err &&
            (err as UpdateQuantityGraphQLError).errorType === "OUT_OF_STOCK"
        ) ?? false;
      if (hasOutOfStockErrors) {
        log.error("Out of stock errors", data.errors);
      } else {
        log.error("Adding multipack bundle to cart error", data.errors);
      }
    }
  } catch (err) {
    log.error("Cannot add multipack bundle to cart", err);
  } finally {
    dispatch({
      type: CART_EDITION_STOP,
    });
  }
};
