import { DEFAULT_PICKUP_READY_HOURS } from "@/react-app/constants";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlButton } from "@/react-components/Common/XxlButton";
import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";
import { Text } from "@/react-components/Text";
import { Dialog, Drawer } from "@mui/material";
import stringFormat from "string-format";
import { StoreStockStatusIndicator } from "../StoreStock/StoreStockStyles";
import type { StockLevel } from "../types";
import {
  useTranslationCCStockStatus,
  useTranslationStoreStockStatus,
} from "./helpers";
import { CloseButton } from "./styles";

type Props = {
  onAtcClick: () => void;
  onClose: () => void;
  isOpen: boolean;
  storeName: string;
  closeBtnText?: string;
  pickupHoursText: string;
  addToCartText: string;
  headingText: string;
  selectedStoreHeaderText: string;
  collectableStockLevel: StockLevel;
  collectableStockLevelText: string;
  storeStockLevel: StockLevel;
  storeStockLevelText: string;
  infoText: string;
};

const DesktopDialog = ({
  isOpen,
  onClose,
  storeName,
  onAtcClick,
  pickupHoursText,
  closeBtnText,
  addToCartText,
  headingText,
  selectedStoreHeaderText,
  collectableStockLevel,
  collectableStockLevelText,
  storeStockLevel,
  storeStockLevelText,
  infoText,
}: Props) => (
  <Dialog open={isOpen} onClose={onClose}>
    <XxlStack p={"24px"} pb={"48px"}>
      <XxlStack pb={"12px"} direction={"row"} justifyContent={"space-between"}>
        <Text typography="baseBold" size="big">
          {headingText}
        </Text>
        <CloseButton onClick={onClose}>
          <Icon size={34} name="Close" />
        </CloseButton>
      </XxlStack>
      <XxlStack
        direction={"row"}
        gap={"4px"}
        bgcolor={xxlTheme.colors.xxlLightGrey}
        p={"12px"}
        mb={"24px"}
      >
        <span>
          <Icon
            name="Info"
            transform={{
              translateX: 0,
              translateY: 2,
            }}
          />
        </span>
        <Text typography="baseBold">{infoText}</Text>
      </XxlStack>
      <Text typography="baseBold">{selectedStoreHeaderText}</Text>
      <XxlStack pt={"12px"} />
      <XxlStack direction={"row"}>
        <XxlStack width={"50%"}>
          <Text typography={"baseBold"}>{storeName}</Text>
          <XxlStack pt={"12px"} />
          <Text>{pickupHoursText}</Text>
        </XxlStack>
        <XxlStack gap={"4px"} width={"50%"}>
          <XxlStack direction={"row"} alignItems={"center"}>
            <StoreStockStatusIndicator stockStatus={collectableStockLevel} />
            <Text typography="small">{collectableStockLevelText}</Text>
          </XxlStack>
          <XxlStack direction={"row"} alignItems={"center"}>
            <StoreStockStatusIndicator stockStatus={storeStockLevel} />
            <Text typography="small">{storeStockLevelText}</Text>
          </XxlStack>
        </XxlStack>
      </XxlStack>
    </XxlStack>
    <XxlStack
      p={"0 24px 24px 24px"}
      gap={"12px"}
      direction={"row"}
      display={"grid"}
      gridTemplateColumns={"1fr 1fr 1fr"}
    >
      <span />
      <XxlButton variant="outlined" onClick={onClose}>
        {closeBtnText}
      </XxlButton>
      <XxlButton
        onClick={onAtcClick}
        disabled={collectableStockLevel === "OUTOFSTOCK"}
      >
        <Icon name="Plus" /> {addToCartText}
      </XxlButton>
    </XxlStack>
  </Dialog>
);

const MobileDialog = ({
  storeName,
  onAtcClick,
  isOpen = true,
  onClose,
  pickupHoursText,
  addToCartText,
  headingText,
  selectedStoreHeaderText,
  collectableStockLevel,
  collectableStockLevelText,
  storeStockLevel,
  storeStockLevelText,
  infoText,
}: Props) => (
  <Drawer open={isOpen} onClose={onClose} anchor="bottom">
    <XxlStack px={"12px"} pt={"24px"} pb={"36px"}>
      <XxlStack pb={"12px"} direction={"row"} justifyContent={"space-between"}>
        <Text typography="baseBold" size="big">
          {headingText}
        </Text>
        <CloseButton onClick={onClose}>
          <Icon size={22} name="CloseIconSlim" />
        </CloseButton>
      </XxlStack>
      <XxlStack
        direction={"row"}
        gap={"4px"}
        justifyContent={"space-between"}
        bgcolor={xxlTheme.colors.xxlLightGrey}
        p={"12px"}
      >
        <span>
          <Icon
            name="Info"
            transform={{
              translateX: 0,
              translateY: 2,
            }}
          />
        </span>
        <Text typography="baseBold">{infoText}</Text>
      </XxlStack>
      <XxlStack gap={"12px"} pt={"24px"}>
        <Text typography="baseBold">{selectedStoreHeaderText}</Text>
        <Text typography={"baseBold"}>{storeName}</Text>
        <Text>{pickupHoursText}</Text>
        <XxlStack justifyContent={"end"} gap={"4px"}>
          <XxlStack direction={"row"} alignItems={"center"}>
            <StoreStockStatusIndicator stockStatus={collectableStockLevel} />
            <Text typography="small">{collectableStockLevelText}</Text>
          </XxlStack>
          <XxlStack direction={"row"} alignItems={"center"}>
            <StoreStockStatusIndicator stockStatus={storeStockLevel} />
            <Text typography="small">{storeStockLevelText}</Text>
          </XxlStack>
        </XxlStack>
      </XxlStack>
    </XxlStack>
    <XxlStack p={"0 12px 12px 12px"}>
      <XxlButton
        onClick={onAtcClick}
        disabled={collectableStockLevel === "OUTOFSTOCK"}
      >
        <Icon name="Plus" /> {addToCartText}
      </XxlButton>
    </XxlStack>
  </Drawer>
);

export const StoreInCartDialog = ({
  isOpen,
  onClose,
  storeName,
  onAtcClick,
  isMobile,
  collectableStockLevel,
  storeStockLevel,
}: {
  isOpen: boolean;
  onClose: () => void;
  storeName: string;
  onAtcClick: () => void;
  isMobile: boolean;
  collectableStockLevel: StockLevel;
  storeStockLevel: StockLevel;
}) => {
  const { t } = useTranslations();

  const addToCartText = t("product.details.add.to.cart.label");
  const closeBtnText = t("general.close");
  const headingText = t("product.details.clickcollect.title");
  const selectedStoreHeaderText = t("storeselect.selected.stores.header");
  const pickupHoursText = stringFormat(
    t("product.details.clickcollect.ready.for.pickup"),
    DEFAULT_PICKUP_READY_HOURS.toString()
  );
  const storeStockLevelText = useTranslationStoreStockStatus(storeStockLevel);
  const collectableStockLevelText = useTranslationCCStockStatus(
    collectableStockLevel
  );
  const infoText = t("product.details.clickcollect.info.message");

  const DialogComponent = isMobile ? MobileDialog : DesktopDialog;

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      storeName={storeName}
      onAtcClick={onAtcClick}
      pickupHoursText={pickupHoursText}
      headingText={headingText}
      closeBtnText={closeBtnText}
      addToCartText={addToCartText}
      selectedStoreHeaderText={selectedStoreHeaderText}
      collectableStockLevel={collectableStockLevel}
      collectableStockLevelText={collectableStockLevelText}
      storeStockLevel={storeStockLevel}
      storeStockLevelText={storeStockLevelText}
      infoText={infoText}
    />
  );
};
