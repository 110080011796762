import { Collapse, useMediaQuery, useTheme } from "@mui/material";
import { CaretDown } from "@xxl/icons";
import type { DeliveryOptionsResponse } from "@xxl/shipments-api";
import {
  Configuration,
  DeliveryOptionTypeEnum,
  ShipmentsApi,
} from "@xxl/shipments-api";
import ReactHtmlParser from "html-react-parser";
import isEmpty from "lodash/isEmpty";
import * as React from "react";
import { useEffect, useRef } from "react";
import stringFormat from "string-format";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { XxlSizeSelectEventPayload } from "../../../global";
import { ButtonStyledAsLink } from "../../../styled";
import { xxlTheme } from "../../../styles/xxl-theme";
import type { TranslationKey } from "../../../translations";
import { setStorageItem } from "../../../utils/LocalStorage/local-storage";
import { assertNever } from "../../../utils/xxl-assert-never";
import { XxlStack } from "../../Common/XxlStack";
import {
  Content,
  DeliveryToggleButton,
  ErrorMessage,
  OptionsWrapper,
} from "./DeliveryInfo.styled";
import { DeliveryOptions } from "./DeliveryOptions";
import { Header, HeaderSection, Wrapper } from "./HeaderSection";
import { MainMessage } from "./MainMessage/MainMessage";
import { PostalCodeEntry } from "./PostalCodeEntry";
import type { CutOffTimeDeliveryOptions } from "./types";
import { ExpressDeliveryDateSupport } from "./types";
import { log } from "@xxl/logging-utils";

type DeliveryInfoProps = {
  productCode: string | null;
  hasSidePadding?: boolean;
  isProductFrom3rdParty: boolean;
};

const postalCodeKey = "USER_POSTAL_CODE";
const {
  colors: { xxlLightAmber },
  spaces: { mini },
} = xxlTheme;

enum ViewState {
  INIT,
  AWAITING_INPUT,
  AWAITING_SIZE,
  FETCHING_DELIVERY_INFO,
  DELIVERY_INFO_RECEIVED,
  ERROR_FETCHING_DELIVERY_INFO,
}

export const DeliveryInfo: React.FunctionComponent<DeliveryInfoProps> = ({
  productCode,
  hasSidePadding = true,
  isProductFrom3rdParty,
}) => {
  const { t } = useTranslations();
  const [viewState, setViewState] = React.useState<ViewState>(ViewState.INIT);
  const [shouldShowDeliveryOptions, setShouldShowDeliveryOptions] =
    React.useState<boolean>(false);
  const [data, setData] = React.useState<DeliveryOptionsResponse | null>(null);
  const { onOrderItem } = data ?? {};
  const [postalCode, setPostalCode] = React.useState<string | null>(null);
  const [selectedProductCode, setSelectedProductCode] = React.useState<
    string | null
  >(null);
  const { configuration, siteCountry, klarnaLanguage, siteUid } =
    useSharedData().data;
  const [shipmentsApi] = React.useState(
    new ShipmentsApi(new Configuration(configuration.shipmentsApi))
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const inputRef = useRef<HTMLInputElement>(null);

  const handleToggleDeliveryOptions = () => {
    setShouldShowDeliveryOptions((state) => !state);
  };

  const updateDeliveryInfo = React.useCallback(
    async (postalCode: string, productCode: string): Promise<void> => {
      setViewState(ViewState.FETCHING_DELIVERY_INFO);
      try {
        const deliveryResponse = await shipmentsApi.getDeliveryOptions(
          siteUid,
          postalCode,
          productCode
        );
        setData(deliveryResponse.data);
        setViewState(ViewState.DELIVERY_INFO_RECEIVED);
      } catch (_err) {
        setViewState(ViewState.ERROR_FETCHING_DELIVERY_INFO);
      }
    },
    [shipmentsApi, siteUid]
  );

  const onSizeChange = (code: string | null) => {
    const { AWAITING_INPUT, INIT } = ViewState;
    setViewState(viewState === AWAITING_INPUT ? viewState : INIT);
    setSelectedProductCode(code === "" ? null : code);
  };

  const handleNewPostalCode = (postalCode: string): void => {
    try {
      setStorageItem(postalCodeKey, postalCode);
    } catch (err) {
      log.error("Could not save postal code.", err);
    }

    setPostalCode(postalCode);

    if (selectedProductCode === null) {
      setViewState(ViewState.AWAITING_SIZE);
      return;
    }

    void updateDeliveryInfo(postalCode, selectedProductCode);
  };

  const resetPostalCode = (): void => {
    setPostalCode(null);
    localStorage.removeItem(postalCodeKey);
    setViewState(ViewState.AWAITING_INPUT);
  };

  const deliveryMessage = React.useMemo(() => {
    // On Order product delivery message
    if (onOrderItem === true) {
      return t("product.details.delivery.message.on.order");
    }

    // Express product delivery message
    if (
      data?.expressDeliveryAvailable === true &&
      Array.isArray(data.deliveryOptions) &&
      data.deliveryOptions.some((option) => option.cutOffTime !== undefined)
    ) {
      const expressDeliveryOption: CutOffTimeDeliveryOptions | undefined =
        data.deliveryOptions.find(
          (option) =>
            option.cutOffTime !== undefined && option.deliveryTime !== undefined
        ) as CutOffTimeDeliveryOptions | undefined;

      if (expressDeliveryOption === undefined) {
        return "";
      }

      const locale = `${klarnaLanguage}-${siteCountry}`;
      const cutOffTime = new ExpressDeliveryDateSupport(
        new Date(expressDeliveryOption.cutOffTime)
      );
      const deliveryTime = new ExpressDeliveryDateSupport(
        new Date(expressDeliveryOption.deliveryTime)
      );

      if (deliveryTime.isToday()) {
        return stringFormat(
          t("product.details.delivery.message.express.today"),
          cutOffTime.hoursAndMinutes()
        );
      } else if (deliveryTime.isTomorrow()) {
        return stringFormat(
          t("product.details.delivery.message.express.tomorrow"),
          cutOffTime.hoursAndMinutes()
        );
      } else {
        return stringFormat(
          t("product.details.delivery.message.express.after.tomorrow"),
          deliveryTime.dayName(locale),
          cutOffTime.dayName(locale),
          cutOffTime.hoursAndMinutes()
        );
      }
    }

    if (data?.deliveryOptions === undefined || isEmpty(data.deliveryOptions)) {
      return "";
    }

    // RUN product delivery message
    if (
      data.expressDeliveryAvailable === false &&
      data.deliveryOptions.length > 0
    ) {
      const fastestDelivery = Math.min(
        ...data.deliveryOptions.map((o) => Number(o.deliveryEarliest))
      );
      const slowestDelivery = Math.max(
        ...data.deliveryOptions.map((o) => Number(o.deliveryLatest))
      );

      return stringFormat(
        t("product.details.delivery.message.paid"),
        String(fastestDelivery),
        String(slowestDelivery)
      );
    }

    const pickupAtStore = data.deliveryOptions.find(
      ({ price, type }) =>
        price === 0 && type === DeliveryOptionTypeEnum.PICKUP_AT_STORE
    );

    const homeDelivery = data.deliveryOptions.find(
      ({ type }) => type === DeliveryOptionTypeEnum.HOME_DELIVERY
    );

    if (pickupAtStore !== undefined && homeDelivery !== undefined) {
      const hasSameDates =
        homeDelivery.deliveryEarliest === pickupAtStore.deliveryEarliest &&
        homeDelivery.deliveryLatest === pickupAtStore.deliveryLatest;

      const hasFreeDeliveries =
        pickupAtStore.price === 0 && homeDelivery.price === 0;

      // Paid Delivery Scenarios
      let key: TranslationKey = hasSameDates
        ? // Same dates
          "product.details.delivery.message.both.no.date"
        : // Different dates
          "product.details.delivery.message.both.date";

      // Free Delivery Scenarios
      if (hasFreeDeliveries) {
        key = hasSameDates
          ? // Same dates
            "product.details.delivery.message.free.both.no.date"
          : // Different dates
            "product.details.delivery.message.free.both.date";
      }

      return stringFormat(
        t(key),
        String(homeDelivery.price),
        String(homeDelivery.deliveryEarliest),
        String(homeDelivery.deliveryLatest),
        String(pickupAtStore.deliveryEarliest),
        String(pickupAtStore.deliveryLatest)
      );
    }

    if (pickupAtStore !== undefined) {
      return stringFormat(
        t("product.details.delivery.message.free"),
        String(pickupAtStore.deliveryEarliest),
        String(pickupAtStore.deliveryLatest)
      );
    }

    if (homeDelivery !== undefined) {
      const key =
        homeDelivery.price === 0
          ? // Free Delivery to home
            "product.details.delivery.message.home.free"
          : // Paid Delivery to home
            "product.details.delivery.message.paid";

      return stringFormat(
        t(key),
        String(homeDelivery.deliveryEarliest),
        String(homeDelivery.deliveryLatest)
      );
    }

    return "";
  }, [data, onOrderItem, t]);

  useEffect(() => {
    if (productCode === null) {
      return;
    }
    onSizeChange(productCode);
  }, [productCode]);

  useEffect(() => {
    switch (viewState) {
      case ViewState.AWAITING_SIZE:
      case ViewState.INIT: {
        const postalCodeFromLocalStore = localStorage.getItem(postalCodeKey);
        if (postalCodeFromLocalStore === null) {
          return;
        }

        setPostalCode(postalCodeFromLocalStore);

        if (selectedProductCode === null) {
          setViewState(ViewState.AWAITING_SIZE);
          return;
        }

        void updateDeliveryInfo(postalCodeFromLocalStore, selectedProductCode);

        return;
      }
      case ViewState.AWAITING_INPUT: {
        inputRef.current?.focus();

        return;
      }
    }
  }, [selectedProductCode, updateDeliveryInfo, viewState]);

  useEffect(() => {
    const callback = (event: CustomEvent<XxlSizeSelectEventPayload>) => {
      onSizeChange(event.detail.productSizeCodeSelected);
    };

    document.body.addEventListener("xxl-size-product-selected", callback);

    return () => {
      document.body.removeEventListener("xxl-size-product-selected", callback);
    };
  }, []);

  const renderComponent = (state: ViewState) => {
    switch (state) {
      case ViewState.INIT: {
        return (
          <MainMessage
            messageSetDangerously={t(
              "product.details.delivery.message.always.free"
            )}
          />
        );
      }
      case ViewState.AWAITING_INPUT:
        return (
          <PostalCodeEntry
            onSubmitPostalCode={handleNewPostalCode}
            isLoading={false}
            inputRef={inputRef}
          />
        );
      case ViewState.FETCHING_DELIVERY_INFO: {
        return (
          <MainMessage messageSetDangerously={"placeholder"} isLoading={true} />
        );
      }
      case ViewState.AWAITING_SIZE: {
        return (
          <MainMessage
            messageSetDangerously={t(
              "product.details.delivery.message.select.size"
            )}
          />
        );
      }
      case ViewState.DELIVERY_INFO_RECEIVED: {
        return data?.deliveryOptions !== undefined &&
          !isEmpty(data.deliveryOptions) ? (
          <>
            <MainMessage messageSetDangerously={deliveryMessage} />
            <Collapse in={shouldShowDeliveryOptions}>
              {data.deliveryOptions.map((deliveryOption) => (
                <DeliveryOptions
                  key={deliveryOption.name}
                  options={deliveryOption}
                  isRedesigned={true}
                />
              ))}
            </Collapse>
            <DeliveryToggleButton
              onClick={handleToggleDeliveryOptions}
              data-testid="optionsToggleButton"
            >
              {shouldShowDeliveryOptions
                ? t("general.show.less")
                : t("product.details.delivery.show.options")}
              <CaretDown data-open={shouldShowDeliveryOptions} />
            </DeliveryToggleButton>
          </>
        ) : (
          <>
            <ErrorMessage>
              {t("product.details.delivery.not.found")}
            </ErrorMessage>
            <ButtonStyledAsLink onClick={resetPostalCode}>
              {t("product.details.delivery.enter.new.postal.code")}
            </ButtonStyledAsLink>
          </>
        );
      }
      case ViewState.ERROR_FETCHING_DELIVERY_INFO: {
        return (
          <>
            <ErrorMessage>
              {t("product.details.delivery.not.available")}
            </ErrorMessage>
            <ButtonStyledAsLink onClick={resetPostalCode}>
              {t("product.details.delivery.enter.new.postal.code")}
            </ButtonStyledAsLink>
          </>
        );
      }
      default: {
        assertNever(state);
      }
    }
  };

  return (
    <XxlStack gap={mini}>
      {isProductFrom3rdParty && (
        <Wrapper bgcolor={xxlLightAmber}>
          <Header>
            {ReactHtmlParser(t("product.details.delivery.message.on.order"))}
          </Header>
        </Wrapper>
      )}
      <HeaderSection
        onClickEnterNewPostalCode={resetPostalCode}
        postalCode={postalCode}
      />
      {viewState === ViewState.INIT ? null : (
        <Content hasFullPadding={!isMobile && hasSidePadding}>
          <OptionsWrapper>{renderComponent(viewState)}</OptionsWrapper>
        </Content>
      )}
    </XxlStack>
  );
};
