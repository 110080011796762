import {
  ContentApi,
  Configuration as ContentApiConfiguration,
} from "@xxl/content-api";
import {
  CustomersApi,
  Configuration as CustomersApiConfig,
} from "@xxl/customers-api";
import {
  Configuration as DeliveryStreamApiConfig,
  DeliverystreamApi,
} from "@xxl/deliverystream-api";
import {
  AuthApi,
  Configuration as FrontendApiConfiguration,
  PricesApi,
} from "@xxl/frontend-api";
import { PimApi, Configuration as PimApiConfig } from "@xxl/pim-api";
import {
  Configuration as ProductConfiguratorApiConfiguration,
  SearchApi as ProductConfiguratorSearchApi,
} from "@xxl/product-configurator-api";
import { ProductConfiguratorApi } from "@xxl/product-configurator-api-library";
import { ElevateApi, ProductApi } from "@xxl/product-search-api";
import {
  RecommendationsApi,
  Configuration as RecommendationsApiConfig,
} from "@xxl/recommendations-api";
import { SearchApi, Configuration as SearchApiConfig } from "@xxl/search-api";
import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import { nextFrontendApi } from "../../../api/NextFrontendApi";
import type { ApiClients } from "../../../contexts/ApiClients";
import { ApiClientsContext } from "../../../contexts/ApiClients";
import { useSharedData } from "../../../contexts/SharedData";

const ApiClientsProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const {
    contentApi,
    customersApi,
    deliveryStreamApi,
    elevateApi,
    elevateClusterApi,
    frontendApi,
    pimApi,
    recommendationsApi,
    searchApi,
    ...configs
  } = useSharedData().data.configuration;

  const basePath =
    typeof window !== "undefined"
      ? window.location.origin
      : frontendApi.basePath;

  const apis = React.useMemo<ApiClients>(() => {
    return {
      authApi: new AuthApi(
        new FrontendApiConfiguration({ ...frontendApi, basePath })
      ),
      contentApi: new ContentApi(new ContentApiConfiguration(contentApi)),
      customersApi: new CustomersApi(new CustomersApiConfig(customersApi)),
      deliveryStreamApi: new DeliverystreamApi(
        new DeliveryStreamApiConfig(deliveryStreamApi)
      ),
      elevateApi: ElevateApi.getInstance(elevateApi),
      elevateClusterApi: ElevateApi.getInstance(elevateClusterApi),
      nextFrontendApi,
      pimApi: new PimApi(new PimApiConfig(pimApi)),
      pricesApi: new PricesApi(
        new FrontendApiConfiguration({ ...frontendApi, basePath })
      ),
      productApi: new ProductApi({
        baseUrl: elevateApi.baseURL,
      }),
      productConfiguratorApi: ProductConfiguratorApi.getInstance({
        baseURL: configs.productConfiguratorApi.basePath,
      }),
      productConfiguratorSearchApi: new ProductConfiguratorSearchApi(
        new ProductConfiguratorApiConfiguration(configs.productConfiguratorApi)
      ),
      recommendationsApi: new RecommendationsApi(
        new RecommendationsApiConfig(recommendationsApi)
      ),
      searchApi: new SearchApi(new SearchApiConfig(searchApi)),
    };
  }, [
    basePath,
    contentApi,
    configs,
    customersApi,
    deliveryStreamApi,
    elevateApi,
    elevateClusterApi,
    frontendApi,
    pimApi,
    recommendationsApi,
    searchApi,
  ]);

  return (
    <ApiClientsContext.Provider value={apis}>
      {children}
    </ApiClientsContext.Provider>
  );
};

export { ApiClientsProvider };
