import type { ProductTooltipContentData } from "@xxl/content-api";
import type { ProductConfiguration } from "react-app/src/components/Product/ConfigurableProduct/ConfigurationModalWrapper";

const appendTooltipsToConfigurations = ({
  configurations,
  tooltips,
}: {
  configurations: ProductConfiguration[];
  tooltips: ProductTooltipContentData[];
}) =>
  configurations.map((config) => {
    const tooltip = tooltips.find(
      (tip) => config.product?.baseProductCode === tip.productCode
    );
    if (tooltip === undefined) {
      return config;
    }
    return {
      ...config,
      tooltip,
    };
  });

export { appendTooltipsToConfigurations };
