import { FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS } from "@/react-app/constants";
import { typographyToCss } from "@/react-app/styles/helpers";
import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import { Text } from "react-app/src/components/Text";
import { resolutionSizes } from "react-app/src/config";
import { colorsAsCssVariable } from "react-app/src/styles/theme/colors";
import spaces from "react-app/src/styles/theme/spaces";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { StockLevels, type StockLevel } from "../../types";

export const VariantUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const VariantLi = styled.li<{ isDisabled?: boolean }>`
  display: grid;
  grid-template-columns: 4fr 5fr;
  gap: ${xxlTheme.spaces.smallRegular};
  align-items: center;
  justify-content: space-between;
  padding: ${xxlTheme.spaces.large} 0;
  cursor: pointer;
  border-bottom: 1px solid ${xxlTheme.colors.xxlMediumGrey};

  ${({ isDisabled = false }) =>
    isDisabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
      `}

  @media (min-width: ${resolutionSizes.tablet}px) {
    padding: ${xxlTheme.spaces.extraLarge} 0;
  }
`;

const stockStatusMap = {
  [StockLevels.IN_STOCK]: xxlTheme.colors.xxlWebGreen,
  [StockLevels.LOW_STOCK]: xxlTheme.colors.xxlOrange,
  [StockLevels.OUT_OF_STOCK]: xxlTheme.colors.xxlRed,
};

export const StockStatusIndicator = styled.span<{
  stockStatus: StockLevel;
}>`
  display: inline-flex;
  align-items: center;
  min-height: 10px;
  min-width: 10px;
  margin: 0;
  margin-top: ${xxlTheme.spaces.line};
  margin-right: ${xxlTheme.spaces.mini};
  background: ${({ stockStatus }): string => stockStatusMap[stockStatus]};
  border-radius: 50%;
`;

export const NumaricInput = styled.input`
  text-align: center;
  border: 1px solid ${xxlTheme.colors.xxlMediumGrey};
  padding: 0 ${xxlTheme.spaces.mini};
  outline-color: ${xxlTheme.colors.xxlGreen};
  ${xxlTheme.typography.standardRegular};
  ${typographyToCss({
    fontSize: FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS,
  })}
`;

export const SizeHeadingSection = styled.div`
  background-color: ${color.lightGray.hex};
  padding: ${xxlTheme.spaces.smallRegular};
  display: flex;
  gap: ${xxlTheme.spaces.micro};
`;

export const RemindMe = styled.span`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: ${spaces.micro};
  color: ${colorsAsCssVariable.xxlBlack};
  pointer-events: auto;
  cursor: pointer;
`;

export const LineDivider = styled.span`
  &::before {
    content: "|";
  }
  display: block;
  color: ${colorsAsCssVariable.xxlGrey};
  padding-left: ${spaces.micro};
`;

export const StockStatusIndicatorWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
`;

export const StyledText = styled(Text)`
  button {
    background: none;
    border: none;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    text-decoration: underline;
    text-align: left;
    pointer-events: none;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`;
