import styled from "@emotion/styled/macro";
import { Button } from "../../../../styled/Button.styled";
import { MQ, typographyToCss } from "../../../../styles/helpers";
import { FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS } from "react-app/src/constants";

export const PostalCodeForm = styled.form`
  display: flex;
  padding: 0;
`;

export const PostalCodeInput = styled.input`
  flex-grow: 2;
  box-sizing: border-box;
  min-width: 65px;
  height: 42px;
  margin: 0 4px 0px 0;
  padding: 0 8px 0 16px;
  font-family: var(--font-family-regular);
  line-height: 19px;
  border: solid 1px var(--color-xxl-medium-gray);
  ${typographyToCss({
    fontSize: FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS,
  })}

  ${MQ("laptop")} {
    height: 50px;
  }

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-color: var(--color-xxl-green);
    outline-offset: 2px;
  }
`;

export const SubmitButton = styled(Button)`
  width: 42px;
  height: 42px;
  padding: 0;

  ${MQ("laptop")} {
    width: 50px;
    height: 50px;
    padding: 12px 12px;
  }

  &:active {
    opacity: 100%;
  }

  & .magnifier {
    width: 18px;
    height: 18px;
  }
`;
