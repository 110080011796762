import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "./queryKeys";
import { QUANTITY_ONE } from "@/react-app/constants";
import { useAmplifyConfig } from "@/react-hooks/useAmplifyConfig";
import { isGeolocationAccessGranted } from "@/react-utils/xxl-geolocation";
import { getSortedStoresList } from "../StoreStock";
import type { GetStoresWithCollectableProductQuery } from "@/react-app/generated/graphql-code-generator";
import {
  callGraphQL,
  type GraphQLResult,
} from "@/react-app/graphql/graphqlApi";
import { StockLevels, type StockLevel, type Store } from "../types";
import { log } from "@xxl/logging-utils";

const getStoresWithCollectableProductQuery = /* GraphQL */ `
  query GetStoresWithCollectableProduct($input: CollectableStockInput!) {
    collectableStock(input: $input) {
      onlineStock
      stores {
        clickAndCollectEnabled
        collectableStock
        stock
        store {
          id
          location {
            latitude
            longitude
          }
          name
        }
      }
    }
  }
`;

const getStockLevel = (
  stockLevel: number,
  minimumStockeLevelForPackage?: number
): StockLevel => {
  const packageMultiplier =
    minimumStockeLevelForPackage === undefined
      ? 1
      : minimumStockeLevelForPackage;
  if (stockLevel > 3 * packageMultiplier) {
    return StockLevels.IN_STOCK;
  }
  if (stockLevel >= packageMultiplier) {
    return StockLevels.LOW_STOCK;
  }

  return StockLevels.OUT_OF_STOCK;
};

const convert = (
  { data, errors }: GraphQLResult<GetStoresWithCollectableProductQuery>,
  minimumStockLevelForPackage = 1
) => {
  if (Array.isArray(errors)) {
    log.debug(errors);
    return [];
  }

  if (data === null) {
    log.debug("Data property is not defined.");
    return [];
  }

  const stores = data.collectableStock?.stores ?? [];

  return stores.reduce((acc, { collectableStock, store, stock }) => {
    acc.push({
      collectableStockLevel: getStockLevel(
        collectableStock,
        minimumStockLevelForPackage
      ),
      id: store.id,
      location: store.location,
      name: store.name,
      onlineStockLevel: getStockLevel(data.collectableStock?.onlineStock ?? 0),
      stockLevel: getStockLevel(stock, minimumStockLevelForPackage),
    });

    return acc;
  }, [] as Store[]);
};

const getStoresWithCollectableProduct = async (
  ean: string,
  graphQLEndpointUrl: string,
  graphQLApiKey: string,
  forceAnonymous = false,
  minimumStockLevelForPackage = 1
): Promise<Store[]> => {
  const response = await callGraphQL<GetStoresWithCollectableProductQuery>(
    {
      forceAnonymous,
      query: getStoresWithCollectableProductQuery,
      variables: {
        input: {
          ean,
        },
      },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

  const { errors = [] } = response;
  if (errors.length > 0) {
    log.error(errors);
    throw Error(errors.map((err) => err.message).toString());
  }

  return convert(response, minimumStockLevelForPackage);
};

/**
 * @param id - The style code of the product, e.g. `7392136971167`
 * @returns The stores with the product in stock, sorted by distance to the user's location
 */
export const useGeoSortedStoresWithCollectableProductQuery = (
  id: string | null
) => {
  const { aws_appsync_apiKey, aws_appsync_graphqlEndpoint } =
    useAmplifyConfig();
  const fetchStores = async () => {
    const data = await getStoresWithCollectableProduct(
      id as string,
      aws_appsync_graphqlEndpoint,
      aws_appsync_apiKey,
      false,
      QUANTITY_ONE
    );

    if (!isGeolocationAccessGranted()) {
      return Promise.resolve(data);
    }
    return await getSortedStoresList(data);
  };

  return useQuery({
    enabled: id !== null,
    queryKey: [QUERY_KEYS.CLICK_AND_COLLECT_STORES_SORTED, { id }],
    queryFn: fetchStores,
  });
};
