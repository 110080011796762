import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { typographyToCss } from "../../../styles/helpers";
import text from "../RatingAndReview.typography";
import { xxlTheme } from "../../../styles/xxl-theme";

const { colors, spaces } = xxlTheme;

export const HelpfulnessVoteContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const ThumbDescription = styled.p`
  ${typographyToCss(text.reviews.helpfulnessVote)};
  width: 100%;
  display: block;
  margin: 0 0 ${spaces.mini};
`;

interface ThumbButtonProps {
  isActive: boolean;
  isFilled: boolean;
}

export const ThumbButton = styled.button<ThumbButtonProps>(
  ({ isActive, isFilled }) => css`
    ${typographyToCss(text.reviews.helpfulnessVote)};
    color: ${isActive || isFilled ? colors.xxlWebBlack : colors.xxlGrey};
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px 2px;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      path {
        fill: ${isActive || isFilled ? colors.xxlWebBlack : colors.xxlGrey};
      }
    }

    &:first-of-type {
      margin-right: ${spaces.regular};
    }
  `
);
