import type { ApiOptions } from "./types";

/**
 * Use this to create an API function for server side usage only, it will throw an error if used client side.
 * The function will create and return an instance if one does not already exists.
 *
 * @param createApiFunc function to instantiate the api
 * @param api API instance or variable to be assigned a new API instance
 * @returns
 */
export const serverSideApiInstanceFactory = <ApiType>(
  createApiFunc: () => ApiType,
  api: ApiType | undefined
): ApiType => {
  if (typeof window !== "undefined") {
    throw Error("Called API is not for client side usage.");
  }

  if (api === undefined) {
    api = createApiFunc();
  }

  return api;
};

/**
 * Use this when creating an api function for use on both client and server.
 * It creates a pattern where the client side call must provide an existing instance on the options object and the server side call can uses a predefined function.
 *
 * @param options object used to differentiate between server side and client side usage
 * @param serverSideApiFunction function to be used for server side API calls
 * @returns function to call specific endpoint
 */
export const clientAndServerApiFunctionFactory = <ApiFunction>(
  options: ApiOptions<ApiFunction>,
  serverSideApiFunction: () => ApiFunction
): ApiFunction => {
  const { usage } = options;

  if (usage === "client-side") {
    return options.apiFunction;
  }

  return serverSideApiFunction();
};
