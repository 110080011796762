import { Stack } from "@mui/material";
import { CaretRight } from "@xxl/icons";
import isEmpty from "lodash/isEmpty";
import { useCountdownTimer } from "react-app/src/components/CountdownTimer/hooks/useCountdownTimer";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { Message } from "../AddToCartWarning/Message";
import { CampaignCountdown } from "../ProductImageSlider/CampaignSplash/CampaignCountdown/CampaignCountdown";
import {
  Description,
  IconWrapper,
  Link,
  MessageContent,
  MessageHeading,
  Text,
  iconId,
} from "./CampaignBanner.styled";
const { colors, spaces } = xxlTheme;

type CampaignCountdownProps = {
  endDate: Date;
  fontColor: string;
  convertDaysToHours?: boolean;
  startDate?: Date;
};

type Props = {
  backgroundColor: string;
  fontColor: string;
  id: string;
  timer: CampaignCountdownProps | null;
  title: string;
  text: string;
  slug: string;
};

export const CampaignBanner = ({
  id,
  timer,
  backgroundColor,
  fontColor,
  text,
  title,
  slug,
}: Props) => {
  const { t } = useTranslations();
  const {
    requestMapping: { campaignHubPage },
  } = useSharedData().data;

  const { endDate, convertDaysToHours, startDate } = timer ?? {};
  const hasCountdown = timer !== null && endDate !== undefined;
  const { hasExpired } = useCountdownTimer({
    endDate,
    isOnlyHours: convertDaysToHours,
    startDate,
  });

  const textId = "xxl-offer-ends-text";
  const ctaButtonBgColor = colors.xxlGreen;
  const fallbackBgColor = colors.xxlLightGreen;
  const campaignBgColor =
    backgroundColor.length !== 7 || backgroundColor === ctaButtonBgColor
      ? fallbackBgColor
      : backgroundColor;

  return !isEmpty(title.trim()) && !isEmpty(text.trim()) ? (
    <Link
      href={`${campaignHubPage}/${slug}`}
      aria-describedby={hasExpired ? title : textId}
    >
      <Message backgroundColor={campaignBgColor}>
        <MessageContent>
          <Stack gap={spaces.miniMicro}>
            <MessageHeading>
              {!isEmpty(title) && (
                <Text id={textId} data-testid={id} fontColor={fontColor}>
                  {hasExpired ? title : `${t("general.offer.ends.in")}:`}
                </Text>
              )}
              {hasCountdown && (
                <CampaignCountdown
                  endDate={endDate}
                  convertDaysToHours={convertDaysToHours}
                  startDate={startDate}
                  fontColor={fontColor}
                />
              )}
            </MessageHeading>
            {!isEmpty(text) && (
              <Description fontColor={fontColor}>{text}</Description>
            )}
          </Stack>
          <IconWrapper>
            <CaretRight fontSize={14} color={fontColor} id={iconId} />
          </IconWrapper>
        </MessageContent>
      </Message>
    </Link>
  ) : null;
};
