import type { SetRequired } from "type-fest";
import type { DeliveryOption } from "@xxl/shipments-api";

export type CutOffTimeDeliveryOptions = SetRequired<
  DeliveryOption,
  "cutOffTime" | "deliveryTime"
>;

export class ExpressDeliveryDateSupport {
  time: Date;

  constructor(time: Date) {
    this.time = time;
  }

  isToday(): boolean {
    const today = new Date();
    return this.isSameDayAs(today);
  }

  isTomorrow(): boolean {
    const tomorrow = new Date(new Date().getDate() + 1);
    return this.isSameDayAs(tomorrow);
  }

  dayName(locale: string): string {
    return this.time.toLocaleDateString(locale, { weekday: "long" });
  }

  hours(): string {
    return this.hoursOrMinutes2Digit(this.time.getHours());
  }

  minutes(): string {
    return this.hoursOrMinutes2Digit(this.time.getMinutes());
  }

  hoursAndMinutes() {
    return this.hours() + ":" + this.minutes();
  }

  protected isSameDayAs(otherTime: Date): boolean {
    return (
      this.time.getDate() === otherTime.getDate() &&
      this.time.getMonth() === otherTime.getMonth() &&
      this.time.getFullYear() === otherTime.getFullYear()
    );
  }

  protected hoursOrMinutes2Digit(hoursOrMinutes: number): string {
    return hoursOrMinutes <= 9 ? `0${hoursOrMinutes}` : String(hoursOrMinutes);
  }
}
