import type { CampaignInfo } from "@/components/Pdp/types";
import { DEFAULT_PICKUP_READY_HOURS } from "@/react-app/constants";
import { Apis } from "@/utils/api-helper";
import { hasValue } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import { type ProductData } from "@xxl/pim-api";
import { color } from "@xxl/theme";
import { transformSizeIdToStyle } from "react-app/src/components/Product/product-helper";
import { getIsPurchaseAllowed } from "react-app/src/hooks/useProductData/useProductData.helper";
import type { SimpleProductData } from "src/components/ProductDetailsPage/types";
import { urlParameterIsString } from "../../../src/utils/page-helper";
import { convertProductLink } from "../../components/ProductDetailsPage/ProductDetailsPage.helper";
import type { SimpleCampaignData } from "../../utils/pdp-page-helper";
import { createBadges, createColorTheme } from "../../utils/pdp-page-helper";

export const getProductCodeFromParameters = ({
  articleNumber,
  sizeCode,
  styleCode,
}: {
  articleNumber: string;
  styleCode?: string | string[];
  sizeCode?: string | string[];
}): string => {
  if (articleNumber.includes("_")) {
    return articleNumber;
  }
  if (hasValue(sizeCode)) {
    if (!urlParameterIsString(sizeCode)) {
      throw TypeError(`Expected sizeCode to be string, got ${typeof sizeCode}`);
    }
    return transformSizeIdToStyle(sizeCode);
  }
  if (hasValue(styleCode)) {
    if (!urlParameterIsString(styleCode)) {
      throw TypeError(
        `Expected styleCode to be string, got ${typeof styleCode}`
      );
    }
    return styleCode;
  }
  return `${articleNumber}_B`;
};

export const getProductData = async (
  siteUid: string,
  productId: string
): Promise<ProductData | null> => {
  try {
    const { data } = await Apis.getInstance().pimApi.getProducts(
      siteUid,
      productId,
      undefined,
      undefined,
      undefined,
      {
        timeout: 10000,
      }
    );
    if (!Array.isArray(data) || data.length < 1) {
      return null;
    }
    return data[0];
  } catch (error) {
    log.error("[getProductData] failed to fetch products", error);
    return null;
  }
};

export const createSimpleProductData = (
  productData: ProductData,
  breadcrumbCategoryCodes: string[],
  toggleClickAndCollect: boolean,
  isFitstationEnabled: boolean,
  styleCode: string
): SimpleProductData | undefined => {
  const {
    averageRating,
    badges,
    baseProductCode = "",
    baseProductName,
    clickAndCollectEnabled = false,
    description,
    googleCategory = "",
    multiChannelAvailability = [],
    name,
    onlyAvailableInStoreNoClickAndCollect = false,
    photoshoot = null,
    primaryImage = "",
    productLink,
    quantityToggleEnabled,
    styleOptions = [],
    url,
    visibilityStatus,
    clickAndCollectPickupReadyHours,
  } = productData;

  const fixedBadges = badges ?? [];

  if (baseProductCode === "" || googleCategory === "") {
    log.error(
      `Missing product info. baseProductCode: ${baseProductCode}, googleCategory: ${googleCategory}`
    );
  }

  if (baseProductName === undefined || name === undefined) {
    log.error(
      `Missing product data.  typeof baseProductName: ${typeof baseProductName}, typeof primaryImage: ${typeof primaryImage}, typeof name: ${typeof name}.`
    );
    return undefined;
  }

  const [categoryCode] =
    breadcrumbCategoryCodes.length > 0
      ? breadcrumbCategoryCodes.reverse()
      : [undefined];
  if (categoryCode === undefined) {
    log.error("Category code is undefined.");
    return undefined;
  }
  const isPurchaseAllowed = getIsPurchaseAllowed(productData);
  const styleColorCode = styleOptions[0]?.colourData?.code ?? "";

  return {
    averageRating: averageRating ?? null,
    badges: createBadges(
      fixedBadges,
      multiChannelAvailability,
      productData.salesMethod
    ),
    baseCode: baseProductCode,
    baseName: baseProductName,
    categoryCode,
    description: description ?? null,
    googleCategory,
    isFitstationEnabled,
    isClickAndCollectEnabled:
      toggleClickAndCollect &&
      clickAndCollectEnabled &&
      isPurchaseAllowed &&
      !(visibilityStatus?.onlyAvailableOnline ?? true),
    isPurchaseAllowed,
    multiChannelAvailability,
    name,
    onlyAvailableInStoreNoClickAndCollect,
    photoshoot,
    primaryImageUrl: primaryImage,
    quantityToggleEnabled: quantityToggleEnabled ?? false,
    styleCode,
    styleColorCode,
    url,
    productLink: convertProductLink(productLink),
    isDiscontinued: visibilityStatus?.discontinued ?? false,
    clickAndCollectPickupReadyHours:
      clickAndCollectPickupReadyHours ?? DEFAULT_PICKUP_READY_HOURS,
  };
};

export const createCampaignInfo = ({
  campaignData,
  campaignRibbonContent,
  id,
}: {
  campaignData: SimpleCampaignData | null;
  campaignRibbonContent?: {
    backgroundColor?: string;
    frontColor?: string;
    text: string;
  };
  id?: string;
}): CampaignInfo | null => {
  if (campaignData === null || id === undefined) {
    return null;
  }

  const { description, slug, timer, title } = campaignData;
  const {
    backgroundColor = color.green.hex,
    frontColor = color.black.hex,
    text = "",
  } = campaignRibbonContent ?? {};

  return {
    colorTheme: createColorTheme({
      backgroundColor,
      foregroundColor: frontColor,
    }),
    description,
    id,
    name: text,
    slug,
    timer,
    title,
  };
};
