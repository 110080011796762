export const DISCOUNT_CATEGORY_SUFFIX = "promo";

export const getIsDiscountCategoryCode = (
  categoryCode: string | undefined
): boolean => {
  if (categoryCode === undefined) {
    return false;
  }
  return categoryCode.endsWith(DISCOUNT_CATEGORY_SUFFIX);
};
