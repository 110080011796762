import styled from "@emotion/styled/macro";
import { Button } from "../../../../../styled";
import { xxlTheme } from "../../../../../styles/xxl-theme";
import { FOOTER_SHADOW } from "../../../../Filter/FilterDrawer/constants";

const { spaces, colors } = xxlTheme;

export const ConfigurationFormFieldset = styled("fieldset")`
  padding: 0;
  border: none;
`;

export const ConfigurationInputContainer = styled("div")`
  margin-bottom: ${spaces.large};
`;

export const ConfigurationFormDivider = styled("hr")`
  border: 0;
  border-bottom: 1px solid ${colors.xxlLightGrey};
  margin-bottom: ${spaces.large};
`;

export const ConfigurationFormFooter = styled.footer`
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  gap: ${spaces.mini};
  box-sizing: border-box;
  width: 100%;
  padding: ${spaces.smallRegular};
  background-color: var(--color-xxl-white);
  box-shadow: ${FOOTER_SHADOW};
`;

export const SubmitButton = styled(Button)`
  display: block;
  width: inherit;
  min-width: 50%;
  margin: 28px auto;
`;

export const TooltipInfoContainer = styled("article")`
  font-family: var(--font-family-regular);
  margin: ${spaces.large} 0;

  h2,
  h3 {
    margin: 0;
    font-weight: 500;
  }

  p:first-of-type {
    margin-top: 0;
  }

  figure,
  video {
    margin: 0 0 ${spaces.large};
    width: 100%;
    img {
      width: 100%;
    }
  }
`;

export const ErrorMessageWrapper = styled.div`
  margin: ${spaces.large} ${spaces.regular};

  p {
    margin-top: 0;
  }

  button {
    width: 100%;
    padding: ${spaces.smallRegular};
    margin: 0;
  }
`;
