import styled from "@emotion/styled/macro";
import type { MegaMenuLevelOneLink } from "@xxl/frontend-api";
import { useMemo } from "react";
import { getUrl } from "../MegaMenu/Desktop/mega-menu-helper";

const SeoLinksStyled = styled.div`
  display: none;
`;

const SEOCategoryLinks = ({
  levelOneLinks,
  isMobile = false,
}: {
  levelOneLinks: MegaMenuLevelOneLink[];
  isMobile?: boolean;
}) => {
  const linksArray = useMemo(
    () =>
      levelOneLinks.flatMap(
        ({ links, displayName, category: levelOneCategory }) => [
          isMobile && (
            <a
              key={levelOneCategory?.code}
              href={getUrl({ link: levelOneCategory?.url })}
            >
              {displayName}
            </a>
          ),
          ...(links ?? []).flatMap(
            ({ category, displayName: _displayName }) => [
              <a
                key={`${category?.url ?? ""}_${levelOneCategory?.code ?? ""}`}
                href={getUrl({ code: category?.code, link: category?.url })}
              >
                {_displayName}
              </a>,
              ...(category?.subCategories ?? []).map(({ url, name }, index) => (
                <a
                  key={`${url ?? ""}_${category?.url ?? ""}_${
                    levelOneCategory?.code ?? ""
                  }_${index}`}
                  href={getUrl({ link: url })}
                >
                  {name}
                </a>
              )),
            ]
          ),
        ]
      ),
    [isMobile, levelOneLinks]
  );

  return <SeoLinksStyled id="seo-category-links">{linksArray}</SeoLinksStyled>;
};

export { SEOCategoryLinks };
