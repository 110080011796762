import styled from "@emotion/styled/macro";
import { resolutionSizes } from "../../../config";

type CircleProps = {
  isHovering: boolean;
  isSelected: boolean;
};

type SizeFitnessContainerProps = {
  padding?: string;
};

export const SizeFitnessContainer = styled.div<SizeFitnessContainerProps>`
  * {
    user-select: none;
    -webkit-touch-callout: none;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 388px;
  padding: ${(props): string => props.padding ?? "30px 0"};
  @media (max-width: ${resolutionSizes.mobile}px) {
    justify-content: space-between;
    max-width: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

type ItemContainerProps = {
  cursor: string;
};
export const ItemContainer = styled.div<ItemContainerProps>`
  display: inline-block;
  width: 50%;
  cursor: ${(props): string => props.cursor};
  text-align: center;
  ${[
    { selector: "&:first-of-type", side: "left" },
    { selector: "&:last-of-type", side: "right" },
  ].map(
    ({ selector, side }) => `
    ${selector} {
      text-align: ${side};
      width: 25%;
    }
  `
  )}
  z-index: 1;
`;

export const Line = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  left: 10px;
  height: 2px;
  background-color: var(--color-xxl-light-gray);
  pointer-events: none;
`;

export const Circle = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  background-color: white;
  border: 2px solid;
  background: var(--color-xxl-light-gray);
  border-color: ${(props: CircleProps): string =>
    props.isHovering ? "#000" : "var(--color-xxl-light-gray)"};
  border-radius: 50%;
  transition: all 200ms ease;
  @media (max-width: ${resolutionSizes.mobile}px) {
    border-color: var(--color-xxl-light-gray);
  }
  > * {
    opacity: ${(props: CircleProps): number => (props.isSelected ? 1 : 0)};
    transition: all 200ms ease-out;
    @media (max-width: ${resolutionSizes.mobile}px) {
      opacity: ${(props: CircleProps): number =>
        props.isHovering || props.isSelected ? 1 : 0};
    }
  }
`;

export const CirclesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export type TextProps = {
  isOpaque?: boolean;
};

export const Text = styled.div<TextProps>`
  width: 33%;
  font-size: 0.75rem;
  text-align: center;
  margin: 0 7px;
  opacity: ${(props): string => (props.isOpaque ? "1" : "0")};
  ${[
    { selector: "&:first-of-type", side: "left" },
    { selector: "&:last-of-type", side: "right" },
  ].map(
    ({ selector, side }) => `
    ${selector} {
      text-align: ${side};
      margin: 0;
    }
  `
  )}
  transition: opacity 0.3s;
`;

Text.defaultProps = {
  isOpaque: true,
};

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 8px;
`;

type SideLabelProps = {
  isVisible: boolean;
};

export const SideLabel = styled.div`
  margin-left: 8px;
  font-size: 0.8rem;
  opacity: ${(props: SideLabelProps): number => (props.isVisible ? 1 : 0)};
  transition: all 200ms;
`;
