import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { useBreakpoint } from "@/react-hooks/useBreakpoint/useBreakpoint";
import { Skeleton } from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import { useStockStatusTranslation } from "../../hooks/useStockStatusTranslation";
import type { StockLevel, Store } from "../../types";
import {
  StockStatusIndicator,
  StockStatusIndicatorWrapper,
  StyledText,
} from "./styles";

const ID = "size-select-drawer-availability-online";

type Props = {
  storesWithStockCount: number;
  highestStoreStockStatus: StockLevel;
  preferredStores: Store[];
  isLoading: boolean;
};
export const StoreStockStatusComponent = ({
  preferredStores,
  highestStoreStockStatus,
  storesWithStockCount,
  isLoading,
}: Props) => {
  const bp = useBreakpoint();
  const isMobile = bp === "mobile";

  const { storeFn } = useStockStatusTranslation();
  const ssText = storeFn({
    storesWithStockCount,
    storeName: preferredStores[0]?.name ?? "",
    isMultiPreferredStores: preferredStores.length >= 2,
    highestStoreStockStatus,
  });

  return (
    <XxlStack direction={"row"} alignItems={"center"}>
      {isLoading && <Skeleton />}
      <StockStatusIndicatorWrapper>
        <StockStatusIndicator stockStatus={highestStoreStockStatus} />
        <StyledText typography={isMobile ? "small" : "base"} data-testid={ID}>
          {ReactHtmlParser(ssText)}
        </StyledText>
      </StockStatusIndicatorWrapper>
    </XxlStack>
  );
};
