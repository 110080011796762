import { useState } from "react";
import { hasNoValue, hasValue } from "@xxl/common-utils";
import {
  ProductImageWithFetchPrio,
  NextProductImage,
} from "./ProductImage.styled";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { useSharedData } from "../../../contexts/SharedData/SharedDataContext";
import { getFileSpinUrl } from "../../../utils/getFilespinUrl";

type Props = {
  alt?: string;
  columnAmount?: number;
  fetchPriority?: "auto" | "high" | "low";
  focusImageUrl?: string;
  imageUrl?: string;
  isFocused?: boolean;
  loading?: "eager" | "lazy";
  size?: { w: number; h: number };
  addImageParameters?: boolean;
  trim?: number;
};

export const ProductImage = ({
  alt = "",
  focusImageUrl,
  imageUrl,
  isFocused = false,
  size = { w: 292, h: 292 },
  fetchPriority = "auto",
  loading,
  trim,
}: Props) => {
  const { isReactApp } = useSharedData();
  const isTabletOrDesktop = useXxlMediaQuery("TabletAndDesktopMediaQuery");
  const [isVisible, setIsVisible] = useState(false);

  if (hasNoValue(imageUrl)) {
    return null;
  }

  const imgUrl = isReactApp
    ? getFileSpinUrl({
        baseUrl: imageUrl,
        width: size.w,
        height: size.h,
        trim: 10,
      })
    : imageUrl;

  const focusedImgUrl =
    isReactApp && focusImageUrl !== undefined
      ? getFileSpinUrl({
          baseUrl: focusImageUrl,
          width: size.w,
          height: size.h,
          trim: 10,
        })
      : focusImageUrl;

  const shouldShowHoverImage =
    isTabletOrDesktop && isFocused && hasValue(focusedImgUrl);

  if (isReactApp) {
    return (
      <figure data-private={true}>
        <ProductImageWithFetchPrio
          key={shouldShowHoverImage ? focusedImgUrl : imgUrl}
          src={shouldShowHoverImage ? focusedImgUrl : imgUrl}
          alt={alt}
          height={size.h}
          width={size.w}
          isVisible={isVisible}
          onLoad={() => setIsVisible(true)}
          fetchPriority={fetchPriority}
          loading={loading}
        />
        {isTabletOrDesktop && hasValue(focusedImgUrl) && (
          <ProductImageWithFetchPrio
            alt={alt}
            height={size.h}
            width={size.w}
            isVisible={false}
            src={focusedImgUrl}
            fetchPriority={fetchPriority}
            loading="lazy"
            style={{ position: "absolute" }}
          />
        )}
      </figure>
    );
  }

  return (
    <figure data-private={true}>
      <NextProductImage
        key={shouldShowHoverImage ? focusedImgUrl : imgUrl}
        src={shouldShowHoverImage ? focusedImgUrl : imgUrl}
        alt={alt}
        height={size.h}
        width={size.w}
        isVisible={isVisible}
        onLoad={() => setIsVisible(true)}
        priority={fetchPriority === "high"}
        trim={trim}
      />
      {isTabletOrDesktop && hasValue(focusedImgUrl) && (
        <NextProductImage
          alt={alt}
          height={size.h}
          width={size.w}
          isVisible={false}
          src={focusedImgUrl}
          style={{ position: "absolute" }}
          trim={trim}
        />
      )}
    </figure>
  );
};
