import { FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS } from "@/react-app/constants";
import { MQ, typographyToCss } from "@/react-app/styles/helpers";
import spaces from "@/react-app/styles/theme/spaces";
import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaces.smallRegular};
  min-height: 100dvh;
  overflow-y: auto;

  ${MQ("tablet")} {
    overflow-y: auto;
    padding: ${spaces.large};
    padding-top: ${spaces.smallRegular};
    height: 790px;
    width: 960px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spaces.large};
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StoreInput = styled.input`
  margin-top: ${spaces.smallRegular};
  padding: ${spaces.regular};
  border: 1px solid ${color.mediumGray.hex};
  ${typographyToCss({
    fontSize: FONT_SIZE_TO_PREVENT_IOS_ZOOM_ON_FOCUS,
  })}
  :focus-visible {
    outline-color: ${color.green.hex};
    outline-offset: ${spaces.micro};
  }
`;

export const StoreSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadingIconWrapper = styled.div`
  margin-top: ${spaces.regular};
  padding: ${spaces.regular};
  ${typographyToCss({
    fontSize: 35,
  })}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  padding: ${spaces.smallRegular};
  background: ${color.white.hex};
  box-shadow: 0 -5px 5px -5px ${color.mediumGray.hex};
  position: sticky;
  bottom: 0;
  ${MQ("tablet")} {
    display: flex;
    justify-content: end;
    padding: ${spaces.smallRegular} ${spaces.large};
  }
`;
