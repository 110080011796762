import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { IMAGE_SIZE } from "../../constants";
import { ThumbnailImageContainer } from "../ProductImage.styles";
import { createThumbnailSrc } from "../ProductImageSlider.helper";
import type { ThumbnailSliderProps } from "./ThumbnailSlider";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { NextImage } from "../../../common/NextImage/NextImage";

type ThumbnailsImagesProps = ThumbnailSliderProps & {
  productName: string;
};

export const ThumbnailsImages = ({
  productImages,
  currentSlide,
  productName,
  instanceRef,
}: ThumbnailsImagesProps) => {
  const { t } = useTranslations();

  return (
    <XxlStack gap={xxlTheme.spaces.micro}>
      {productImages.map((productImage, idx) => (
        <ThumbnailImageContainer
          key={`${idx} - ${productImage}`}
          selected={currentSlide === idx}
          onClick={() => instanceRef.current?.moveToIdx(idx)}
        >
          <NextImage
            priority={true}
            src={createThumbnailSrc(productImage)}
            width={IMAGE_SIZE.THUMBNAIL}
            height={IMAGE_SIZE.THUMBNAIL}
            alt={`${productName} - ${t(
              "product.details.alt.image.thumbnail"
            )} ${idx + 1}`}
          />
        </ThumbnailImageContainer>
      ))}
    </XxlStack>
  );
};
