import { forwardRef, type PropsWithChildren } from "react";
import { ProductImages } from "../ProductImages";
import { MultipleProductImages } from "./MultipleProductImages";

type ProductImageSliderContentProps = PropsWithChildren<{
  handleClickOpen: (img: string) => void;
  hasMoreThanOneProductImage: boolean;
  isLastItem: boolean;
  productImages: string[];
  productName: string;
  shouldShowUpsaleLink: boolean;
}>;

export const ProductImageSliderContent = forwardRef<
  HTMLDivElement,
  ProductImageSliderContentProps
>(
  (
    {
      children,
      handleClickOpen,
      hasMoreThanOneProductImage,
      isLastItem,
      productImages,
      productName,
      shouldShowUpsaleLink,
    },
    sliderRef
  ) =>
    hasMoreThanOneProductImage ? (
      <MultipleProductImages
        handleClickOpen={handleClickOpen}
        isLastItem={isLastItem}
        productImages={productImages}
        productName={productName}
        sliderRef={sliderRef}
        shouldShowUpsaleLink={shouldShowUpsaleLink}
      >
        {children}
      </MultipleProductImages>
    ) : (
      <ProductImages
        handleClickOpen={handleClickOpen}
        isLastItem={isLastItem}
        productImages={productImages}
        productName={productName}
        ref={sliderRef}
        shouldShowUpsaleLink={shouldShowUpsaleLink}
      >
        {children}
      </ProductImages>
    )
);

ProductImageSliderContent.displayName = "ProductImageSliderContent";
