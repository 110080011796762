import { useState } from "react";
import parse from "html-react-parser";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useSizeGuidesQuery } from "../queries/useSizeGuidesQuery";
import { Drawer } from "react-app/src/components/Drawer";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { Button, SectionHeading } from "./styles";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Text } from "react-app/src/components/Text";
import type { Photoshoot } from "@xxl/pim-api";
import stringFormat from "string-format";
import { Container } from "./SizeGuide.styled";
import { isNullOrUndefined, hasValue } from "@xxl/common-utils";
import { StatusCodes } from "http-status-codes";

type Props = {
  brandCode?: string;
  categoryCode?: string[];
  photoshoot?: Photoshoot | null;
};
export const SizeGuide = ({
  brandCode = "",
  categoryCode = [""],
  photoshoot,
}: Props) => {
  const { t } = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const { modelHeight, modelSize } = photoshoot ?? {};
  const hasModelInfo = hasValue(modelHeight) && hasValue(modelSize);

  const { data: sgData } = useSizeGuidesQuery(brandCode, categoryCode);

  if (sgData?.status === StatusCodes.NO_CONTENT) return null;
  if (isNullOrUndefined(sgData?.data) && isNullOrUndefined(photoshoot))
    return null;

  return (
    <>
      <Container>
        <XxlStack direction="row" justifyContent="flex-end">
          <Button
            onClick={toggleIsOpen}
            data-testid="size-guide-button"
            style={{ padding: 0 }}
          >
            <Text fontFamily="medium">{t("product.details.size.guide")}</Text>
          </Button>
        </XxlStack>
        {hasModelInfo && (
          <Text>
            {stringFormat(
              t("product.details.model.size"),
              modelHeight.toString(),
              modelSize.toUpperCase()
            )}
          </Text>
        )}
      </Container>
      <Drawer
        open={isOpen}
        heading={t("product.details.size.guide")}
        onClose={toggleIsOpen}
        customPadding={xxlTheme.spaces.smallRegular}
      >
        <SectionHeading>{parse(sgData?.data.content ?? "")}</SectionHeading>
      </Drawer>
    </>
  );
};
