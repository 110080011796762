import { Time } from "@xxl/icons";
import { useCountdownTimer } from "react-app/src/components/CountdownTimer/hooks/useCountdownTimer";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import {
  Colon,
  Seconds,
  SecondsName,
  TimeItem,
  Wrapper,
} from "./CampaignCountdown.styled";

type CampaignCountdownProps = {
  endDate: Date;
  fontColor: string;
  convertDaysToHours?: boolean;
  startDate?: Date;
};

const TEST_ID = "xxl-countdown-timer";

const CampaignCountdown = ({
  convertDaysToHours = false,
  endDate,
  startDate,
  fontColor,
}: CampaignCountdownProps) => {
  const { t } = useTranslations();
  const { days, hasExpired, hours, min, sec } = useCountdownTimer({
    endDate,
    isOnlyHours: convertDaysToHours,
    startDate,
  });

  if (hasExpired) {
    return null;
  }

  return (
    <Wrapper fontColor={fontColor} data-testid={TEST_ID}>
      <Time fontSize={12} style={{ marginRight: "3px" }} />
      {days > 0 && (
        <>
          <TimeItem>{days}</TimeItem>
          <TimeItem>{t("countdowntimer.days").charAt(0)}</TimeItem>
          <Colon>:</Colon>
        </>
      )}
      <TimeItem>{hours}</TimeItem>
      <TimeItem>{t("countdowntimer.hours").charAt(0)}</TimeItem>
      <Colon>:</Colon>
      <TimeItem>{min}</TimeItem>
      <TimeItem>{t("countdowntimer.min").charAt(0)}</TimeItem>
      <Colon>:</Colon>
      <Seconds>{sec.toString().padStart(2, "0")}</Seconds>
      <SecondsName>{t("countdowntimer.sec").charAt(0)}</SecondsName>
    </Wrapper>
  );
};

export { CampaignCountdown };
