import {
  COLLECT_SUCCESS_CLEAR,
  useCartContext,
} from "@/react-components/Cart/CartState";
import { useState, useEffect } from "react";

const BUTTON_SUCCESS_TIMEOUT = 2000;

export const useShouldShowAtcSuccess = (): boolean => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const {
    state: { isAddToCartSuccess },
    dispatch,
  } = useCartContext();
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (isAddToCartSuccess === true) {
      setIsSuccess(true); // Set isSuccess to true when isAddToCartSuccess is true

      timeoutId = setTimeout(() => {
        setIsSuccess(false);
        dispatch({
          type: COLLECT_SUCCESS_CLEAR,
        });
      }, BUTTON_SUCCESS_TIMEOUT);
    }

    // Cleanup function to clear the timeout
    return () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }
    };
  }, [dispatch, isAddToCartSuccess]);

  return isSuccess;
};
export const useShouldShowCncSuccess = (): boolean => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const {
    state: { isCollectSuccess },
    dispatch,
  } = useCartContext();
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (isCollectSuccess === true) {
      setIsSuccess(true); // Set isSuccess to true when isAddToCartSuccess is true

      timeoutId = setTimeout(() => {
        setIsSuccess(false);
        dispatch({
          type: COLLECT_SUCCESS_CLEAR,
        });
      }, BUTTON_SUCCESS_TIMEOUT);
    }

    // Cleanup function to clear the timeout
    return () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }
    };
  }, [dispatch, isCollectSuccess]);

  return isSuccess;
};
