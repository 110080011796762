import type { PropsWithChildren, Ref } from "react";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { ProductImages } from "../ProductImages";

type MultipleProductImagesProps = PropsWithChildren<{
  handleClickOpen: (img: string) => void;
  isLastItem: boolean;
  productImages: string[];
  productName: string;
  sliderRef: Ref<HTMLDivElement> | undefined;
  shouldShowUpsaleLink: boolean;
}>;

export const MultipleProductImages = ({
  children,
  handleClickOpen,
  isLastItem,
  productImages,
  productName,
  sliderRef,
  shouldShowUpsaleLink,
}: MultipleProductImagesProps) => {
  return (
    <XxlStack
      direction="row"
      gap={xxlTheme.spaces.mini}
      alignItems="start"
      boxSizing="border-box"
      overflow="hidden"
    >
      <XxlStack overflow="hidden" width="100%">
        <ProductImages
          handleClickOpen={handleClickOpen}
          isLastItem={isLastItem}
          productImages={productImages}
          productName={productName}
          ref={sliderRef}
          shouldShowUpsaleLink={shouldShowUpsaleLink}
        >
          {children}
        </ProductImages>
      </XxlStack>
    </XxlStack>
  );
};
