import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import React from "react";
import stringFormat from "string-format";
import { FormButton } from "./FormButton";
import { Message } from "../Message";
import { Container, StickyFormContent } from "./styles";
import { getRestockDate } from "./utils";

const translationKeys = {
  archived: "product.details.unavailable.product.waiting.for.restock",
  discontinued: "product.details.unavailable.product",
} as const;

export const ProductFormNotSellable = ({
  btnRef,
  isRefInView,
  productName,
  reason,
}: {
  btnRef: React.MutableRefObject<null>;
  isAddToCartBtnInView: boolean;
  isRefInView: boolean;
  productName: string;
  reason: "archived" | "discontinued";
}) => {
  const { t } = useTranslations();
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");

  const text = stringFormat(
    t(translationKeys[reason]),
    productName,
    getRestockDate()
  );

  return (
    <>
      <Container ref={btnRef}>
        <FormButton />
        <Message text={text} />
      </Container>
      <StickyFormContent
        shouldShowStickyContent={!isRefInView && !isLaptopSize}
      >
        <FormButton />
        <Message text={text} />
      </StickyFormContent>
    </>
  );
};
