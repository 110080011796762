import { hasValue, isNotUndefined } from "@xxl/common-utils";
import { LogLevelEnum, getLogger, log } from "@xxl/logging-utils";
import type { ClassificationValueTypeEnum } from "@xxl/pim-api";
import { getPriceDisplay } from "@xxl/prices-utils";
import {
  Channel,
  PriceId,
  ProductType,
  type ChannelType,
  type ProductApi,
  type VariantData,
} from "@xxl/product-search-api";
import { useStateValue } from "cotton-box-react";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
/* eslint-disable-next-line import/no-extraneous-dependencies */ // TODO fix dependency
import { createFrontendPriceDisplays } from "next-js-app/src/components/ProductDetailsPage/ProductDetailsPage.helper";
import { useEffect, useState } from "react";
import { PRODUCT_API_CLIENT_IDS } from "../../constants";
import { useApiClients } from "../../contexts/ApiClients";
import { useSessionSource } from "../../contexts/Session";
import { useSharedData } from "../../contexts/SharedData";
import {
  useTranslations,
  type Translate,
} from "../../contexts/Translations/TranslationsContext";
import type { EcomSiteUidLegacy } from "../../global";
import { xxlTheme } from "../../styles/xxl-theme";
import { getPriceDisplayFromElevateData } from "../../utils/ElevateApi/mappings/elevate-PPDs";
import { getSizeOptionsElevateData } from "../../utils/ElevateApi/mappings/elevate-sizeOptions";
import { getFrontendPriceDisplayData } from "../../utils/PriceDisplay/price-display";
import { dispatchEvent } from "../../utils/xxl-event";
import {
  convertSiteUidToHost,
  legacySiteUidToSiteUid,
} from "../../utils/xxl-shared-data";
import {
  getIsPurchaseAllowed,
  isProductFrom3rdParty,
} from "./useProductData.helper";
import type { FrontendProductData } from "./useProductData.types";

const noLogs = getLogger(undefined, LogLevelEnum.NONE);

const {
  colors: { xxlWebBlack, xxlWhite },
} = xxlTheme;

const fetchProductData = async ({
  onError,
  productCode,
  shouldEvaluateProductConfiguratorPrice,
  siteUid,
  productApi,
  isTeamsales,
  isLoggedIn,
  t,
}: {
  onError: () => void;
  productCode: string;
  shouldEvaluateProductConfiguratorPrice: boolean;
  siteUid: EcomSiteUidLegacy;
  productApi: ProductApi;
  isTeamsales: boolean;
  isLoggedIn: boolean;
  t: Translate;
}): Promise<FrontendProductData | null> => {
  try {
    const site = convertSiteUidToHost(legacySiteUidToSiteUid(siteUid));
    const optionalData = await productApi.getBaseProduct({
      site,
      key: productCode,
      clientId: PRODUCT_API_CLIENT_IDS.useProductMiniPdp,
      priceId: isTeamsales
        ? PriceId.TEAMSALES
        : isLoggedIn
          ? PriceId.MEMBER
          : PriceId.ANONYMOUS,
    });
    const selectedStyle = optionalData?.products.find(
      ({ isSelected }) => isSelected === true
    );
    if (selectedStyle === undefined) {
      throw Error("No selected style in data from elevate api");
    }
    if (selectedStyle.isGraveyard === true) {
      throw Error("Selected style not visible");
    }
    const baseProductCode = hasValue(optionalData?.articleNumber)
      ? `${optionalData.articleNumber}_B`
      : "";
    const { variants = [], ...data } = selectedStyle;
    const { salesMethodCode } = data;
    const priceDisplays = [getPriceDisplayFromElevateData(data, isLoggedIn)];

    const fixBreakRows = (input: string): string =>
      input.replaceAll("\\n", "<br/>");
    const getMultiChannelAvailability = (sizes: VariantData[]): ChannelType[] =>
      [
        sizes.some(({ availability }) =>
          availability
            .filter(({ channel }) => channel === Channel.ONLINE)
            .some(({ stockNumber }) => stockNumber > 0)
        )
          ? Channel.ONLINE
          : undefined,
        sizes.some(({ availability }) =>
          availability
            .filter(
              ({ channel, key }) =>
                channel === Channel.STORE && key !== Channel.CENTRAL_WAREHOUSE
            )
            .some(({ stockNumber }) => stockNumber > 0)
        )
          ? Channel.STORE
          : undefined,
      ].filter(isNotUndefined);

    const priceDisplay = getPriceDisplay({
      isLoggedIn,
      priceDisplays: createFrontendPriceDisplays(priceDisplays),
      logger: noLogs,
    });
    if (isEmpty(priceDisplay)) {
      throw Error("Empty price display in useProductData hook");
    }
    const isRewardsProduct = priceDisplays.some(
      (item) => item.invertedPrice !== undefined
    );
    const cheapestPriceForRewardsProduct = isRewardsProduct
      ? priceDisplays.find((item) => item.userGroupId !== "registeredcustomers")
          ?.salesPriceFormatted
      : undefined;
    const frontendPriceDisplay = getFrontendPriceDisplayData({
      isLoggedIn,
      priceDisplay,
      shouldEvaluateProductConfiguratorPrice,
      siteUid,
      t,
    });
    const [categoryCode] = data.categoryBreadcrumbs
      .map(({ code }) => code)
      .reverse()
      .filter(Boolean);
    if (isEmpty(categoryCode)) {
      throw Error("Empty category code in useProductData hook");
    }
    if (isEmpty(baseProductCode)) {
      throw Error("Empty baseProductCode in useProductData");
    }
    const [productId] = baseProductCode.split("_");
    const isPurchaseAllowed = getIsPurchaseAllowed({
      baseProductSoldIndividually: true,
      productType:
        data.type === ProductType.UNKNOWN ? ProductType.NORMAL : data.type,
      serviceProduct: data.type === ProductType.SERVICE,
      visibilityStatus: {},
    });
    const productData: FrontendProductData = {
      averageRating: data.rating,
      badges: [],
      baseProductCode,
      baseProductName: data.title, // not available in elevate
      baseProductSoldIndividually: undefined,
      basisPrice: undefined,
      bigProduct: undefined,
      brand: data.brand,
      breadcrumbs: data.categoryBreadcrumbs,
      bundlePriceAddition: undefined,
      bundles: data.bundledBy,
      categoryCode,
      cheapestPriceForRewardsProduct,
      classifications: [
        ...data.classifications.mandatory,
        ...data.classifications.optional,
      ].map(({ id, name, unit, value, valueType, priority }) => ({
        id,
        name,
        optional: data.classifications.optional.some((c) => c.id === id),
        priority,
        ...(unit !== undefined ? { unit } : {}),
        value,
        valueName: undefined,
        valueType: valueType as ClassificationValueTypeEnum,
      })),
      clickAndCollectEnabled: data.isExcludedFromClickAndCollect !== true,
      clickAndCollectPickupReadyHours: undefined,
      code: data.code,
      colourData: undefined,
      configurations: undefined,
      description: hasValue(data.description)
        ? fixBreakRows(data.description)
        : undefined,
      ean: undefined,
      erpCategories: undefined,
      fitTags: undefined,
      frontendPriceDisplay,
      googleCategory: undefined,
      googleTopLevelCategory: undefined,
      instantShoppingAvailable: undefined,
      isProductFrom3rdParty: isProductFrom3rdParty(salesMethodCode),
      isPurchaseAllowed,
      isRewardsProduct,
      level1Category: data.categoryBreadcrumbs[0],
      multiChannelAvailability: getMultiChannelAvailability(variants),
      multipackEan: undefined,
      name: data.title,
      numberOfReviews: undefined,
      onlyAvailableInStoreNoClickAndCollect:
        data.isOnlyAvailableInStoreNoClickAndCollect,
      packageSize: undefined,
      photoshoot: undefined,
      pk: undefined, // not used
      preselected: undefined,
      priceDisplay,
      priceDisplays: undefined,
      primaryImage: data.imageInfo.images[0]?.sources[0].url,
      productConfigurator: undefined,
      productFamily: {
        children: data.productFamily,
        parent: baseProductCode,
      },
      productId,
      productImages: data.imageInfo.images
        .map(({ sources }) => sources[0])
        .filter(isNotUndefined)
        .map(({ url }) => url),
      productLink: undefined, // Not used
      productType: data.type === ProductType.UNKNOWN ? undefined : data.type,
      quantityToggleEnabled: undefined,
      returnable: undefined,
      salesMethod: data.salesMethodCode,
      selectedColour: undefined,
      selectedSize: undefined,
      selectedStyle: undefined,
      selectedSwatch: undefined,
      serviceProduct: data.type === ProductType.SERVICE,
      sizeOptions: getSizeOptionsElevateData({ variants }),
      stockStatus: data.stockStatus,
      styleOptions: optionalData?.products.map(
        ({
          code,
          isSelected,
          title,
          url,
          imageInfo,
          stockStatus,
          baseColor,
        }) => ({
          code,
          selected: isSelected,
          name: title,
          url: url,
          primaryImage: imageInfo.images[0]?.sources?.[0].url,
          stockStatus,
          colourData: {
            name: "", // needs to have a value, but not in elevate
            code: baseColor,
            swatch: undefined, // not in elevate
          },
          pk: undefined, // hopefully unused
        })
      ),
      summary: hasValue(data.summary) ? fixBreakRows(data.summary) : undefined,
      superCategories: undefined,
      taxRate: undefined,
      units: undefined,
      url: data.url,
      usp: data.usps.map(({ key, name, value, valueType }) => ({
        key,
        name,
        values: [{ value, valueType }],
      })),
      visibilityStatus: undefined,
    };

    return productData;
  } catch (error) {
    log.error(error);
    dispatchEvent("xxl-show-message", {
      msgs: [
        {
          background: xxlWebBlack,
          textColor: xxlWhite,
          key: "general.error",
        },
      ],
    });
    onError();
    return null;
  }
};

const useProductData = ({
  onError = noop,
  productCode,
  shouldEvaluateProductConfiguratorPrice,
}: {
  onError?: () => void;
  productCode: string;
  shouldEvaluateProductConfiguratorPrice: boolean;
}) => {
  const { t } = useTranslations();
  const [productData, setProductData] = useState<FrontendProductData | null>(
    null
  );
  const { siteUid, isTeamsales } = useSharedData().data;
  const { pimApi, productApi } = useApiClients();
  const isLoggedIn = useStateValue(useSessionSource);

  useEffect(() => {
    setProductData(null);
    void (async () => {
      setProductData(
        await fetchProductData({
          onError,
          productCode,
          shouldEvaluateProductConfiguratorPrice,
          siteUid,
          productApi,
          isLoggedIn,
          isTeamsales,
          t,
        })
      );
    })();
  }, [
    isLoggedIn,
    onError,
    pimApi,
    productApi,
    productCode,
    shouldEvaluateProductConfiguratorPrice,
    siteUid,
    isTeamsales,
    t,
  ]);

  return productData;
};

export { useProductData };
