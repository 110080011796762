import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const { colors, spaces, typography } = xxlTheme;

export const PriceWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

type PriceProps = {
  isDiscountPrice: boolean;
};
export const CurrentPrice = styled.div<PriceProps>(
  ({ isDiscountPrice }) => css`
    grid-area: current-price;

    ${typographyToCss(typography.colossalBold)};
    color: ${isDiscountPrice ? colors.xxlRed : colors.xxlBlack};
  `
);

export const PriceDescription = styled.div`
  ${typographyToCss(typography.smallRegular)};
  color: ${colors.xxlWebBlack};
`;

export const OriginalPrice = styled.div`
  ${typographyToCss(typography.mediumRegular)};
  display: flex;
  gap: ${spaces.micro};
  color: ${colors.xxlBlack};
`;

export const OtherPrice = styled.span<{
  hasLowerProductConfiguratorPriceThanSalesPrice?: boolean;
  hasUndeductedRewardDiscount?: boolean;
  isInvertedPrice: boolean;
}>`
  ${({
    hasLowerProductConfiguratorPriceThanSalesPrice = false,
    hasUndeductedRewardDiscount = false,
    isInvertedPrice,
  }) => css`
    text-decoration: ${isInvertedPrice ||
    hasLowerProductConfiguratorPriceThanSalesPrice
      ? "none"
      : "line-through"};
    color: ${hasLowerProductConfiguratorPriceThanSalesPrice
      ? "inherit"
      : hasUndeductedRewardDiscount
        ? colors.xxlRed
        : "inherit"};
  `}
`;
