import styled from "@emotion/styled/macro";
import type * as CSSType from "csstype";
import type { PropsWithChildren } from "react";
import spaces from "react-app/src/styles/theme/spaces";
import { ContentContainer } from "../ContentContainer/ContentContainer.styled";

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.miniMicro};
  flex-wrap: wrap;
`;

type MessageProps = {
  backgroundColor: CSSType.Property.BackgroundColor;
};
const Message = ({
  backgroundColor,
  children,
}: PropsWithChildren<MessageProps>) => {
  return (
    <ContentContainer backgroundColor={backgroundColor}>
      <FlexContainer>{children}</FlexContainer>
    </ContentContainer>
  );
};

export { Message };
