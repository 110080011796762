/**
 * @description Get at least 3 slider images, regardless of the number of images
 * @param {string[]} imageUrls - Array of image URLs
 * @returns {string[]} - Array of image URLs containing at least 3 elements
 */
export const getSliderImages = (imageUrls: string[]) => {
  switch (imageUrls.length) {
    case 1:
      return [imageUrls[0], imageUrls[0], imageUrls[0]];
    case 2:
      return [imageUrls[0], imageUrls[1], imageUrls[0]];
    default:
      return imageUrls;
  }
};
