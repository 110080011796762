import { QUANTITY_ONE } from "@/react-app/constants";
import { dispatchAddToCartEvent } from "@/react-utils/xxl-event";
import type { ProductData, VariantData } from "@xxl/product-search-api";
import { getSalesUnit } from "./helpers";

export type AddToCartArgs = {
  quantity?: number;
  storeId?: string;
};

export type TAtc = ({ quantity, storeId }: AddToCartArgs) => void;

type T = {
  p: ProductData;
  v: VariantData;
} & AddToCartArgs;

export const addToCart = ({ p, v, storeId, quantity = QUANTITY_ONE }: T) => {
  dispatchAddToCartEvent({
    brandName: p.brand?.name,
    additionalSales: p.additionalSales,
    categoryCode: p.categoryBreadcrumbs.at(-1)?.code ?? "",
    eanCode: v.code,
    imageUrl: p.imageInfo.images[0]?.sources[0]?.url ?? "",
    productName: p.title,
    quantity,
    salesPrice: p.price.selling.range.min.value,
    salesPriceFormatted: p.price.selling.range.min.formatted,
    sizeCode: v.sizeCode,
    sizeName: v.label,
    storeId,
    styleCode: p.code,
    ticket: v.ticket,
    unit: getSalesUnit(p),
  });
};
