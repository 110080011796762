import * as React from "react";
import { ConfigurationForm } from "./ConfigurationForm";
import type { ProductConfiguration } from "../ConfigurationModalWrapper";
import type { Configuration } from "./ConfigurationForm/types";

type ConfigurationModalProps = {
  configuration: ProductConfiguration[] | null;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onReload: () => void;
  onSubmit: (configuration: Configuration) => Promise<void>;
};

export const ConfigurationModal: React.FunctionComponent<
  ConfigurationModalProps
> = ({ configuration, isLoading, isOpen, onClose, onReload, onSubmit }) => {
  const [isDialogBoxOpen, setIsDialogBoxOpen] = React.useState(isOpen);
  const handleClose = (): void => {
    onClose();
  };

  React.useEffect(() => {
    setIsDialogBoxOpen(isOpen && !isLoading);
  }, [isOpen, isLoading]);

  return (
    !isLoading && (
      <ConfigurationForm
        configuration={configuration ?? []}
        onSubmit={onSubmit}
        onReload={onReload}
        isModalOpen={isDialogBoxOpen}
        onModalClose={handleClose}
      />
    )
  );
};
