import { DEFAULT_PICKUP_READY_HOURS } from "@/react-app/constants";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";
import { Text } from "@/react-components/Text";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
} from "@mui/material";
import type { Store as StoreDetails } from "@xxl/content-api";
import { color } from "@xxl/theme";
import React from "react";
import { StoreStockSelectedDetailsSection } from "../StoreStock";
import { StoreStockStatusIndicator } from "../StoreStock/StoreStockStyles";
import type { Store } from "../types";
import {
  useTranslationCCStockStatus,
  useTranslationStoreStockStatus,
} from "./helpers";
import { ACCORDION_STYLES } from "./styles";

const AccordionMobile = ({
  store,
  handleStoreSelect,
  selectedStoreId,
  selectedStoreDetails,
}: {
  store: Store;
  handleStoreSelect: (id: string) => void;
  selectedStoreId?: string;
  selectedStoreDetails?: StoreDetails;
}) => {
  const { t } = useTranslations();
  const tStoreStock = useTranslationStoreStockStatus(store.stockLevel);
  const tCollectableStockLevel = useTranslationCCStockStatus(
    store.collectableStockLevel
  );
  const isSelected = selectedStoreId === store.id;
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    handleStoreSelect(evt.target.value);

  return (
    <Accordion
      sx={ACCORDION_STYLES}
      key={store.id}
      expanded={selectedStoreId === store.id}
    >
      <AccordionSummary sx={{ margin: 0, paddingX: 0 }}>
        <XxlStack>
          <XxlStack
            alignItems={"center"}
            direction="row"
            spacing={xxlTheme.spaces.small}
          >
            <FormControlLabel
              value={store.id}
              control={
                <Radio
                  checked={isSelected}
                  onChange={handleChange}
                  value={store.id}
                  name="click-and-collect-store-selection"
                  inputProps={{ "aria-label": store.id }}
                  sx={{
                    color: color.gray.hex,
                    "&.Mui-checked": {
                      color: color.webGray.hex,
                    },
                  }}
                />
              }
              label={
                <Text
                  typography={isSelected ? "baseBold" : "base"}
                  style={{
                    margin: 0,
                  }}
                >
                  {store.name}
                </Text>
              }
            />
          </XxlStack>
          <XxlStack gap={"4px"} px={"30px"}>
            <XxlStack direction={"row"} alignItems={"center"}>
              <StoreStockStatusIndicator
                stockStatus={store.collectableStockLevel}
              />
              <Text typography="small">{tCollectableStockLevel}</Text>
            </XxlStack>
            <XxlStack direction={"row"} alignItems={"center"}>
              <StoreStockStatusIndicator stockStatus={store.stockLevel} />
              <Text typography="small">{tStoreStock}</Text>
            </XxlStack>
          </XxlStack>
        </XxlStack>
      </AccordionSummary>
      <AccordionDetails>
        <Accordion
          disableGutters={true}
          sx={{
            ...ACCORDION_STYLES,
            borderTop: "none",
            borderBottom: "none",
            ".MuiAccordionDetails-root": {
              padding: 0,
            },
          }}
        >
          <AccordionDetails>
            <Text>
              {t({
                key: "product.details.clickcollect.ready.for.pickup",
                messageArguments: [DEFAULT_PICKUP_READY_HOURS.toString()],
              })}
            </Text>
            <AccordionSummary
              expandIcon={<Icon name="CaretDown" />}
              sx={{
                minHeight: "fit-content",
                padding: ` ${xxlTheme.spaces.smallRegular} 0 0 0 `,
                ".MuiAccordionSummary-content": {
                  margin: 0,
                  gap: xxlTheme.spaces.mini,
                  padding: 0,
                  minHeight: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  "&.expanded": {
                    minHeight: "fit-content",
                  },
                },
              }}
            >
              <Text typography="baseMedium" padding="0px">
                {t("general.address.and.opening.hours")}
              </Text>
            </AccordionSummary>
          </AccordionDetails>
          <AccordionDetails>
            <XxlStack gap={"12px"}>
              <StoreStockSelectedDetailsSection
                store={selectedStoreDetails}
                isMobile={true}
              />
            </XxlStack>
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
};

export const StoreListMobile = ({
  preferredStores = [],
  remainingStores,
  handleStoreSelect,
  selectedStoreId,
  selectedStoreDetails,
}: {
  preferredStores: Store[];
  remainingStores: Store[];
  handleStoreSelect: (id: string) => void;
  selectedStoreId?: string;
  selectedStoreDetails?: StoreDetails;
}) => {
  return (
    <>
      {preferredStores.map((store) => (
        <AccordionMobile
          key={store.id}
          store={store}
          handleStoreSelect={handleStoreSelect}
          selectedStoreId={selectedStoreId}
          selectedStoreDetails={selectedStoreDetails}
        />
      ))}
      {remainingStores.map((store) => (
        <AccordionMobile
          key={store.id}
          store={store}
          handleStoreSelect={handleStoreSelect}
          selectedStoreId={selectedStoreId}
          selectedStoreDetails={selectedStoreDetails}
        />
      ))}
    </>
  );
};
