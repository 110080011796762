import { motion } from "framer-motion";
import { Icon } from "react-app/src/components/Icon/Icon";
import { Text } from "react-app/src/components/Text";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import type { TranslationKey } from "react-app/src/translations";
import type { SizeFitness } from "src/generated/graphql-code-generator";
import { useSizeRecommendationQuery } from "../../queries/useSizeRecommendationQuery";
import { SizeHeadingSection } from "./styles";

const TRANSITION = { duration: 0.2, ease: [0.65, 0.05, 0.36, 1] };

const sizeFitnessTranslationKeyMap: Record<SizeFitness, TranslationKey> = {
  SMALLER_FIT: "product.details.customer.size.rating.small",
  BIGGER_FIT: "product.details.customer.size.rating.big",
  TRUE_TO_SIZE: "product.details.customer.size.rating.true",
};

type TSizeRating = {
  percentage: number;
  sizeFitness: SizeFitness;
}[];
const computeSizeRecommendation = (sr: TSizeRating) => {
  return sr.reduce((acc, curr) => {
    if (curr.percentage > acc.percentage) {
      return curr;
    }
    return acc;
  });
};

export const VariantRecommendationComponent = ({
  articleNumber,
}: {
  articleNumber: string;
}) => {
  const { t } = useTranslations();
  const { data: srData } = useSizeRecommendationQuery(articleNumber);
  if (srData === undefined || srData.length === 0) return null;
  const recommendation = computeSizeRecommendation(srData);

  const translationString =
    sizeFitnessTranslationKeyMap[recommendation.sizeFitness];

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      transition={TRANSITION}
    >
      <SizeHeadingSection>
        <Icon name="Star" size={12} />
        <Text as="span" fontFamily="medium" size="small">
          {t({
            key: translationString,
            messageArguments: [recommendation.percentage.toString()],
          })}
        </Text>
      </SizeHeadingSection>
    </motion.div>
  );
};
