import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import React from "react";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { PriceData } from "../../../utils/ProductData/product-card-data-helper";
import { OtherPrice } from "../../PriceDisplays/PriceDisplays.styled";
import { PriceType } from "@xxl/product-search-api";
import {
  CurrentPrice,
  OriginalPrice,
  PriceWrapper,
  SalesPriceDisclaimer,
} from "../Product.styled";

type PriceWithLabelsV2Props = {
  selectedColumnsNumber: number;
  priceData: {
    selling: PriceData;
    alternate?: PriceData;
    cheapest?: PriceData;
    type?: PriceType;
  };
  showDisclaimer: boolean;
  isMultiPackProduct?: boolean;
  productCode?: string;
  hasRewardPrices?: boolean;
};

const getLabel = (priceData: PriceData, t: Translate) => {
  const { labelType } = priceData;

  if (labelType === "TranslatedLabel") {
    return priceData.label;
  }

  if (labelType === "TranslationKey") {
    return t(priceData.translationKey);
  }

  return undefined;
};

const PriceWithLabelsV2: React.FunctionComponent<PriceWithLabelsV2Props> = ({
  selectedColumnsNumber,
  priceData,
  showDisclaimer,
  hasRewardPrices,
}) => {
  const { t } = useTranslations();
  const { alternate, selling, cheapest, type } = priceData;
  const hasUndeductedRewardDiscount =
    isNotNullOrUndefined(alternate) && alternate.value < selling.value;
  const hasDiscountedSellingPrice =
    isNotNullOrUndefined(alternate) && alternate.value > selling.value;
  const sellingLabelAsDisclaimer = getLabel(selling, t);
  const alternateLabel = isNotNullOrUndefined(alternate)
    ? getLabel(alternate, t)
    : undefined;
  const cheapestLatestPrice = cheapest?.valueFormatted;
  const isCampaignProduct = type === PriceType.CAMPAIGN;
  const shouldShowRewardsCheapestPrice =
    (type === PriceType.REWARD || isCampaignProduct) &&
    cheapestLatestPrice !== undefined &&
    cheapestLatestPrice.length > 0;

  const rewardsPriceLabel = shouldShowRewardsCheapestPrice
    ? `${t("product.price.history.latest.price.label.short")} ${cheapestLatestPrice}`
    : "";

  return (
    <PriceWrapper data-testid="new-product-card-price-display">
      <CurrentPrice
        data-testid="current-price"
        columnAmount={selectedColumnsNumber}
        isDiscountPrice={hasDiscountedSellingPrice}
      >
        {selling.valueFormatted}
        {/* TODO: Add widget same as v1 https://xxlsports.atlassian.net/browse/XD-16347 */}
      </CurrentPrice>

      <OriginalPrice columnAmount={selectedColumnsNumber}>
        {isNotNullOrUndefined(alternate) ? (
          <>
            {isNotNullOrUndefined(alternateLabel) && (
              <span>{alternateLabel}</span>
            )}
            <OtherPrice
              hasLowerProductConfiguratorPriceThanSalesPrice={
                false // TODO: https://xxlsports.atlassian.net/browse/XD-16321
              }
              hasUndeductedRewardDiscount={hasUndeductedRewardDiscount}
              isInvertedPrice={hasUndeductedRewardDiscount}
            >
              {alternate.valueFormatted}
            </OtherPrice>
          </>
        ) : null}
      </OriginalPrice>
      {showDisclaimer ? (
        <SalesPriceDisclaimer title={sellingLabelAsDisclaimer}>
          {sellingLabelAsDisclaimer}
        </SalesPriceDisclaimer>
      ) : null}
      {hasValue(hasRewardPrices) && (
        <SalesPriceDisclaimer title={rewardsPriceLabel}>
          {rewardsPriceLabel}
        </SalesPriceDisclaimer>
      )}
    </PriceWrapper>
  );
};

export { PriceWithLabelsV2 };
