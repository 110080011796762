import { TRANSITION } from "@/react-app/constants";
import styled from "@emotion/styled/macro";
import { motion } from "framer-motion";
import { XxlButton } from "react-app/src/components/Common/XxlButton/XxlButton";
import { typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const SalesPrice = styled.span`
  ${typographyToCss({
    fontSize: 20,
    fontFamily: "var(--font-family-bold)",
  })}
  margin-left: ${xxlTheme.spaces.micro};
`;

type Props = {
  disabled: boolean;
  onClick: () => void;
  salesPrice?: string;
  loading?: boolean;
  isStickyBtnVisible: boolean;
  children: React.ReactNode;
  icon?: "Check" | "Plus";
};

export const StickyAtcBtn = ({
  children,
  salesPrice,
  isStickyBtnVisible,
  disabled,
  loading,
  onClick,
  icon = "Check",
}: Props) => {
  return (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: isStickyBtnVisible ? 0 : "100%" }}
      transition={TRANSITION}
      style={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: xxlTheme.zIndex.navigation,
      }}
    >
      <XxlButton
        icon={icon}
        disabled={disabled}
        loading={loading}
        onClick={onClick}
        size="large"
        data-testid="sticky-add-to-cart-btn"
      >
        {children}
        <SalesPrice>{salesPrice}</SalesPrice>
      </XxlButton>
    </motion.div>
  );
};
