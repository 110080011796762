import type { UseInViewOptions } from "framer-motion";

export const GRID_COLUMNS = { one: 1, two: 2, four: 4, six: 6 } as const;
export const FILTER_DROPDOWN_HEIGHT = 278;
export const MOBILE_FILTER_DROPDOWN_OFFSET = 132;
export const MOBILE_FILTER_CONTAINER_MIN_HEIGHT = 40;
export const FILTER_DROPDOWN_WIDTH = 200;
export const FILTER_SLIDER_DROPDOWN_WIDTH = 254;
export const STICKY_FILTER_NEGATIVE_MARGIN_MOBILE = 100;
export const RANGE_FILTER_MAX = "max" as const;
export const RANGE_FILTER_MIN = "min" as const;

/* Don't react when disappearing in the bottom */
export const stickyFiltersMarginSettings: UseInViewOptions = {
  margin: `-${FILTER_DROPDOWN_HEIGHT}px 0px 9999px 0px`,
};
