import styled from "@emotion/styled/macro";
import { XxlStack } from "../../../Common/XxlStack";
import { typographyToCss } from "../../../../styles/helpers";
import { xxlTheme } from "../../../../styles/xxl-theme";
import text from "../DeliveryInfo.typography";

const { colors } = xxlTheme;

export const Header = styled.span`
  ${typographyToCss(text.header)}
  display: flex;
  flex-grow: 1;
  align-items: start;
  text-align: left;
  margin: 0;
  color: ${colors.xxlBlack};
  & svg {
    padding-right: 7px;
  }
`;

export const Wrapper = styled(XxlStack)`
  display: flex;
  padding: 12px 16px;
`;

export const PostalCodeWrapper = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: repeat(3, auto);
  gap: 4px;
  p {
    ${typographyToCss(text.header)}
    margin: 0;
  }

  button {
    ${typographyToCss(text.changeButton)};
  }
`;
