import { log } from "@xxl/logging-utils";
import {
  type ProductData,
  type StockStatusType,
} from "@xxl/product-search-api";
import noop from "lodash/noop";
import type { MouseEvent } from "react";
import { Slider } from "react-app/src/components/Common/Slider/Slider";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { useWindowDimensions } from "react-app/src/hooks/useWindowDimensions";
import { getPrimaryImg } from "../helpers";
import { StyleItem } from "./StyleItem";
import { createStylesConfig } from "./helpers";
import type { Style } from "./types";

const SLIDER_SPACING = 0;

const sortStyles = (s: Style[]) =>
  s.toSorted((a, b) =>
    a.baseColor.toLowerCase().localeCompare(b.baseColor.toLowerCase())
  );

const getIsInStock = (ss: StockStatusType) =>
  ss === "INSTOCK" || ss === "LOWSTOCK";
const sortSizeSelectorData = (styleArr: Style[]) =>
  styleArr.toSorted((a, b) =>
    a.isInStock === true && b.isSelected === false ? -1 : 1
  );

type Props = {
  onClick?: ({
    code,
    event,
  }: {
    code: string;
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>;
  }) => void;
  products: ProductData[];
};

const getStyleSelectorData = (pd: ProductData): Style => {
  const primaryImg = getPrimaryImg(pd.imageInfo.images);
  const imageData = {
    imageUrl: primaryImg?.sources[0].url,
    alt: primaryImg?.alt ?? "",
  };

  if (imageData.imageUrl === undefined) {
    log.warn(
      "Style image URL is undefined. Please ensure that primaryImg has a valid source."
    );
  }

  return {
    code: pd.code,
    imageData,
    baseColor: pd.baseColor ?? "",
    isInStock: getIsInStock(pd.stockStatus),
    url: pd.url,
    isSelected: pd.isSelected,
  };
};

export const ProductStyleSelector = ({ onClick = noop, products }: Props) => {
  const wd = useWindowDimensions();
  if (wd.x === undefined) {
    return null;
  }
  const [shouldShowSlider, perView] = createStylesConfig(wd.x, products.length);

  if (products.length <= 1) return null;

  const sizeSelectorData = products.map(getStyleSelectorData);
  const sortedStyles = sortSizeSelectorData(sizeSelectorData);
  const inStockStyles = [...sortedStyles].filter((s) => s.isInStock);
  const outOfStockStyles = [...sortedStyles].filter((s) => !s.isInStock);

  if (!shouldShowSlider) {
    return (
      <XxlStack direction="row" flexWrap={"wrap"} gap={"10px"} rowGap={"10px"}>
        {[...sortStyles(inStockStyles), ...sortStyles(outOfStockStyles)].map(
          (style) => (
            <StyleItem key={style.code} style={style} onClick={onClick} />
          )
        )}
      </XxlStack>
    );
  }

  return (
    <Slider
      items={sortedStyles.map((style) => (
        <StyleItem key={style.code} style={style} onClick={onClick} />
      ))}
      slidesConfig={{
        perView,
        spacing: SLIDER_SPACING,
      }}
    />
  );
};
