import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import type { TranslationKey } from "@/react-app/translations";
import * as React from "react";
import { StockLevels, type StockLevel } from "../types";
import {
  StoreStockStatus,
  StoreStockStatusIndicator,
} from "./StoreStockStyles";

type StoreStockLevelProps = {
  stockLevel: StockLevel;
};

const getStockStatusTranslation = (stockLevel: StockLevel): TranslationKey => {
  switch (stockLevel) {
    case StockLevels.LOW_STOCK:
      return "product.details.storestock.low";
    case StockLevels.OUT_OF_STOCK:
      return "product.details.storestock.out";
    default:
      return "product.details.storestock.in.stock";
  }
};

const StoreStockLevelElement: React.FunctionComponent<StoreStockLevelProps> = ({
  stockLevel,
}) => {
  const { t } = useTranslations();

  return (
    <StoreStockStatus>
      <StoreStockStatusIndicator stockStatus={stockLevel} />
      {t(getStockStatusTranslation(stockLevel))}
    </StoreStockStatus>
  );
};
export { StoreStockLevelElement };
