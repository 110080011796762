import { isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  PreviewData,
} from "next";
import type { ParsedUrlQuery } from "querystring";
import type { ToggleCookie } from "react-app/src/components/XXLDynamicToggle/types";
import { PageType, pagePaths } from "react-app/src/constants";
import type { FeatureToggles } from "react-app/src/global";
import type { NextJsTranslations } from "react-app/src/utils/xxl-translate";
import type { EnvironmentData, XXLAppData } from "src/global";
import {
  getEnvironmentData,
  getTranslationsContent,
} from "../../utils/common-content";
import { isBot } from "../custom-headers";
import { getEnvironmentVariables, getSiteUid } from "../environment-variables";
import type { GetServerSidePropsCallback } from "../layout/with-layout-page-props";
import { getXXLToggleCookieFromRequest, isLocalhost } from "../page-helper";
import { setCookies } from "../search-page-helper/search-helper";
import {
  getCachedPageData,
  setCachedPageData,
} from "../server-side-cache/server-side-cache";
import { getSsmConfigParameters } from "../ssm-parameters";
import { NotFoundResponse } from "../constants";

export interface SharedPageData {
  environmentData: Omit<EnvironmentData, "pageType">;
  ecoOnlineApiKey: string;
  ecoOnlineUrl: string;
  giosg: {
    giosgEnabled: boolean;
    giosgId: string;
  };
  headerCode: string;
  logRocketApiId: string;
  serverGtmScriptUrl: string;
  translations: NextJsTranslations;
}

interface PageData extends SharedPageData {
  environmentData: EnvironmentData;
  isBot: boolean;
}

export const getPageType = (pathName?: string): PageType => {
  const {
    category,
    product,
    checkout,
    orderConfirmationPage,
    brandIndex,
    storeFinder,
    guides,
    campaignHub,
    error,
  } = pagePaths;
  if (isNotNullOrUndefined(pathName)) {
    if (pathName.includes(category)) {
      return PageType.CATEGORY;
    }
    if (pathName.includes(product)) {
      return PageType.PRODUCT;
    }
    if (pathName.includes(checkout)) {
      return PageType.CHECKOUT;
    }
    if (pathName.includes(orderConfirmationPage)) {
      return PageType.ORDER_CONFIRMATION;
    }
    if (pathName.includes(brandIndex)) {
      return PageType.BRAND_INDEX;
    }
    if (pathName.includes(storeFinder)) {
      return PageType.STORE_FINDER;
    }
    if (guides.find((item) => pathName.includes(item)) !== undefined) {
      return PageType.GUIDES;
    }
    if (pathName.includes(campaignHub)) {
      if (pathName.split(campaignHub)[1].length === 0) {
        return PageType.CAMPAIGN_HUB;
      } else {
        return PageType.CAMPAIGN;
      }
    }
    if (pathName.includes(error)) {
      return PageType.ERROR;
    }
  }

  return PageType.HOME;
};

const getSharedPageData = async (
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
): Promise<SharedPageData> => {
  const { req } = context;
  const { headers, url } = req;
  const { host } = headers;

  if (typeof host !== "string") {
    throw TypeError(`Expected pageHost to be string, got ${typeof host}`);
  }

  if (typeof url !== "string") {
    throw TypeError(`Expected page url to be string, got ${typeof url}`);
  }

  const siteUid = getSiteUid();
  const environmentVariables = getEnvironmentVariables(process.env.ENV_SITE);
  const getTranslationsContentPromise = getTranslationsContent(
    environmentVariables.TOGGLE_TEAMSALES
  );

  const [translations, ssmParams] = await Promise.all([
    getTranslationsContentPromise,
    getSsmConfigParameters(siteUid),
  ]);

  const {
    cookieVersion,
    ecoOnlineApiKey,
    ecoOnlineUrl,
    giosg,
    logRocketApiId,
    serverGtmScriptUrl,
  } = ssmParams;

  const environmentData = await getEnvironmentData({
    cookieVersion,
    environmentVariables,
    isDevelopment: isLocalhost(host),
    siteUid,
  });

  return {
    environmentData,
    ecoOnlineApiKey,
    ecoOnlineUrl,
    giosg,
    headerCode: environmentVariables.CONFIG_SITE_CONTENT_HEADERCODE,
    logRocketApiId,
    serverGtmScriptUrl,
    translations,
  };
};

const dynamicCookieToFeatureToggles = (
  dynamicToggles: ToggleCookie
): FeatureToggles =>
  Object.keys(dynamicToggles).reduce((toggles, name) => {
    return {
      ...toggles,
      ...{
        [name]:
          name !== "toggle_force_solr_as_provider_on_pages"
            ? dynamicToggles[name][0] === "true"
            : dynamicToggles[name].toString(),
      },
    };
  }, {} as FeatureToggles);

const getPageData = (
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
  sharedData: SharedPageData
): PageData => {
  const { req } = context;
  const { headers, url } = req;
  const pageType: PageType = getPageType(url);

  if (typeof url !== "string") {
    throw TypeError(`Expected page url to be string, got ${typeof url}`);
  }

  const featureTogglesFromCookie =
    process.env.ENV_TYPE !== "prod" ? getXXLToggleCookieFromRequest(req) : null;
  const dynamicCookieFeatureToggles =
    featureTogglesFromCookie !== null
      ? dynamicCookieToFeatureToggles(featureTogglesFromCookie)
      : undefined;

  return {
    ...sharedData,
    environmentData: {
      ...sharedData.environmentData,
      featureToggles: {
        ...sharedData.environmentData.featureToggles,
        ...dynamicCookieFeatureToggles,
      },
      pageType,
    },
    isBot: isBot(headers),
  };
};

const withPageData =
  <T = undefined>(
    cb: GetServerSidePropsCallback<XXLAppData, T>,
    additionalData?: T | undefined
  ): GetServerSideProps<XXLAppData> =>
  async (context) => {
    try {
      const { req, res } = context;
      const { cookies } = req;

      const { customerKey, sessionKey } = await setCookies({
        cookies,
        req,
        res,
      });
      let commonData: SharedPageData | null = await getCachedPageData();
      if (commonData === null) {
        commonData = await getSharedPageData(context);
        void setCachedPageData(commonData);
      }
      const pageData = getPageData(context, commonData);

      return await cb(
        context,
        pageData,
        {
          customerKey,
          sessionKey,
        },
        additionalData
      );
    } catch (error) {
      log.error(
        "Unhandled error on page, for more info turn on debug mode",
        (error as Error).message
      );
      log.debug("Unhandled error on page", error);
      return NotFoundResponse;
    }
  };

export { withPageData };
