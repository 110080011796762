import { CreateClientApi } from "../PdpBody/Reviews/api/client";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../queries/queryKeys";
import { useAmplifyConfig } from "@/react-hooks/useAmplifyConfig";

export const useSizeRecommendationQuery = (articleNr: string) => {
  const { aws_appsync_apiKey, aws_appsync_graphqlEndpoint } =
    useAmplifyConfig();
  const api = CreateClientApi(aws_appsync_apiKey, aws_appsync_graphqlEndpoint);

  const fetchSizeRecommendation = async () =>
    await api.ReviewService.GetSizeRating(articleNr);

  return useQuery({
    queryKey: [QUERY_KEYS.SIZE_RECOMMENDATION],
    queryFn: fetchSizeRecommendation,
  });
};
