import type { BaseProductData } from "@xxl/product-search-api";
import { createContext, useContext } from "react";
import type { FavoritesArrayType } from "./Favorites.helper";

export const FAVORITES_REQUEST_SUCCESS = "FAVORITES_REQUEST_SUCCESS";
export const FAVORITES_REQUEST_ERROR = "FAVORITES_REQUEST_ERROR";
export const UPDATE_FAVORITES_LIST = "UPDATE_FAVORITES_LIST";
export const SET_FAVORITES_ARRAY = "SET_FAVORITES_ARRAY";
export const LOAD_MORE_FAVORITES = "LOAD_MORE_FAVORITES";
export const SET_FAVORITES_LOADING = "SET_FAVORITES_LOADING";
export const TOGGLE_IS_IN_SHARE_MODE = "TOGGLE_IS_IN_SHARE_MODE";
export const SET_SHARED_LIST = "SET_SHARED_LIST";
export const SET_EMPTY_FAVORITES_PAGE = "SET_EMPTY_FAVORITES_PAGE";

const TAKE_FAVORITES_NUMBER = 18;
export const FAVORITES_TRACKING_KEY = "favorites";

export enum FavoritesConfirmationEnum {
  Hidden,
  Added,
  Removed,
}

type State = {
  favorites?: BaseProductData[];
  favoritesCount?: number;
  isLoading: boolean;
  updateFavorites: boolean;
  favoriteIds?: FavoritesArrayType;
  loadMoreFavorites: boolean;
  takeFavoritesNumber?: number;
  isInShareMode: boolean;
  sharedFavorites: string[];
};

type StateStruct = {
  current: State;
  prev?: State;
};

type Action =
  | {
      type: typeof FAVORITES_REQUEST_SUCCESS;
      payload: BaseProductData[];
    }
  | {
      type: typeof SET_FAVORITES_ARRAY;
      payload: FavoritesArrayType;
    }
  | {
      type: typeof FAVORITES_REQUEST_ERROR;
    }
  | {
      type: typeof LOAD_MORE_FAVORITES;
    }
  | {
      type: typeof SET_FAVORITES_LOADING;
    }
  | {
      type: typeof TOGGLE_IS_IN_SHARE_MODE;
    }
  | {
      type: typeof SET_EMPTY_FAVORITES_PAGE;
    }
  | {
      type: typeof SET_SHARED_LIST;
      payload: string;
    }
  | {
      type: typeof UPDATE_FAVORITES_LIST;
    };

type DefaultValue = {
  state: State;
  dispatch: (action: Action) => void;
};

const initialStaticState: State = {
  isLoading: false,
  updateFavorites: true,
  loadMoreFavorites: false,
  isInShareMode: false,
  sharedFavorites: [],
};

export const getInitialState = (): StateStruct => {
  return {
    current: initialStaticState,
  };
};

const reducerInternal = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_FAVORITES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FAVORITES_REQUEST_SUCCESS: {
      const baseProducts = action.payload;
      return {
        ...state,
        isLoading: false,
        loadMoreFavorites: false,
        favoritesCount: baseProducts.length,
        favorites: baseProducts,
        updateFavorites: false,
      };
    }
    case FAVORITES_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        loadMoreFavorites: false,
        updateFavorites: false,
      };
    case UPDATE_FAVORITES_LIST:
      return {
        ...state,
        updateFavorites: true,
        isLoading: false,
        loadMoreFavorites: false,
        favoritesCount: undefined,
        takeFavoritesNumber: state.favorites?.length ?? TAKE_FAVORITES_NUMBER,
        favorites: undefined,
      };
    case SET_FAVORITES_ARRAY:
      return {
        ...state,
        favoriteIds: action.payload,
      };
    case LOAD_MORE_FAVORITES:
      return {
        ...state,
        loadMoreFavorites: true,
      };
    case TOGGLE_IS_IN_SHARE_MODE:
      return {
        ...state,
        isInShareMode: !state.isInShareMode,
        sharedFavorites: [],
      };
    case SET_SHARED_LIST:
      return {
        ...state,
        sharedFavorites: [...state.sharedFavorites, action.payload],
      };
    case SET_EMPTY_FAVORITES_PAGE:
      return {
        ...state,
        isLoading: false,
        updateFavorites: false,
        loadMoreFavorites: false,
        favoritesCount: 0,
      };
    default:
      return state;
  }
};

export const reducer = (
  stateStruct: StateStruct,
  action: Action
): StateStruct => {
  return {
    prev: stateStruct.current,
    current: reducerInternal(stateStruct.current, action),
  };
};

export const FavoritesContext = createContext<DefaultValue>({
  dispatch: (): void => {
    return;
  },
  state: initialStaticState,
});

export const useFavoritesContext = (): DefaultValue =>
  useContext(FavoritesContext);
