import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { useStoreDetailsQuery } from "../../queries/useStoreDetailsQuery";
import type { Store } from "../../types";
import { StoreStockSelectedDetailsSection } from "../StoreStockSelectedDetailsSection";

export const StoreDetailsSectionComponent = ({
  store,
  isMobile,
}: {
  store: Store;
  isMobile: boolean;
}) => {
  const { t } = useTranslations();
  const {
    data: storeDetails,
    isLoading: isLoadingQuery,
    isError,
  } = useStoreDetailsQuery(store);
  if (isError) {
    return <div>{t("general.error")}</div>;
  }
  return (
    <StoreStockSelectedDetailsSection
      store={storeDetails}
      isLoading={isLoadingQuery}
      isMobile={isMobile}
    />
  );
};
