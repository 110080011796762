import React from "react";
import { ClientSideRenderHTML } from "../../ClientSideRenderHTML";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

/**
 * Implementation details taken from https://docs.klarna.com/on-site-messaging/in-depth-knowledge/on-site-messaging-javascript-library/
 * 2023-09-18
 */

type PaymentProviderMessageProps = {
  locale: string;
  productSalesPrice: number;
};

export const PaymentProviderMessage = ({
  locale,
  productSalesPrice,
}: PaymentProviderMessageProps) => {
  const priceInMinorUnits = productSalesPrice * 100;
  const id = "xxl-payment-provider-msg";
  const styling = `
    #${id}::part(osm-container) {
      background-color: ${xxlTheme.colors.xxlWhite};
      padding: ${xxlTheme.spaces.mini};
      border: 1px solid ${xxlTheme.colors.xxlMediumGrey};
    }
  `;

  // Load klarna-placement client side to avoid hydration error caused by Klarna script changing DOM
  const html = `
    <klarna-placement
      data-key="credit-promotion-badge"
      data-locale="${locale}"
      data-purchase-amount="${priceInMinorUnits}"
      id="${id}"
    />
  `;

  return (
    <div>
      <style>{styling}</style>
      <ClientSideRenderHTML html={html} />
    </div>
  );
};
