import * as React from "react";
import {
  HelpfulnessVoteContainer,
  ThumbButton,
  ThumbDescription,
} from "./HelpfulnessVote.styled";
import ThumbDown from "@mui/icons-material/ThumbDown";
import ThumbUp from "@mui/icons-material/ThumbUp";
import type { UpdateServerWithNewHelpfulnessVoteProps } from "./HelpfulnessVoteHelper";
import {
  updateServerWithNewHelpfulnessVote,
  calculateHelpfulnessVotes,
  getClientStoredHelpfulnessVote,
  updateClientWithNewHelpfulnessVote,
  getSessionStorageKey,
} from "./HelpfulnessVoteHelper";
import debounce from "lodash/debounce";
import type { HelpfulnessVotesType } from "../Api/RatingsAndReviewsAPI";
import { HelpfulnessVotes } from "../Api/RatingsAndReviewsAPI";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { useSharedData } from "react-app/src/contexts/SharedData";

export type HelpfulnessVotesCountProp = {
  helpfulCount: number;
  unhelpfulCount: number;
};

type HelpfulnessVoteProps = {
  reviewId: string;
  helpfulVoteCount: number;
  unhelpfulVoteCount: number;
};

export const HelpfulnessVote: React.FunctionComponent<HelpfulnessVoteProps> = ({
  reviewId,
  helpfulVoteCount,
  unhelpfulVoteCount,
}): JSX.Element => {
  const { t } = useTranslations();
  const {
    data: {
      configuration: {
        amplifyConfig: { aws_appsync_apiKey, aws_appsync_graphqlEndpoint },
      },
    },
  } = useSharedData();
  const initialHelpfulnessVote = getClientStoredHelpfulnessVote(
    getSessionStorageKey(reviewId)
  );
  const adjustHelpfulCaching = (
    requestedCount: number,
    storedCount: HelpfulnessVotesType
  ): number => {
    if (requestedCount === 0 && storedCount === HelpfulnessVotes.HELPFUL) {
      return 1;
    }
    return requestedCount;
  };
  const adjustUnhelpfulCaching = (
    requestedCount: number,
    storedCount: HelpfulnessVotesType
  ): number => {
    if (requestedCount === 0 && storedCount === HelpfulnessVotes.UNHELPFUL) {
      return 1;
    }
    return requestedCount;
  };
  const [helpfulnessVotesCount, setHelpfulnessVotesCount] =
    React.useState<HelpfulnessVotesCountProp>({
      helpfulCount: adjustHelpfulCaching(
        helpfulVoteCount,
        initialHelpfulnessVote
      ),
      unhelpfulCount: adjustUnhelpfulCaching(
        unhelpfulVoteCount,
        initialHelpfulnessVote
      ),
    });

  const [currentHelpfulnessVote, setCurrentHelpfulnessVote] =
    React.useState<HelpfulnessVotesType>(initialHelpfulnessVote);

  const delayedUpdateServerWithNewHelpfulnessVote = React.useMemo(
    () =>
      debounce(async (props: UpdateServerWithNewHelpfulnessVoteProps) => {
        await updateServerWithNewHelpfulnessVote(
          props,
          aws_appsync_apiKey,
          aws_appsync_graphqlEndpoint
        );
        updateClientWithNewHelpfulnessVote(props);
      }, 250),
    []
  );

  const handleClickHelpfulnessVote =
    (clickedVote: keyof typeof HelpfulnessVotes) => async () => {
      const shouldRemoveVote = clickedVote === currentHelpfulnessVote;
      const newHelpfulnessVote = shouldRemoveVote ? null : clickedVote;
      const calculatedHelpfulnessVotesCount = calculateHelpfulnessVotes({
        newHelpfulnessVote,
        currentHelpfulnessVote,
        helpfulnessVotesCount,
      });
      setCurrentHelpfulnessVote(newHelpfulnessVote);
      setHelpfulnessVotesCount(calculatedHelpfulnessVotesCount);
      await delayedUpdateServerWithNewHelpfulnessVote({
        helpfulnessVote: newHelpfulnessVote,
        reviewId,
      });
    };

  return (
    <HelpfulnessVoteContainer>
      <ThumbDescription>
        {t("product.ratings-and-reviews.read.was.helpful")}
      </ThumbDescription>
      <br />
      <ThumbButton
        data-testid="helpful-button"
        onClick={handleClickHelpfulnessVote(HelpfulnessVotes.HELPFUL)}
        isActive={currentHelpfulnessVote === HelpfulnessVotes.HELPFUL}
        isFilled={helpfulnessVotesCount.helpfulCount > 0}
      >
        <ThumbUp titleAccess={t("general.yes")}></ThumbUp>
        {`${t("general.yes")} (${helpfulnessVotesCount.helpfulCount})`}
      </ThumbButton>
      <ThumbButton
        data-testid="not-helpful-button"
        onClick={handleClickHelpfulnessVote(HelpfulnessVotes.UNHELPFUL)}
        isActive={currentHelpfulnessVote === HelpfulnessVotes.UNHELPFUL}
        isFilled={helpfulnessVotesCount.unhelpfulCount > 0}
      >
        <ThumbDown titleAccess={t("general.no")}></ThumbDown>
        {`${t("general.no")} (${helpfulnessVotesCount.unhelpfulCount})`}
      </ThumbButton>
    </HelpfulnessVoteContainer>
  );
};
