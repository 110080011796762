import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const { colors, iconSize, zIndex } = xxlTheme;

export type Direction = "right" | "left";
export type Variant = "default" | "dark" | "gray";

type ButtonProps = {
  variant?: Variant;
  margin: number;
  direction: Direction;
};

export const Button = styled.button<ButtonProps>(
  ({ variant = "default", direction, margin }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    height: ${iconSize.big};
    width: ${iconSize.big};
    background-color: ${variant === "dark" ? colors.xxlBlack : "transparent"};
    position: absolute;
    top: 50%;
    z-index: ${zIndex["image-banner"]};
    transition: all 200ms;
    ${direction === "right" ? `right: ${margin}px;` : `left: ${margin}px;`}

    svg {
      height: ${iconSize.small};
      width: ${iconSize.small};
      color: ${variant === "dark" ? colors.xxlWhite : colors.xxlBlack};
      background-color: ${variant === "gray"
        ? colors.xxlLightGrey
        : "transparent"};
    }

    &:disabled {
      opacity: 0;
      pointer-events: none;
    }
  `
);
