import { isNotEmpty, isNullOrUndefined } from "@xxl/common-utils";
import type { Translate } from "react-app/src/contexts/Translations/TranslationsContext";
import {
  ANONYMOUS_CUSTOMERS_USER_GROUP,
  ANONYMOUS_CUSTOMERS_USER_GROUP_ALT,
  REGISTERED_CUSTOMERS_USER_GROUP,
} from "../../constants";
import type { TranslationKey } from "../../translations";

export type UspClassification = {
  name: string;
  value: string;
  id: string;
};

type UspValue = {
  userGroupId?: string;
  value?: string;
  valueType?: string;
};

type UspItem = {
  key?: string;
  name?: string;
  values: UspValue[];
};

const separator = ": ";

const getLoggedInValue = (
  values: UspValue[],
  isLoggedIn: boolean
): UspValue | undefined =>
  values.find(
    isLoggedIn
      ? ({ userGroupId }) => userGroupId === REGISTERED_CUSTOMERS_USER_GROUP
      : ({ userGroupId }) =>
          userGroupId === ANONYMOUS_CUSTOMERS_USER_GROUP ||
          userGroupId === ANONYMOUS_CUSTOMERS_USER_GROUP_ALT
  ) ?? undefined;

const getUspName = (usp: UspItem): string => {
  if (isNotEmpty(usp.name)) {
    return usp.name;
  }

  if (usp.values.length < 1 || usp.values[0].value === undefined) {
    return "";
  }

  return usp.values[0].value.split(separator)[0];
};

const getUspValue = (t: Translate, uspValue?: UspValue): string => {
  if (isNullOrUndefined(uspValue) || isNullOrUndefined(uspValue.value)) {
    return "";
  }
  const { value, valueType } = uspValue;
  const valueWithoutName =
    value.indexOf(separator) === -1 ? value : value.split(separator)[1];
  if (
    valueType === "boolean" ||
    valueWithoutName === "true" ||
    valueWithoutName === "false"
  ) {
    return valueWithoutName === "true" ? t("general.yes") : t("general.no");
  }

  return valueWithoutName;
};

/**
 * Creates classifications from USP items.
 * IMPORTANT: Does not apply to data from the Elevate data source
 * */
const getClassificationsFromUsps = (
  usps: UspItem[],
  isLoggedIn: boolean,
  t: Translate
): UspClassification[] => {
  return usps.reduce((acc, uspItem, index) => {
    if (uspItem.values.length < 1) {
      return acc;
    }
    const [uspFirstValueItem] = uspItem.values;
    const name = isNotEmpty(uspItem.key)
      ? t(uspItem.key as TranslationKey)
      : getUspName(uspItem);
    const hasSetOfValues = uspItem.values.length > 1;
    const value = hasSetOfValues
      ? getLoggedInValue(uspItem.values, isLoggedIn)
      : uspFirstValueItem;

    return [
      ...acc,
      {
        id: `usp-classification-${uspItem.key ?? ""}-${index}`,
        name: name,
        value: getUspValue(t, value),
      },
    ];
  }, [] as UspClassification[]);
};

export { getClassificationsFromUsps };
