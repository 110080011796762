import { Icon } from "@/react-components/Icon";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";
import {
  INITIAL_IMAGE_SLIDE_DESKTOP,
  MIN_IMAGES_FOR_DESKTOP_THUMBNAILS,
} from "../../constants";
import { ImageDialog } from "../ImageDialog";
import {
  Arrow,
  DesktopImageGrid,
  SliderArrowContainer,
} from "../ProductImage.styles";
import type { ProductImageSliderInnerComponentProps } from "../types";
import { ProductImageSliderContent } from "./ProductImageSliderContent";
import { ThumbnailsImages } from "./ThumbnailsImages";
import { ThumbnailsSlider } from "./ThumbnailsSlider";

export const ProductImageSliderDesktop = ({
  children,
  productImages = [],
  productName,
  shouldReplaceThumbnailsWithArrows,
  shouldShowUpsaleLink,
}: ProductImageSliderInnerComponentProps) => {
  const initialSlide = INITIAL_IMAGE_SLIDE_DESKTOP;
  const hasThumbnailSlider =
    productImages.length > MIN_IMAGES_FOR_DESKTOP_THUMBNAILS;
  const hasMoreThanOneProductImage = productImages.length > 1;

  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [isSlideLoaded, setIsSlideLoaded] = useState(false);
  const [isFirstItem, setIsFirstItem] = useState(true);
  const [isLastItem, setIsLastItem] = useState(false);
  const [fullScreenPreviewOpen, setFullScreenPreviewOpen] = useState(false);
  const [initialSlideIndex, setInitialSlideIndex] = useState(initialSlide);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: initialSlide,
    slideChanged(slider) {
      const { abs, maxIdx, rel } = slider.track.details;
      setCurrentSlide(rel);
      setIsFirstItem(abs === INITIAL_IMAGE_SLIDE_DESKTOP);
      setIsLastItem(abs === maxIdx);
    },
    created() {
      setIsSlideLoaded(true);
    },
  });

  const handleClose = () => setFullScreenPreviewOpen(false);
  const handlePreviousClick = () => instanceRef.current?.prev();
  const handleNextClick = () => instanceRef.current?.next();
  const handleClickOpen = (img: string) => {
    setFullScreenPreviewOpen(true);
    const index = productImages.findIndex((image) => image === img);
    setInitialSlideIndex(index);
  };

  return (
    <DesktopImageGrid
      shouldReplaceThumbnailsWithArrows={shouldReplaceThumbnailsWithArrows}
    >
      {!shouldReplaceThumbnailsWithArrows && (
        <>
          {hasThumbnailSlider && isSlideLoaded ? (
            <ThumbnailsSlider
              productImages={productImages}
              instanceRef={instanceRef}
              currentSlide={currentSlide}
              handleNextClick={handleNextClick}
              handlePreviousClick={handlePreviousClick}
            />
          ) : (
            <ThumbnailsImages
              productImages={productImages}
              instanceRef={instanceRef}
              currentSlide={currentSlide}
              productName={productName}
            />
          )}
        </>
      )}
      {shouldReplaceThumbnailsWithArrows && (
        <SliderArrowContainer>
          <Arrow
            direction="left"
            disabled={isFirstItem}
            onClick={handlePreviousClick}
          >
            <Icon name="CaretUp" color="white" size={18} />
          </Arrow>
        </SliderArrowContainer>
      )}
      <ProductImageSliderContent
        handleClickOpen={handleClickOpen}
        hasMoreThanOneProductImage={hasMoreThanOneProductImage}
        isLastItem={isLastItem}
        productImages={productImages}
        productName={productName}
        ref={sliderRef}
        shouldShowUpsaleLink={shouldShowUpsaleLink}
      >
        {children}
      </ProductImageSliderContent>
      {shouldReplaceThumbnailsWithArrows && (
        <SliderArrowContainer>
          <Arrow
            direction="right"
            disabled={isLastItem}
            onClick={handleNextClick}
          >
            <Icon name="CaretUp" color="white" size={18} />
          </Arrow>
        </SliderArrowContainer>
      )}
      <ImageDialog
        initialSlideIndex={initialSlideIndex}
        open={fullScreenPreviewOpen}
        handleClose={handleClose}
        productImages={productImages}
      />
    </DesktopImageGrid>
  );
};
