import React, { useEffect, useState } from "react";
import {
  PriceBox,
  PricesList,
  SmallPriceRow,
  TotalPriceRow,
  CouponsRow,
  PromotionsList,
  PromotionsItem,
} from "../Styles/TotalPrice.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { isWalley, useCartContext } from "../CartState";
import type { KlarnaPlacementWidgetConfig } from "../../../global";
import * as XxlEvent from "../../../utils/xxl-event";
import {
  formatOptionalPrice,
  formatPrice,
} from "../../../utils/xxl-price-format";
import { sumIfSet } from "../Services/sumIfSet";
import { useSharedData } from "../../../contexts/SharedData";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import {
  SUCCESS_RESPONSE_STATUS,
  getOnSiteMessagingConfigurationForProducts,
} from "../Api/CartAPI";
import {
  legacySiteUidToSiteUid,
  convertSiteUidToHost,
} from "../../../utils/xxl-shared-data";
import { PaymentProvider } from "../../../generated/graphql-code-generator";
import { log } from "@xxl/logging-utils";
import {
  getEmployeeDiscount,
  getRemainingDiscountTotal,
} from "../utils/discountPrice";

export const TotalPrice: React.FunctionComponent = () => {
  const { state } = useCartContext();
  const { t } = useTranslations();
  const {
    data: {
      klarnaPlacementWidget,
      configuration: {
        amplifyConfig: { aws_appsync_graphqlEndpoint, aws_appsync_apiKey },
      },
      siteUid,
      siteCurrency,
    },
    isReactApp,
  } = useSharedData();
  const siteHost = convertSiteUidToHost(legacySiteUidToSiteUid(siteUid));
  const [klarnaPlacement, setKlarnaPlacement] = useState<
    KlarnaPlacementWidgetConfig | undefined
  >();
  const [isWalleyProvider, setIsWalleyProvider] = useState(
    isWalley(state.paymentProvider)
  );
  const getKlarnaPlacementConfig = async (): Promise<void> => {
    try {
      if (isReactApp && isNotNullOrUndefined(klarnaPlacementWidget)) {
        setKlarnaPlacement({
          ...klarnaPlacementWidget,
          size:
            klarnaPlacementWidget.size !== undefined &&
            klarnaPlacementWidget.size.length > 0
              ? klarnaPlacementWidget.size
              : "auto-size",
        });
      } else {
        const products: string[] | undefined =
          isNotNullOrUndefined(state.cart) &&
          isNotNullOrUndefined(state.cart.data) &&
          isNotNullOrUndefined(state.cart.data.cart) &&
          isNotNullOrUndefined(state.cart.data.cart.items)
            ? state.cart.data.cart.items.map((item) => item.articleNumber)
            : undefined;
        if (isNotNullOrUndefined(products)) {
          const response = await getOnSiteMessagingConfigurationForProducts(
            products,
            siteHost,
            aws_appsync_graphqlEndpoint,
            aws_appsync_apiKey
          );

          if (
            response.status === SUCCESS_RESPONSE_STATUS &&
            isNotNullOrUndefined(response.data.data) &&
            "onSiteMessagingConfigurationForProducts" in response.data.data &&
            isNotNullOrUndefined(klarnaPlacementWidget)
          ) {
            const { data } = response.data;

            const clientId = String(
              data.onSiteMessagingConfigurationForProducts?.clientId ??
                klarnaPlacementWidget.clientId
            );
            const { show, scriptUrl, locale } = klarnaPlacementWidget;
            setKlarnaPlacement({
              size: "auto-size",
              show,
              scriptUrl,
              clientId: clientId,
              locale,
            });
          }
        }
      }
    } catch (error) {
      log.error("Cannot get Klarna Placement config", error);
    }
  };

  const purchaseAmount = state.displayCart?.totalPriceAsInteger;

  useEffect(() => {
    if (
      isNotNullOrUndefined(window.KlarnaPlacementWidget) &&
      !isWalleyProvider
    ) {
      window.KlarnaPlacementWidget.initialize();
    }
  }, []);

  useEffect(() => {
    setIsWalleyProvider(isWalley(state.paymentProvider));
  }, [state.paymentProvider]);

  useEffect(() => {
    if (klarnaPlacement === undefined && !isWalleyProvider) {
      const config = void getKlarnaPlacementConfig();
      if (isNotNullOrUndefined(config)) {
        setKlarnaPlacement(config);
      }
    }
  }, [klarnaPlacement]);

  const [shippingFee, setShippingFee] = useState<number | null>(null);

  useEffect(() => {
    const onShippingChange = (event: CustomEvent<{ price: unknown }>) => {
      const price =
        typeof event.detail.price === "number" ? event.detail.price : 0;
      setShippingFee(price / 100);
    };

    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_SHIPPING_UPDATE,
      onShippingChange
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_SHIPPING_UPDATE,
        onShippingChange
      );
    };
  }, []);

  useEffect(() => {
    setShippingFee(state.displayCart?.shippingTotalAmount ?? 0);
  }, [state.displayCart?.shippingTotalAmount]);

  const remainingDiscountTotal = getRemainingDiscountTotal(
    state.cart?.data?.cart?.totals.cartDiscountTotals ?? []
  );
  const employeeDiscountTotal = getEmployeeDiscount(
    state.cart?.data?.cart?.totals.cartDiscountTotals ?? []
  );
  const shouldShowDiscounts =
    state.displayCart?.hasDiscounts === true && remainingDiscountTotal > 0;
  const shouldShowEmployeeDiscount =
    state.displayCart?.hasDiscounts === true && employeeDiscountTotal > 0;

  return (
    <PriceBox>
      <PricesList>
        <SmallPriceRow>
          <span>{t("cart.summary.partial.sum")}</span>
          <span>
            {formatPrice(
              state.displayCart?.priceWithoutDiscount ?? 0,
              siteCurrency
            )}
          </span>
        </SmallPriceRow>
        {shouldShowDiscounts && (
          <SmallPriceRow discountPrice={true}>
            <span>{t("cart.summary.discount")}</span>
            <span>
              -{formatOptionalPrice(remainingDiscountTotal, siteCurrency)}
            </span>
          </SmallPriceRow>
        )}
        {shouldShowEmployeeDiscount && (
          <SmallPriceRow employeePrice={true}>
            <span>{t("cart.summary.employee.discount")}</span>
            <span>
              ~{formatOptionalPrice(employeeDiscountTotal, siteCurrency)}
            </span>
          </SmallPriceRow>
        )}
        {isWalley(state.paymentProvider) &&
          isNotNullOrUndefined(state.displayCart) &&
          isNotNullOrUndefined(state.displayCart.giftCards) && (
            <>
              {state.displayCart.giftCards.map((item, index) => (
                <SmallPriceRow giftCardPrice={true} key={index}>
                  <span>{t("checkout.page.gift.card.name")}</span>
                  <span>
                    -{formatOptionalPrice(item.usedBalance, siteCurrency)}
                  </span>
                </SmallPriceRow>
              ))}
            </>
          )}
        {shippingFee !== null && (
          <SmallPriceRow>
            <span>{t("cart.summary.shipping.title")}</span>
            <span>
              {shippingFee === 0
                ? t("cart.summary.shipping.free")
                : formatPrice(shippingFee, siteCurrency)}
            </span>
          </SmallPriceRow>
        )}
      </PricesList>
      <TotalPriceRow>
        <span>{t("cart.summary.footer.total.sum")}</span>
        <span>
          {isWalley(state.paymentProvider)
            ? formatOptionalPrice(
                state.displayCart?.paymentTotalAmount,
                siteCurrency
              )
            : formatOptionalPrice(
                sumIfSet(
                  state.displayCart?.totalAmountExcludingShipping,
                  shippingFee
                ),
                siteCurrency
              )}
        </span>
      </TotalPriceRow>
      {state.displayCart?.appliedOrderPromotions !== undefined &&
        state.displayCart.appliedOrderPromotions.length > 0 && (
          <PromotionsList>
            {state.displayCart.appliedOrderPromotions.map(
              (promotion, index) => (
                <React.Fragment key={`promotions-list-${index}`}>
                  <PromotionsItem>
                    <CouponsRow>{promotion.description}</CouponsRow>
                  </PromotionsItem>
                </React.Fragment>
              )
            )}
          </PromotionsList>
        )}
      {state.paymentProvider === PaymentProvider.KLARNA &&
        klarnaPlacement !== undefined &&
        purchaseAmount !== undefined && (
          <div className="js-klarna-placement-widget-container">
            <klarna-placement
              class="klarna-placement js-klarna-placement-widget"
              data-key={`credit-promotion-${klarnaPlacement.size as string}`}
              data-locale={`${klarnaPlacement.locale as string}`}
              data-purchase-amount={`${purchaseAmount}`}
            ></klarna-placement>
          </div>
        )}
    </PriceBox>
  );
};
