import { resolutionSizes } from "@/react-app/config";
import { XxlStack } from "@/react-components/Common/XxlStack";
import styled from "@emotion/styled/macro";
import { Text } from "react-app/src/components/Text";
import { MQ } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const {
  spaces: { large, regular, smallRegular },
} = xxlTheme;

const sharedMargin = `
  margin-bottom: 0;
  margin-top: ${smallRegular};
  ${MQ("laptop")} {
    margin-top: ${large};
  }
`;

export const Ul = styled.ul`
  ${sharedMargin}
  padding-left: ${regular};
`;

export const Article = styled.article`
  > *,
  & + ul {
    ${sharedMargin}
  }
`;

export const StyledDescription = styled(Text)`
  .pdp_description--highlight {
    // Is defined in https://bitbucket.org/xxldevelopment/master-products-service
    font-family: var(--font-family-bold);
  }
`;

export const DescriptionGrid = styled.div`
  display: grid;
  grid-gap: ${xxlTheme.spaces.regular};
  row-gap: ${xxlTheme.spaces.large};
  @media (min-width: ${resolutionSizes.laptop}px) {
    grid-template-columns: 1fr 1fr;
    gap: ${xxlTheme.spaces.big};
  }
`;

export const OverviewGridArea = styled(XxlStack)`
  grid-area: right;
`;
