import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import type { TranslationKey } from "@/react-app/translations";
import type { StockLevel } from "../types";

export const useTranslationCCStockStatus = (stockLevel: StockLevel) => {
  const { t } = useTranslations();
  return t(
    {
      INSTOCK: "product.details.clickcollect.instock.collectable",
      LOWSTOCK: "product.details.clickcollect.fewstock.collectable",
      OUTOFSTOCK: "product.details.clickcollect.nostock.collectable",
    }[stockLevel] as TranslationKey
  );
};

export const useTranslationStoreStockStatus = (stockLevel: StockLevel) => {
  const { t } = useTranslations();
  return t(
    {
      INSTOCK: "product.details.clickcollect.instock.store",
      LOWSTOCK: "product.details.clickcollect.fewstock.store",
      OUTOFSTOCK: "product.details.clickcollect.nostock.store",
    }[stockLevel] as TranslationKey
  );
};
