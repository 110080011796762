import { xxlTheme } from "@/react-app/styles/xxl-theme";
import styled from "@emotion/styled/macro";
import {
  MQ,
  interactionFeedback,
  typographyToCss,
} from "react-app/src/styles/helpers";

const { spaces, colors, typography } = xxlTheme;

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: ${spaces.large};
  height: 100%;
  box-sizing: border-box;
  background-color: ${colors.xxlLightGrey};
  ${typographyToCss(typography.bigBold)}

  font-size: ${spaces.biggerBig};
  ${MQ("laptop")} {
    font-size: calc(2 * ${spaces.biggerBig});
  }

  ${interactionFeedback(`
    background-color: ${colors.xxlMediumGrey};
  `)}
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: ${spaces.mini};
  font-size: ${spaces.big};
  ${MQ("laptop")} {
    font-size: ${spaces.smallHuge};
  }
  & > span {
    font-size: ${spaces.largeBig};
    ${MQ("laptop")} {
      font-size: ${spaces.big};
    }
  }
`;

export const StyledLastPlaceSlide = styled.div`
  place-items: center;
  background-color: ${colors.xxlLightGrey};

  ${interactionFeedback(`
    cursor: pointer;
  `)}
`;
