import styled from "@emotion/styled/macro";
import type { USPData } from "@xxl/product-search-api";
import parse from "html-react-parser";
import { Text } from "react-app/src/components/Text";
import { typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  margin-left: ${xxlTheme.spaces.small};
  display: flex;
  flex-direction: column;
  gap: ${xxlTheme.spaces.micro};
`;

const Li = styled.li`
  ${typographyToCss(xxlTheme.typography.mediumRegular)};
  letter-spacing: 0;
  word-break: break-word;
`;

type Props = {
  usps: USPData[];
};

export const Attributes = ({ usps }: Props) => {
  if (usps.length === 0) return <div />;
  return (
    <Ul>
      {usps.slice(0, 3).map(({ key, name, value, valueType }) => (
        <Li key={key}>
          <Text as="span">{name}:&nbsp;</Text>
          <Text as="span">
            {valueType === "template" ? parse(value) : value}
          </Text>
        </Li>
      ))}
    </Ul>
  );
};
