import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Text } from "@/react-components/Text/Text";
import type { DateOpeningSchedule, Store } from "@xxl/content-api";
import { formatDate } from "../PdpBody/Reviews/ReviewCard.helpers";

const getTomorrowsDateFormatted = (rawDate: Date): string => {
  const tomorrowsDate = new Date(rawDate);
  tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);
  return formatDate(tomorrowsDate as unknown as number);
};

const getSpecialOpeningHoursForSelectedDate = (
  specialOpeningHoursData: DateOpeningSchedule[] | undefined,
  selectedDate: string
) => {
  return specialOpeningHoursData?.find(
    (specialOpeningHour: DateOpeningSchedule) =>
      specialOpeningHour.date === selectedDate
  );
};

const getOpeningHours = (todaysDate: Date, selectedStoreDetails: Store) => {
  const todaysDateFormatted = formatDate(todaysDate as unknown as number);
  const tomorrowsDateFormatted = getTomorrowsDateFormatted(todaysDate);

  const specialHoursToday = getSpecialOpeningHoursForSelectedDate(
    selectedStoreDetails.specialOpeningHours,
    todaysDateFormatted
  );

  const specialHoursTomorrow = getSpecialOpeningHoursForSelectedDate(
    selectedStoreDetails.specialOpeningHours,
    tomorrowsDateFormatted
  );

  //getDay() returns 0 for Sunday but in Sanity we store Monday at index 0
  const tomorrowDayIndex = todaysDate.getDay();
  const todayDayIndex = (tomorrowDayIndex + 6) % 7;
  const { openingHours = [] } = selectedStoreDetails;

  return {
    storeOpeningHoursToday: specialHoursToday ?? openingHours[todayDayIndex],
    storeOpeningHoursTomorrow:
      specialHoursTomorrow ?? openingHours[tomorrowDayIndex],
  };
};

type Props = {
  store: Store;
};

const StoreStockOpeningHours = ({ store }: Props) => {
  const { t } = useTranslations();
  const todaysDate = new Date();
  const { storeOpeningHoursToday, storeOpeningHoursTomorrow } = getOpeningHours(
    todaysDate,
    store
  );

  return (
    <XxlStack>
      <Text as="span">
        {t("product.details.storestock.open.today")}:&nbsp;
        <Text fontFamily="bold" as="span">
          {storeOpeningHoursToday.opensAt ?? ""}&nbsp;&ndash;&nbsp;
          {storeOpeningHoursToday.closesAt ?? ""}
        </Text>
      </Text>
      <Text as="span">
        {t("product.details.storestock.open.tomorrow")}:&nbsp;
        <Text fontFamily="bold" as="span">
          {storeOpeningHoursTomorrow.opensAt ?? ""}&nbsp;&ndash;&nbsp;
          {storeOpeningHoursTomorrow.closesAt ?? ""}
        </Text>
      </Text>
    </XxlStack>
  );
};

export { StoreStockOpeningHours };
