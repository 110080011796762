import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { legacySiteUidToSiteUid } from "@/react-utils/xxl-shared-data";
import { useQuery } from "@tanstack/react-query";
import { hasNoValue, hasValue } from "@xxl/common-utils";
import type { Store } from "../types";
import { QUERY_KEYS } from "./queryKeys";

export const useStoreDetailsQuery = (store?: Store) => {
  const {
    data: { siteUid },
  } = useSharedData();
  const { contentApi } = useApiClients();

  const getStore = async () => {
    if (hasNoValue(store)) {
      throw new Error(
        QUERY_KEYS.STORE_DETAILS + ":Store is undefined. Please try again."
      );
    }
    const response = await contentApi.getStore(
      legacySiteUidToSiteUid(siteUid),
      store.id
    );
    if (response.status !== 200 || response.data.result === undefined) {
      throw new Error(
        `Failed to fetch store data. Status Code: ${response.status}. Store ID: ${store.id}. Please try again.`
      );
    }

    return response.data.result[0];
  };
  return useQuery({
    enabled: hasValue(store),
    queryFn: getStore,
    queryKey: [QUERY_KEYS.STORE_DETAILS, store?.id],
    staleTime: Infinity,
  });
};
