import {
  getCentralWarehouseStock,
  type ProductData,
} from "@xxl/product-search-api";
import type { FrontendProductData } from "../../../hooks/useProductData/useProductData.types";

export const getSizeOptionsElevateData = ({
  variants,
}: Pick<ProductData, "variants">): FrontendProductData["sizeOptions"] =>
  variants.map(({ code, sizeCode, label, stockStatus, availability }) => ({
    ean: code,
    sizeCode,
    itemNumber: sizeCode.split("_")[0],
    size: label,
    stockStatus,
    expressDeliveryAvailable:
      getCentralWarehouseStock({ availability }).stockNumber > 0,
  }));
