import { DialogBox } from "react-app/src/components/DialogBox";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { MiniElevatePdpContent } from "./MiniElevatePdpContent";

type Props = {
  handleClose: () => void;
  isOpen: boolean;
  isQuickShop: boolean;
  productCode: string;
};

export const MiniElevatePdp = ({
  handleClose,
  isOpen,
  isQuickShop,
  productCode,
}: Props) => {
  const { t } = useTranslations();

  return (
    <DialogBox
      dialogBoxHeadline={t("product.details.product.information")}
      isDialogBoxOpen={isOpen}
      handleDialogBoxClosing={handleClose}
      hasPadding={true}
      hideShadow={isQuickShop}
      dialogBoxSize="md"
    >
      <MiniElevatePdpContent
        handleDialogBoxClosing={handleClose}
        isQuickShop={isQuickShop}
        productCode={productCode}
      />
    </DialogBox>
  );
};
