import { prepareContentLists } from "@/react-app/api/elevate-api";
import { getElevateApiConfiguration } from "@/utils/environment-variables";
import type { Site } from "@xxl/common-utils";
import { hasValue } from "@xxl/common-utils";
import type {
  LandingPageQuery,
  LandingPageRequestSettings,
  SearchPageQuery,
} from "@xxl/product-search-api";
import { ElevateApi, ProductApi } from "@xxl/product-search-api";
import {
  clientAndServerApiFunctionFactory,
  serverSideApiInstanceFactory,
} from "./shared/helpers";
import type { ApiOptions } from "./shared/types";

type getProductDiscountCountFunction = InstanceType<
  typeof ProductApi
>["getProductDiscountCount"];
export type GetSearchResultsApiOptions = ApiOptions<
  ElevateApi<unknown>["storefront"]["searchPagePOST"]
>;
export type GetLandingPageApiOptions = ApiOptions<
  ElevateApi<unknown>["storefront"]["landingPagePOST"]
>;

let productApi: ProductApi | undefined;
let elevateApi: ElevateApi<unknown> | undefined;

const getProductApiInstance = () =>
  serverSideApiInstanceFactory(() => {
    const { baseURL } = getElevateApiConfiguration(false);
    productApi = new ProductApi({ baseUrl: baseURL });
    return productApi;
  }, productApi);

const elevateApiInstance = (toggle_elevate_cluster: boolean) =>
  serverSideApiInstanceFactory(
    () =>
      ElevateApi.getInstance(
        getElevateApiConfiguration(toggle_elevate_cluster)
      ),
    elevateApi
  );

const getSearchResults =
  ({
    options,
    toggle_elevate_cluster_search,
  }: {
    options: GetSearchResultsApiOptions;
    toggle_elevate_cluster_search: boolean;
  }) =>
  (query: SearchPageQuery) => {
    const apiFunction = clientAndServerApiFunctionFactory(
      options,
      () =>
        elevateApiInstance(toggle_elevate_cluster_search).storefront
          .searchPagePOST
    );

    return apiFunction(query, {
      contentLists: prepareContentLists(query.limit),
    });
  };

const getLandingPageResults =
  ({
    options,
    toggle_elevate_cluster_landing_page,
  }: {
    options: GetLandingPageApiOptions;
    toggle_elevate_cluster_landing_page: boolean;
  }) =>
  async (
    query: LandingPageQuery,
    includeNavigation: boolean,
    initialData?: LandingPageRequestSettings
  ) => {
    const apiFunction = clientAndServerApiFunctionFactory(
      options,
      () =>
        elevateApiInstance(toggle_elevate_cluster_landing_page).storefront
          .landingPagePOST
    );

    return apiFunction(query, {
      navigation: {
        include: Boolean(includeNavigation),
      },
      ...(hasValue(initialData) && {
        primaryList: initialData.primaryList,
      }),
    });
  };

const getProductDiscountCount = async (
  site: Site,
  options: ApiOptions<getProductDiscountCountFunction>
) =>
  clientAndServerApiFunctionFactory(
    options,
    () => getProductApiInstance().getProductDiscountCount
  )(site);

export { getLandingPageResults, getProductDiscountCount, getSearchResults };
