import { IMAGE_SIZE } from "../constants";

const toFixedNumber = (n: number, fractionDigits = 2) =>
  Number(n.toFixed(fractionDigits));

export const createStylesConfig = (viewportWidth: number, count: number) => {
  const ITEM_WIDTH = IMAGE_SIZE.STYLE;
  const ITEM_GAP = 10;
  const PAGE_PADDING_X = 12 * 2;
  const GAP_OFFSET = 1;
  // calculating is slider needed
  const totalGapNeeded = ITEM_GAP * (count - GAP_OFFSET);
  const totalItemSpaceNeeded = count * ITEM_WIDTH;
  const totalUsableSpace = viewportWidth - PAGE_PADDING_X;
  const calculatedSpaceNeeded = totalItemSpaceNeeded + totalGapNeeded;
  const isSliderNeeded = calculatedSpaceNeeded > totalUsableSpace;

  // calculating how many items can fit per view
  const calculatedCount = toFixedNumber(totalUsableSpace / ITEM_WIDTH);
  const calculatedInViewGapTotal = toFixedNumber(
    (calculatedCount - GAP_OFFSET) * ITEM_GAP
  );
  const perViewTotal = toFixedNumber(
    (totalUsableSpace - calculatedInViewGapTotal) / ITEM_WIDTH
  );

  return [isSliderNeeded, perViewTotal] as const;
};
