import { useTracking } from "@/react-app/contexts/Tracking";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { useCartContext } from "@/react-components/Cart/CartState";
import { addMultipackBundleToCart } from "@/react-components/Cart/Services/addMultipackBundleToCart";
import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import type { Configuration } from "@/react-components/Product/ConfigurableProduct/ConfigurationModal/ConfigurationForm/types";
import { DeliveryInfo } from "@/react-components/Product/DeliveryInfo/DeliveryInfo";
import { UspMarquee } from "@/react-components/UspMarquee";
import { useAmplifyConfig } from "@/react-hooks/useAmplifyConfig";
import { useFeatureToggles } from "@/react-hooks/useFeatureToggle";
import { SALES_METHODS } from "@/react-hooks/useProductData/constants";
import { isProductFrom3rdParty } from "@/react-hooks/useProductData/useProductData.helper";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import type { PaymentWidgetData } from "@/utils/server-side-cache/server-side-cache";
import { hasNoValue, hasValue, isNotEmpty } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import type { ConfigurationData, ProductData } from "@xxl/product-search-api";
import { useRef, useState } from "react";
import { Browse } from "../Browse/Browse";
import { CampaignBanner } from "../CampaignBanner/CampaignBanner";
import { CncDialog } from "../Cnc/CncDialog";
import { PaymentProviderMessage } from "../PaymentProviderMessage/PaymentProviderMessage";
import { ProductFormContainer } from "../ProductForm/ProductFormContainer";
import { SizeGuide } from "../SizeGuide/SizeGuide";
import { StoreAvailability } from "../StoreAvailability";
import { StoreStock } from "../StoreStock/StoreStock";
import type { AddToCartArgs } from "../addToCart";
import { addToCart } from "../addToCart";
import { configurationAtc } from "../configurationAtc";
import { createTimerProps } from "../helpers";
import { useCartLoadingState } from "../hooks/useCartLoadingState";
import { useWalleyWidget } from "../hooks/useWalleyWidget";
import { useElevateBundleQuery } from "../queries/useElevateBundleQuery";
import { useGeoSortedStoresWithCollectableProductQuery } from "../queries/useStoresWithCollectableProductQuery";
import {
  CART_LOADING_STATE,
  type CampaignInfo,
  type PaymentProviderMessageConfig,
  type VolumentalMetaData,
} from "../types";
import { ConfigurationModalWrapper } from "./ConfigurationModalWrapper";

type Props = {
  hasRelatedGuides: boolean;
  hasServicePromotions: boolean;
  configurations: ConfigurationData[];
  campaignInfo?: CampaignInfo;
  isMiniPdp?: boolean;
  paymentProviderMessageConfig: PaymentProviderMessageConfig;
  paymentWidget: PaymentWidgetData | null;
  products: ProductData[];
  volumentalMetaData: VolumentalMetaData;
};
export const ProductPanel = ({
  campaignInfo,
  configurations,
  hasRelatedGuides,
  hasServicePromotions,
  isMiniPdp = false,
  paymentProviderMessageConfig,
  paymentWidget,
  products,
  volumentalMetaData,
}: Props) => {
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const [primaryProduct] = products;
  const { toggle_delivery_widget } = useFeatureToggles(
    "toggle_delivery_widget"
  );
  const { aws_appsync_apiKey, aws_appsync_graphqlEndpoint } =
    useAmplifyConfig();
  const trackers = useTracking();
  const { dispatch: cartDispatch } = useCartContext();
  const { data: bundleData } = useElevateBundleQuery({
    bundleConfiguration: primaryProduct.bundleConfiguration,
  });
  const walleyWidgetRef = useRef<HTMLDivElement>(null);

  const [isCncDialogOpen, setIsCncDialogOpen] = useState(false);
  const [hasPendingAddToCartAction, setHasPendingAddToCartAction] =
    useState(false);
  const toggleCncDialog = () => setIsCncDialogOpen((prev) => !prev);
  const [isVariantSelectOpen, setIsVariantSelectOpen] = useState(false);
  const toggleVariantSelectDrawer = () =>
    setIsVariantSelectOpen((prev) => !prev);
  const [selectedVariantCode, setSelectedVariantCode] = useState<string | null>(
    null
  );
  const [isConfigurationModalOpen, setIsConfigurationModalOpen] =
    useState(false);
  const toggleConfigurationModal = () => {
    if (isConfigurationModalOpen) {
      setIsConfigurationModalOpen(false);
      return;
    }

    if (selectedVariantCode === null) {
      setHasPendingAddToCartAction(true);
      toggleVariantSelectDrawer();
      return;
    }

    setIsConfigurationModalOpen(true);
  };

  const [cartLoadingState, setCartLoadingState] = useCartLoadingState();

  const isDangerousGoods =
    primaryProduct.salesMethodCode === SALES_METHODS.DANGEROUS_GOODS;
  const isMultiPackProduct = primaryProduct.type === "MULTIPACK";
  const {
    isGraveyard = false,
    isOnlyAvailableInStoreNoClickAndCollect = false,
    units,
  } = primaryProduct;

  const shouldShowDeliveryInfo =
    toggle_delivery_widget &&
    !isGraveyard &&
    !isOnlyAvailableInStoreNoClickAndCollect;

  const selectedVariantSizeCode =
    primaryProduct.variants.find((v) => v.code === selectedVariantCode)
      ?.sizeCode ?? null;

  const campaignTimerSettings = createTimerProps(
    campaignInfo?.timer ?? null,
    campaignInfo?.colorTheme.foreground
  );

  const { data: sortedStores = [] } =
    useGeoSortedStoresWithCollectableProductQuery(selectedVariantCode);

  const shouldShowWalleyWidget = useWalleyWidget(
    walleyWidgetRef,
    paymentWidget,
    primaryProduct.price.selling.range.min.value
  );

  const handleAtcClick = ({
    quantity,
    storeId,
    variantCode,
  }: AddToCartArgs & { variantCode?: string }) => {
    const code = variantCode ?? selectedVariantCode;
    if (hasNoValue(code)) {
      toggleVariantSelectDrawer();
      setHasPendingAddToCartAction(true);
      return;
    }
    if (storeId === undefined) {
      setCartLoadingState(CART_LOADING_STATE.ATC_LOADING);
    } else {
      setCartLoadingState(CART_LOADING_STATE.CNC_LOADING);
    }

    const variant = primaryProduct.variants.find((v) => v.code === code);
    try {
      if (variant === undefined) {
        throw new Error(
          "Error: No variant found when attempting to add to cart."
        );
      }
      const bundleEan = variant.code;
      const bundledProductEan = bundleData?.products[0].variants[0].code;
      if (
        isMultiPackProduct &&
        isNotEmpty(bundleEan) &&
        isNotEmpty(bundledProductEan)
      ) {
        void addMultipackBundleToCart({
          bundleEan,
          bundledProductEan,
          units,
          dispatch: cartDispatch,
          trackers,
          graphqlEndpoint: aws_appsync_graphqlEndpoint,
          graphqlApiKey: aws_appsync_apiKey,
          quantity,
          trackingListName: "ePDP",
        });
      } else {
        addToCart({
          p: primaryProduct,
          v: variant,
          storeId,
          quantity,
        });
      }
    } catch (e) {
      log.error(e);
    }
  };

  const handleConfAtc = (
    configs: Configuration,
    p: ProductData,
    variantCode: string | null,
    apiKey: string,
    apiEndpoint: string
  ) => {
    try {
      if (variantCode === null) {
        throw new Error("Error using handleConfAtc, no variantCode found");
      }
      const v = p.variants.find((variant) => variant.code === variantCode);
      if (v === undefined) {
        throw new Error("Error using handleConfAtc, no variant found");
      }
      void configurationAtc(configs, p, v, apiKey, apiEndpoint);
    } catch (e) {
      log.error(e);
    } finally {
      toggleConfigurationModal();
    }
    return Promise.resolve();
  };

  return (
    <>
      {!isMiniPdp && (
        <SizeGuide
          brandCode={primaryProduct.brand?.code}
          categoryCode={primaryProduct.categoryBreadcrumbs.map((c) => c.code)}
          photoshoot={null} // XD-16006: waiting on BE data
        />
      )}
      <div style={{ marginTop: xxlTheme.spaces.smallRegular }}>
        <ProductFormContainer
          hasPendingAddToCartAction={hasPendingAddToCartAction}
          product={primaryProduct}
          toggleCncDialog={toggleCncDialog}
          isCncDialogOpen={isCncDialogOpen}
          volumentalMetaData={volumentalMetaData}
          isVariantSelectOpen={isVariantSelectOpen}
          toggleVariantSelectDrawer={toggleVariantSelectDrawer}
          onVariantSelect={(props) => {
            const { action, variantCode } = props;
            setSelectedVariantCode(variantCode);
            setHasPendingAddToCartAction(false);
            switch (action) {
              case "select-variant-and-add-to-cart": {
                const { quantity } = props;
                handleAtcClick({ quantity, variantCode });
                break;
              }
              case "select-variant-and-open-configurations-modal":
                setIsConfigurationModalOpen(true);
                break;
            }
          }}
          onAtcClick={handleAtcClick}
          selectedVariantCode={selectedVariantCode}
          cartLoadingState={cartLoadingState}
          configurations={configurations}
          toggleConfigurationModal={toggleConfigurationModal}
        />
      </div>
      {hasValue(campaignInfo) && (
        <XxlStack mt={"12px"}>
          <CampaignBanner
            backgroundColor={campaignInfo.colorTheme.background}
            fontColor={campaignInfo.colorTheme.foreground}
            id={campaignInfo.id}
            timer={campaignTimerSettings}
            text={campaignInfo.description ?? ""}
            title={campaignInfo.title}
            slug={campaignInfo.slug}
          />
        </XxlStack>
      )}
      {!isMiniPdp && (
        <>
          <XxlStack display={isLaptopSize ? "none" : "block"}>
            <UspMarquee />
            <Browse
              hasDescription={Boolean(primaryProduct.description)}
              hasClassifications={primaryProduct.usps.length > 0}
              hasRelatedGuides={hasRelatedGuides}
              hasServicePromotions={hasServicePromotions}
            />
          </XxlStack>
          <XxlStack mt={"12px"} />
          <StoreAvailability
            product={primaryProduct}
            toggleIsCnCDialogOpen={toggleCncDialog}
            isOnlinePurchaseAllowed={!isDangerousGoods}
            selectedVariantCode={selectedVariantCode}
          />
          <XxlStack mt={"12px"} />
          <StoreStock selectedVariantCode={selectedVariantCode} />
          <XxlStack mt={"24px"} />
        </>
      )}
      {shouldShowDeliveryInfo && (
        <DeliveryInfo
          hasSidePadding={false}
          isProductFrom3rdParty={isProductFrom3rdParty(
            primaryProduct.salesMethodCode
          )}
          productCode={selectedVariantSizeCode}
        />
      )}
      {paymentProviderMessageConfig.isActive && !primaryProduct.isGraveyard ? (
        <>
          {shouldShowWalleyWidget ? (
            <>
              <XxlStack mt={"24px"} />
              <div ref={walleyWidgetRef} />
            </>
          ) : (
            <>
              <XxlStack mt={"24px"} />
              <PaymentProviderMessage
                locale={paymentProviderMessageConfig.locale}
                productSalesPrice={primaryProduct.price.selling.range.min.value}
              />
            </>
          )}
        </>
      ) : null}
      <CncDialog
        onAtcClick={handleAtcClick}
        toggleDialog={toggleCncDialog}
        isDialogOpen={isCncDialogOpen}
        stores={sortedStores}
      />
      <ConfigurationModalWrapper
        configurations={configurations}
        onClose={() => {
          setIsConfigurationModalOpen(false);
        }}
        onSubmit={(c: Configuration) =>
          handleConfAtc(
            c,
            primaryProduct,
            selectedVariantCode,
            aws_appsync_apiKey,
            aws_appsync_graphqlEndpoint
          )
        }
        open={isConfigurationModalOpen}
      />
    </>
  );
};
