import { useApiClients } from "react-app/src/contexts/ApiClients";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "./queryKeys";

export const useSizeGuidesQuery = (
  brandCode: string,
  categoryCodes: string[]
) => {
  const {
    nextFrontendApi: { getSizeGuide },
  } = useApiClients();

  const fetchSizeGuides = async () =>
    await getSizeGuide(brandCode, categoryCodes);

  return useQuery({
    queryKey: [QUERY_KEYS.SIZE_GUIDES],
    queryFn: fetchSizeGuides,
    staleTime: Infinity,
  });
};
