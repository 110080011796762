import * as React from "react";
import {
  Circle,
  CirclesContainer,
  ItemContainer,
  Line,
  SizeFitnessContainer,
  Text,
  TextContainer,
} from "./SizeFitnessSlider.styled";
import { CheckIcon } from "../common/CheckIcon/CheckIcon";
import type {
  OnChangeCallback,
  SizeFitnessOption,
} from "../../Common/SliderInput/SliderInputHelper";
import { SliderInputHelper } from "../../Common/SliderInput/SliderInputHelper";
import { Label } from "../../../styled/Label.styled";
import { SizeFitness } from "../../../generated/graphql-code-generator";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";

type SizeFitnessProps = {
  onChange?: OnChangeCallback;
};

const getSelections = (): readonly SizeFitnessOption[] => [
  {
    value: SizeFitness.SMALLER_FIT,
    labelTranslationKey: "product.ratings-and-reviews.size.fitness.small",
    ref: React.createRef<HTMLDivElement>(),
  },
  {
    value: SizeFitness.TRUE_TO_SIZE,
    labelTranslationKey: "product.ratings-and-reviews.size.fitness.perfect",
    ref: React.createRef<HTMLDivElement>(),
  },
  {
    value: SizeFitness.BIGGER_FIT,
    labelTranslationKey: "product.ratings-and-reviews.size.fitness.big",
    ref: React.createRef<HTMLDivElement>(),
  },
];

const SizeFitnessSlider: React.FunctionComponent<SizeFitnessProps> = ({
  onChange,
}) => {
  const { t } = useTranslations();
  const selections = getSelections();
  const {
    handleMouseEnter,
    handleMouseLeave,
    handleTouchEnd,
    handleMouseUp,
    handleTouchMove,
    isSelected,
    isHover,
  } = SliderInputHelper(selections, onChange);

  const productHasOneSize = Boolean(
    document
      .getElementById("js-product-size-select-list")
      ?.classList.contains("js-one-size-available")
  );
  return productHasOneSize ? (
    <></>
  ) : (
    <>
      <Label>{t("product.ratings-and-reviews.size.fitness.label")}</Label>
      <SizeFitnessContainer
        onTouchStart={handleTouchMove}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd(true)}
      >
        <CirclesContainer>
          <Line />
          {selections.map((item) => {
            return (
              <ItemContainer cursor={"pointer"} key={item.value} ref={item.ref}>
                <Circle
                  onMouseEnter={(): void => handleMouseEnter(item)}
                  onMouseLeave={handleMouseLeave}
                  onMouseUp={handleMouseUp(true)(item)}
                  isHovering={isHover(item)}
                  isSelected={isSelected(item)}
                  role="button"
                >
                  <CheckIcon noMargins={true} />
                </Circle>
              </ItemContainer>
            );
          })}
        </CirclesContainer>
        <TextContainer>
          {selections.map((item) => (
            <Text key={item.value}>{t(item.labelTranslationKey)}</Text>
          ))}
        </TextContainer>
      </SizeFitnessContainer>
    </>
  );
};

type ConditionalTextProps = {
  option: SizeFitnessOption;
  selectedFit: SizeFitness;
  mustShowLabel: boolean;
};
const ConditionalText: React.FunctionComponent<ConditionalTextProps> = ({
  option,
  selectedFit,
  mustShowLabel,
}) => {
  const { t } = useTranslations();
  const isSelected = Boolean(option.value === selectedFit);
  const isVisible = Boolean(isSelected || mustShowLabel);
  return <Text isOpaque={isVisible}>{t(option.labelTranslationKey)}</Text>;
};

type SizeFitnessStaticProps = {
  selectedFit: SizeFitness;
  padding?: string;
};

export const SizeFitnessStatic: React.FunctionComponent<
  SizeFitnessStaticProps
> = ({ selectedFit, padding }): JSX.Element => {
  const selections = React.useCallback(getSelections, [])();
  const [mustShowAllLabels, setShouldShowAllLabels] =
    React.useState<boolean>(false);

  return (
    <SizeFitnessContainer padding={padding}>
      <CirclesContainer
        onTouchStart={(): void => {
          setShouldShowAllLabels(!mustShowAllLabels);
        }}
        onMouseEnter={(): void => {
          setShouldShowAllLabels(true);
        }}
        onMouseLeave={(): void => {
          setShouldShowAllLabels(false);
        }}
        data-testid="size-fit-stat-circ-cont"
      >
        <Line />
        {selections.map((item) => {
          return (
            <ItemContainer cursor={"default"} key={item.value} ref={item.ref}>
              <Circle
                isHovering={false}
                isSelected={Boolean(item.value === selectedFit)}
              >
                <CheckIcon noMargins={true} />
              </Circle>
            </ItemContainer>
          );
        })}
      </CirclesContainer>
      <TextContainer>
        {selections.map((option) => (
          <ConditionalText
            key={option.value}
            option={option}
            selectedFit={selectedFit}
            mustShowLabel={mustShowAllLabels}
          />
        ))}
      </TextContainer>
    </SizeFitnessContainer>
  );
};

export { SizeFitnessSlider };
