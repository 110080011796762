import { Minus, Plus, Trash } from "@xxl/icons";
import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import ReactHtmlParser from "html-react-parser";
import { useSessionSource } from "../../../contexts/Session";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { type CartItemType } from "../../../generated/graphql-code-generator";
import type { TranslationKey } from "../../../translations";
import { getFrontendPriceDisplayData } from "../../../utils/PriceDisplay/price-display";
import { windowAccess } from "../../../utils/Window";
import { handleNumKeyDown } from "../../../utils/xxl-input";
import { HiddenText } from "../../Common";
import { ToggleButton } from "../../Common/Button/ToggleButton";
import { Ribbon } from "../../Product/Ribbon";
import {
  PaymentType,
  SUCCESS_RESPONSE_STATUS,
  updateProductsQuantity,
} from "../Api/CartAPI";
import type { CartItem as CartItemData } from "../Api/types";
import { TSSettingsList } from "./TSSettingsList";
import {
  CART_CONTENT_EDITING_LOCK,
  CART_EDITION_START,
  CART_EDITION_STOP,
  isKlarnaCheckoutSnippetLoaded,
  isWalley,
  LIST_HEIGHT_CHANGE,
  REMOVE_NOT_ENOUGH_QUANTITY,
  UPDATE_CART_CONTENT,
  useCartContext,
} from "../CartState";
import {
  DECREASE_ITEM_QUANTITY_BUTTON_TEST_ID,
  DEFAULT_QUANTITY_STRING,
  FULL_DESCRIPTION_TEST_ID,
  INCREASE_ITEM_QUANTITY_BUTTON_TEST_ID,
  ITEM_BRAND_TEST_ID,
  ITEM_IMAGE_TEST_ID,
  ITEM_NAME_TEST_ID,
  ITEM_QUANTITY_TEST_ID,
  REMOVE_ITEM_BUTTON_TEST_ID,
  TOGGLE_DESCRIPTION_BUTTON_TEST_ID,
} from "../constants";
import {
  handleTrackingClickProductInCart,
  handleTrackingIncreaseQuantity,
  handleTrackingRemoveProductFromCart,
} from "../Services/tracking";
import {
  parseQuantity,
  _updateCartQuantity,
} from "../Services/updateCartQuantity";
import { ErrorsList } from "../Styles/CartContent.styled";
import {
  Article,
  Brand,
  BrandAndName,
  ButtonsWrapper,
  ExpandableButtonsWrapper,
  ExpandableDescriptionWrapper,
  ExpandableWrapper,
  FullServiceDescription,
  Link,
  OutOfStockButton,
  OutOfStockButtonText,
  OutOfStockHeading,
  OutOfStockImage,
  OutOfStockListItem,
  OutOfStockOverlay,
  ProductColorAndSize,
  ProductDescriptionWrapper,
  ProductImage,
  ProductInfoAndPriceWrapper,
  ProductInfoWrapper,
  ProductSplash,
  QuantityButton,
  QuantityDisplay,
  QuantityWrapper,
  RemoveButton,
  RibbonWrapper,
  TeamBundleList,
  TeamBundleListItem,
  TeamBundleStrong,
  TeamBundleText,
  Warning,
  ServiceHeader,
  ServiceHeaderIcon,
  ServiceHeaderButton,
  DepositText,
  PriceWrapper,
  DepositInfoWrapper,
  DepositPrice,
  DepositTitle,
} from "../Styles/ProductItem.styled";
import { SettingsList } from "./SettingsList";
import { SmallProductPrice } from "./SmallProductPrice";
import { useSharedData } from "../../../contexts/SharedData";
import { isEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import { useTracking } from "../../../contexts/Tracking";
import {
  BOX_UNIT,
  PACKAGE_UNIT,
  PIECE_UNIT,
} from "../../Product/product-helper";
import { useStateValue } from "cotton-box-react";
import { formatPrice } from "@xxl/price-formatting-utils";
import { usePaymentProvider } from "../../../hooks/usePaymentProvider/usePaymentProvider";
import { getFileSpinUrl } from "../../../utils/getFilespinUrl";
import { log } from "@xxl/logging-utils";

export const ERROR_DISPLAY_TIMEOUT = 5000;

type CartItemProps = {
  item: CartItemData;
  servicesQuantity?: number;
  productQuantity?: number;
  onChange: (value: boolean) => void;
  onServiceEditClick?: () => void;
};

export const CartItem: React.FunctionComponent<CartItemProps> = ({
  item,
  onChange,
  onServiceEditClick,
  servicesQuantity,
  productQuantity,
}) => {
  const { state, dispatch } = useCartContext();
  const { t } = useTranslations();
  const isLoggedIn = useStateValue(useSessionSource);
  const trackers = useTracking();
  const { hasMiniCart, displayCart } = state;
  const [quantityInputValue, setQuantityInputValue] = useState<string>(
    isNotNullOrUndefined(item.quantity)
      ? item.quantity.toString()
      : DEFAULT_QUANTITY_STRING
  );
  const defaultPaymentProvider = usePaymentProvider(PaymentType.CHECKOUT);
  const quantityInputRef = React.useRef<HTMLInputElement>(null);
  const isTeamBundle = item.teamBundleSettings !== undefined;
  const hasSettings =
    (item.productSettings !== undefined && item.productSettings.length > 0) ||
    (isTeamBundle &&
      item.teamBundleSettings?.teamMembers !== undefined &&
      item.teamBundleSettings.teamMembers.length > 0);
  const hasBundleProducts =
    item.bundleProducts !== undefined && item.bundleProducts.length > 0;
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(
    hasBundleProducts || hasSettings
  );
  const { configuration, siteUid, siteCurrency } = useSharedData().data;
  const productListName =
    windowAccess()._sharedData.gtmData.productListName ?? "";
  const [graphQLEndpointUrl] = React.useState(
    configuration.amplifyConfig.aws_appsync_graphqlEndpoint
  );
  const [graphQLApiKey] = React.useState(
    configuration.amplifyConfig.aws_appsync_apiKey
  );
  const storeName =
    isNotNullOrUndefined(displayCart) && "collectStoreName" in displayCart
      ? displayCart.collectStoreName
      : undefined;
  const isService = item.type === "SERVICE";
  const isMultipack =
    hasBundleProducts && (item.unit === BOX_UNIT || item.unit === PACKAGE_UNIT);
  const nbsp = String.fromCharCode(160);
  const depositValues =
    isMultipack &&
    isNotNullOrUndefined(item.bundledProducts) &&
    item.bundledProducts.length === 2
      ? item.bundledProducts[1]
      : undefined;
  const depositText = isNotNullOrUndefined(depositValues)
    ? `${depositValues.name} x${nbsp}${String(depositValues.quantity)}`
    : undefined;
  const depositPrice =
    isNotNullOrUndefined(depositValues) &&
    isNotNullOrUndefined(depositValues.price)
      ? `${formatPrice(depositValues.price, siteCurrency)}`
      : undefined;
  const showSpecifications =
    !isMultipack &&
    (hasBundleProducts ||
      hasSettings ||
      (isService &&
        item.description !== undefined &&
        item.description.length > 0));
  const hasErrors = Boolean(
    item.errors !== undefined && item.errors.length > 0
  );
  const ean = isNotNullOrUndefined(item.ean) ? item.ean[0] : undefined;
  const unit = isNotNullOrUndefined(item.unit) ? item.unit : PIECE_UNIT;

  const [showNotEnoughError, setShowNotEnoughError] = useState(false);
  if (item.displayPrice === undefined) {
    throw Error("Missing display price cart");
  }
  const transformedPrice = getFrontendPriceDisplayData({
    priceDisplay: item.displayPrice,
    t,
    isLoggedIn,
    siteUid,
  });

  const hasProductSizeOrColor =
    item.selectedSize !== undefined || item.selectedColor !== undefined;
  const _removeFromCart = async (): Promise<void> => {
    try {
      if (!hasMiniCart) {
        let checkoutSuspended = false;
        if (isKlarnaCheckoutSnippetLoaded()) {
          window._klarnaCheckout((api) => {
            api.suspend({
              autoResume: {
                enabled: false,
              },
            });
          });
          checkoutSuspended = true;
        }
        if (isWalley(state.paymentProvider)) {
          dispatch({
            type: CART_CONTENT_EDITING_LOCK,
          });
          window.walley?.checkout.api.suspend();
          checkoutSuspended = true;
        }
        if (checkoutSuspended) {
          dispatch({
            type: CART_CONTENT_EDITING_LOCK,
          });
        }
      }
      const itemId = {
        id: item.entryNumber,
        type: item.type as CartItemType,
      };

      const ApiResponse = await updateProductsQuantity(
        "0",
        itemId,
        configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
        graphQLApiKey,
        ean,
        unit
      );

      if (ApiResponse.status === SUCCESS_RESPONSE_STATUS) {
        handleTrackingRemoveProductFromCart(
          item,
          transformedPrice.salesPrice ?? "",
          transformedPrice.otherPriceType ?? "unknown",
          trackers
        );

        dispatch({
          type: UPDATE_CART_CONTENT,
        });
        dispatch({
          type: CART_EDITION_STOP,
        });
        setTimeout(() => {
          dispatch({
            type: LIST_HEIGHT_CHANGE,
          });
        }, 0);
      }
    } catch (err) {
      log.error("Cannot remove item from cart", err);
    }
  };

  const handleIncreaseQuantity = () => {
    if (item.quantity !== undefined) {
      onChange(true);
      handleTrackingIncreaseQuantity(
        item,
        transformedPrice.salesPrice ?? "",
        transformedPrice.otherPriceType ?? "unknown",
        productListName,
        trackers
      );
      void _updateCartQuantity({
        quantity: (item.quantity += 1),
        entryNumber:
          isMultipack && isNotNullOrUndefined(item.bundleItemId)
            ? item.bundleItemId.id
            : item.entryNumber,
        entryType:
          isMultipack && isNotNullOrUndefined(item.bundleItemId)
            ? item.bundleItemId.type
            : item.type,
        dispatch,
        hasMiniCart,
        hasErrors,
        isServicesModalOpened: false,
        graphQLEndpointUrl,
        graphQLApiKey,
        deliveryPointOfServiceName: item.selectedStore?.name,
        ean,
        unit,
        paymentProvider: state.paymentProvider ?? defaultPaymentProvider,
      });
    }
  };

  const changeQuantity = (quantity: number) => {
    if (quantity < 0) {
      return;
    }

    onChange(true);
    handleTrackingRemoveProductFromCart(
      item,
      transformedPrice.salesPrice ?? "",
      transformedPrice.otherPriceType ?? "unknown",
      trackers
    );
    void _updateCartQuantity({
      quantity,
      entryNumber:
        isMultipack && isNotNullOrUndefined(item.bundleItemId)
          ? item.bundleItemId.id
          : item.entryNumber,
      entryType:
        isMultipack && isNotNullOrUndefined(item.bundleItemId)
          ? item.bundleItemId.type
          : item.type,
      dispatch,
      hasMiniCart,
      hasErrors,
      isServicesModalOpened: false,
      graphQLEndpointUrl,
      graphQLApiKey,
      deliveryPointOfServiceName: item.selectedStore?.name,
      ean,
      unit,
      paymentProvider: state.paymentProvider ?? defaultPaymentProvider,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuantityInputValue(e.target.value);

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = parseQuantity(e.target.value);
    setQuantityInputValue(quantity.toString());
    changeQuantity(quantity);
  };

  const decreaseQuantity = () => {
    if (item.quantity !== undefined && item.quantity > 1) {
      onChange(true);
      handleTrackingRemoveProductFromCart(
        item,
        transformedPrice.salesPrice ?? "",
        transformedPrice.otherPriceType ?? "unknown",
        trackers
      );
      void _updateCartQuantity({
        quantity: (item.quantity -= 1),
        entryNumber:
          isMultipack && isNotNullOrUndefined(item.bundleItemId)
            ? item.bundleItemId.id
            : item.entryNumber,
        entryType:
          isMultipack && isNotNullOrUndefined(item.bundleItemId)
            ? item.bundleItemId.type
            : item.type,
        dispatch,
        hasMiniCart,
        hasErrors,
        isServicesModalOpened: false,
        graphQLEndpointUrl,
        graphQLApiKey,
        deliveryPointOfServiceName: item.selectedStore?.name,
        ean,
        unit,
        paymentProvider: state.paymentProvider ?? defaultPaymentProvider,
      });
    }
  };

  const toggleDescription = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
  };

  const handleProductClick = () => {
    handleTrackingClickProductInCart(
      item,
      transformedPrice.salesPrice ?? "",
      transformedPrice.otherPriceType ?? "unknown",
      productListName,
      trackers
    );
  };

  const deleteTrigger = (availableStockAmount?: number) => {
    onChange(true);
    dispatch({
      type: CART_EDITION_START,
    });
    if (
      isNotNullOrUndefined(availableStockAmount) &&
      availableStockAmount > 0
    ) {
      void changeQuantity(availableStockAmount);
    } else {
      void _removeFromCart();
    }
  };

  useEffect(() => {
    setQuantityInputValue(
      isNotNullOrUndefined(item.quantity)
        ? item.quantity.toString()
        : DEFAULT_QUANTITY_STRING
    );
  }, [item.quantity]);

  useEffect(() => {
    if (state.displayCart?.totalPriceAsInteger !== undefined)
      windowAccess().KlarnaPlacementWidget?.updatePrice(
        state.displayCart.totalPriceAsInteger
      );
  }, [state.displayCart?.totalPriceAsInteger]);

  useEffect(() => {
    if (
      state.notEnoughQuantityEntryNumber !== undefined &&
      state.notEnoughQuantityEntryNumber === item.entryNumber &&
      !state.isCartContentLocked
    ) {
      setShowNotEnoughError(true);
      setTimeout(() => {
        dispatch({
          type: REMOVE_NOT_ENOUGH_QUANTITY,
        });
        setShowNotEnoughError(false);
      }, ERROR_DISPLAY_TIMEOUT);
    }
  }, [
    state.notEnoughQuantityEntryNumber,
    item.entryNumber,
    dispatch,
    state.isCartContentLocked,
  ]);

  const isSimpleProduct =
    isMultipack ||
    (item.quantity !== undefined &&
      item.quantity !== 0 &&
      item.isBundleService !== true &&
      !hasSettings &&
      item.type !== "BUNDLE");

  const imgSrc = getFileSpinUrl({
    baseUrl: `${window.location.origin}${item.primaryImage}`,
    width: 120,
    height: 120,
  });

  const getCollectWarningArgs = (
    storeName: string,
    collectWarning?: TranslationKey,
    collectStock?: number
  ) => {
    switch (collectWarning) {
      case "cart.collect.warning.LOCAL_PARTIAL_STOCK":
        return [
          collectStock !== undefined ? collectStock.toString() : "",
          storeName,
        ];
      default:
        return [storeName];
    }
  };

  return (
    <Article
      data-testid={`cart-entry-${String(item.selectedStyle)}`}
      isService={isService}
    >
      {isService && (
        <ServiceHeader>
          <ServiceHeaderIcon />
          {t(`service.product.card.header`)}
          <ServiceHeaderButton onClick={onServiceEditClick}>
            {t("general.edit")}
          </ServiceHeaderButton>
        </ServiceHeader>
      )}
      {item.errors !== undefined && item.errors.length > 0 && (
        <OutOfStockOverlay>
          <ErrorsList>
            {item.errors.map((error, index) => (
              <OutOfStockListItem key={`item-error-${index}`}>
                <OutOfStockHeading>
                  {t(error as TranslationKey)}
                </OutOfStockHeading>
              </OutOfStockListItem>
            ))}
          </ErrorsList>
          <OutOfStockButton
            type="button"
            onClick={() => {
              deleteTrigger(item.maxCartStockAvailable);
            }}
          >
            <OutOfStockImage>
              <Trash />
            </OutOfStockImage>
            <OutOfStockButtonText>
              {isNotNullOrUndefined(item.maxCartStockAvailable) &&
              item.maxCartStockAvailable > 0
                ? t("cart.entry.update.available.quantity")
                : t("general.remove")}
            </OutOfStockButtonText>
          </OutOfStockButton>
        </OutOfStockOverlay>
      )}
      {showNotEnoughError && (
        <OutOfStockOverlay>
          <OutOfStockHeading>
            {t("cart.entry.not.enough.stock")}
          </OutOfStockHeading>
        </OutOfStockOverlay>
      )}
      {(item.ribbon !== undefined || item.splashInfo !== undefined) && (
        <RibbonWrapper>
          {item.splashInfo !== undefined && (
            <ProductSplash>
              {t(item.splashInfo as TranslationKey)}
            </ProductSplash>
          )}
          {item.ribbon !== undefined && <Ribbon {...item.ribbon} />}
        </RibbonWrapper>
      )}
      <ProductInfoAndPriceWrapper isService={isService}>
        <ProductInfoWrapper>
          {!isService && (
            <Link href={item.url} onClick={handleProductClick}>
              <ProductImage
                data-private={true}
                data-testid={ITEM_IMAGE_TEST_ID}
                src={imgSrc}
                alt={item.title}
              />
            </Link>
          )}
          <ProductDescriptionWrapper>
            <BrandAndName data-testid={ITEM_NAME_TEST_ID}>
              {item.brand !== undefined && (
                <Brand data-testid={ITEM_BRAND_TEST_ID}>{item.brand}</Brand>
              )}
              &nbsp;
              {item.title}
            </BrandAndName>
            {hasProductSizeOrColor && (
              <ProductColorAndSize>
                {item.selectedColor !== undefined && item.selectedColor.name}
                {item.selectedSize !== undefined &&
                  item.selectedColor !== undefined && <>&nbsp;|&nbsp;</>}
                {item.selectedSize !== undefined
                  ? item.unit !== undefined
                    ? t(`product.unit.amount.${unit}` as TranslationKey)
                    : item.selectedSize
                  : item.selectedSize}
              </ProductColorAndSize>
            )}
            {isTeamBundle && (
              <>
                {item.teamBundleSettings?.bundleSettings !== undefined &&
                  item.teamBundleSettings.bundleSettings.length > 0 && (
                    <TeamBundleList>
                      {item.teamBundleSettings.bundleSettings.map(
                        (setting, index) => (
                          <TeamBundleListItem
                            key={`team-bundle-setting-${index}`}
                          >
                            <TeamBundleText>
                              <TeamBundleStrong>
                                {setting.name}:&nbsp;
                              </TeamBundleStrong>
                              {setting.text}
                            </TeamBundleText>
                          </TeamBundleListItem>
                        )
                      )}
                    </TeamBundleList>
                  )}
                {item.teamBundleSettings?.bundleQuantity !== undefined &&
                  item.teamBundleSettings.bundleQuantity.length > 0 && (
                    <TeamBundleList>
                      {item.teamBundleSettings.bundleQuantity.map(
                        (quantityItem, index) => (
                          <TeamBundleListItem
                            key={`team-bundle-quantity-${index}`}
                          >
                            <TeamBundleText>
                              <TeamBundleStrong>
                                {t("cart.size")}:&nbsp;
                              </TeamBundleStrong>
                              {quantityItem.size}
                              &nbsp;|&nbsp;
                              <TeamBundleStrong>
                                {t("cart.quantity")}:&nbsp;
                              </TeamBundleStrong>
                              {quantityItem.quantity}
                            </TeamBundleText>
                            {quantityItem.printConfigurations !== undefined && (
                              <TSSettingsList
                                settingsArray={quantityItem.printConfigurations}
                              />
                            )}
                          </TeamBundleListItem>
                        )
                      )}
                    </TeamBundleList>
                  )}
              </>
            )}
            {showSpecifications === true && (
              <ExpandableWrapper>
                <ExpandableButtonsWrapper>
                  <ToggleButton
                    testid={TOGGLE_DESCRIPTION_BUTTON_TEST_ID}
                    onClick={toggleDescription}
                    isToggled={isDescriptionVisible}
                  >
                    {isTeamBundle
                      ? t("cart.team.bundle.members")
                      : isService
                        ? t(isDescriptionVisible ? "read.less" : "read.more")
                        : t("cart.bundles.settings.show")}
                  </ToggleButton>
                </ExpandableButtonsWrapper>
                <AnimateHeight
                  height={isDescriptionVisible ? "auto" : 0}
                  onAnimationEnd={() => {
                    dispatch({
                      type: LIST_HEIGHT_CHANGE,
                    });
                  }}
                >
                  <ExpandableDescriptionWrapper
                    data-testid={FULL_DESCRIPTION_TEST_ID}
                  >
                    {isService && (
                      <FullServiceDescription>
                        {ReactHtmlParser(item.description as string)}
                      </FullServiceDescription>
                    )}
                    {hasBundleProducts && item.bundleProducts !== undefined && (
                      <SettingsList
                        list={item.bundleProducts}
                        title={t("cart.bundles.title")}
                      />
                    )}
                    {hasSettings &&
                      item.productSettings !== undefined &&
                      !isTeamBundle && (
                        <SettingsList
                          list={item.productSettings}
                          title={t("cart.bundles.settings")}
                        />
                      )}
                    {isTeamBundle && (
                      <TeamBundleList>
                        {item.teamBundleSettings?.teamMembers.map(
                          (teamMember) => (
                            <TeamBundleListItem
                              key={`team-member-${teamMember.number}`}
                            >
                              <TeamBundleText>
                                {!isEmpty(teamMember.firstname) && (
                                  <>{teamMember.firstname},&nbsp;</>
                                )}
                                {!isEmpty(teamMember.lastname) && (
                                  <>{teamMember.lastname},&nbsp;</>
                                )}
                                {!isEmpty(teamMember.initials) && (
                                  <>{teamMember.initials},&nbsp;</>
                                )}
                                {!isEmpty(teamMember.number) && (
                                  <>{teamMember.number},&nbsp;</>
                                )}
                                {!isEmpty(teamMember.selectedSize) && (
                                  <>{teamMember.selectedSize}</>
                                )}
                              </TeamBundleText>
                            </TeamBundleListItem>
                          )
                        )}
                      </TeamBundleList>
                    )}
                  </ExpandableDescriptionWrapper>
                </AnimateHeight>
              </ExpandableWrapper>
            )}
          </ProductDescriptionWrapper>
        </ProductInfoWrapper>
        <SmallProductPrice
          priceDisplay={item.displayPrice}
          productType={null}
        />
      </ProductInfoAndPriceWrapper>
      {isNotNullOrUndefined(depositText) && (
        <DepositInfoWrapper>
          <DepositTitle>{t("cart.multipack.deposit.title")}</DepositTitle>
          <ProductInfoAndPriceWrapper isService={false}>
            <DepositText>{depositText}</DepositText>
            {isNotNullOrUndefined(depositPrice) && (
              <PriceWrapper>
                <DepositPrice>{depositPrice}</DepositPrice>
              </PriceWrapper>
            )}
          </ProductInfoAndPriceWrapper>
        </DepositInfoWrapper>
      )}
      <ButtonsWrapper isService={isService}>
        <RemoveButton
          data-testid={REMOVE_ITEM_BUTTON_TEST_ID}
          type="button"
          onClick={() => {
            deleteTrigger();
          }}
        >
          <HiddenText text={t("cart.remove.product")} />
          <Trash />
        </RemoveButton>
        {isSimpleProduct && (
          <QuantityWrapper>
            <QuantityButton
              data-testid={DECREASE_ITEM_QUANTITY_BUTTON_TEST_ID}
              type="button"
              disabled={item.quantity !== undefined && item.quantity <= 1}
              onClick={decreaseQuantity}
            >
              <Minus fontSize={15} />
            </QuantityButton>
            <QuantityDisplay
              value={quantityInputValue}
              onChange={handleChange}
              onKeyDown={(e) => handleNumKeyDown(e, quantityInputRef)}
              onBlur={handleBlur}
              ref={quantityInputRef}
              data-testid={ITEM_QUANTITY_TEST_ID}
              type="number"
            />
            <QuantityButton
              data-testid={INCREASE_ITEM_QUANTITY_BUTTON_TEST_ID}
              type="button"
              onClick={handleIncreaseQuantity}
              disabled={Boolean(
                isService &&
                  isNotNullOrUndefined(servicesQuantity) &&
                  isNotNullOrUndefined(productQuantity) &&
                  servicesQuantity >= productQuantity
              )}
            >
              <Plus fontSize={15} />
            </QuantityButton>
          </QuantityWrapper>
        )}
      </ButtonsWrapper>
      {!isEmpty(item.collectWarning) && (
        <Warning>
          {isNotNullOrUndefined(storeName)
            ? t({
                key: `${item.collectWarning as TranslationKey}`,
                messageArguments: getCollectWarningArgs(
                  storeName,
                  item.collectWarning as TranslationKey,
                  item.collectStock
                ),
              })
            : `${item.collectWarning as TranslationKey}`}
        </Warning>
      )}
    </Article>
  );
};
