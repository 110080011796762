import * as React from "react";
import ReactHtmlParser from "html-react-parser";
import stringFormat from "string-format";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { StarsDisplay } from "../AverageRating/StarsDisplay";
import { CheckIcon } from "../common/CheckIcon/CheckIcon";
import { SizeFitnessStatic } from "../SizeFitnessSlider/SizeFitnessSlider";
import { HelpfulnessVote } from "./HelpfulnessVote";
import type { ReviewProps } from "./Reviews";
import {
  getFormattedDate,
  languageCodeToTranslationKeyMap,
  supportedLanguages,
} from "./Reviews";
import {
  MainContainer,
  RatingInfo,
  Recommended,
  SizeFitnessContainerReviews,
  StarsAndName,
  Text,
  TextAndSizeFitness,
  TextDescription,
  TranslationInfo,
} from "./Reviews.styled";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export const Review: React.FunctionComponent<ReviewProps> = ({
  review,
  translation,
}) => {
  const { t } = useTranslations();
  const [translationInfoText, setTranslationInfoText] = React.useState<
    string | null
  >(null);

  React.useEffect(() => {
    if (isNotNullOrUndefined(translation)) {
      const { sourceLanguage } = translation;
      const isSupportedLanguage = supportedLanguages.includes(sourceLanguage);
      const fallbackText = t("product.ratings-and-reviews.translated.auto");

      isSupportedLanguage
        ? setTranslationInfoText(
            t(languageCodeToTranslationKeyMap[sourceLanguage])
          )
        : setTranslationInfoText(fallbackText);
    }
  }, [translation, t]);

  return (
    <MainContainer data-testid="rr-review">
      <RatingInfo>
        <StarsAndName>
          <StarsDisplay
            rating={review.rating}
            size="xs"
            useBottomMargin={false}
          ></StarsDisplay>
          <p>{stringFormat(t("general.by.x"), review.displayName)}</p>
        </StarsAndName>
        <p>{getFormattedDate(review.creationDate)}</p>
      </RatingInfo>
      <TextAndSizeFitness>
        <Text>
          <h3>{translation?.headline ?? review.headline}</h3>
          <TextDescription>
            &#8220;{translation?.text ?? review.text}&#8221;
          </TextDescription>
          {translationInfoText !== null && (
            <TranslationInfo>&#40;{translationInfoText}&#41;</TranslationInfo>
          )}
          {review.isArticleRecommended ? (
            <Recommended>
              <CheckIcon isSmall={true} />
              {ReactHtmlParser(
                t("product.ratings-and-reviews.read.would.recommend")
              )}
            </Recommended>
          ) : null}
        </Text>
        {typeof review.sizeFitness !== "undefined" &&
        review.sizeFitness !== null ? (
          <SizeFitnessContainerReviews data-testid="rr-size-fit">
            <SizeFitnessStatic
              selectedFit={review.sizeFitness}
              padding={"20px 0 0"}
            />
          </SizeFitnessContainerReviews>
        ) : null}
      </TextAndSizeFitness>
      <HelpfulnessVote
        reviewId={review.id}
        helpfulVoteCount={review.helpfulVoteCount}
        unhelpfulVoteCount={review.unhelpfulVoteCount}
      />
    </MainContainer>
  );
};
