import * as React from "react";
import { Paragraph } from "../DeliveryOptions.styled";
import stringFormat from "string-format";
import styled from "@emotion/styled/macro";
import { useTranslations } from "../../../../../contexts/Translations/TranslationsContext";

export const Time = styled.p`
  width: 100%;
`;

type DeliveryCostAndTimeProps = {
  currency: string;
  price: number;
  deliveryEarliest?: string;
  deliveryLatest?: string;
};

const createDeliveryTimeMessage = (
  earliest: string,
  latest: string,
  tomorrowMessage: string,
  deliveryTimeMessage: string
): string => {
  const canDeliverTomorrow = Boolean(earliest === "1" && latest === "1");
  const separator = " - ";
  const deliverLaterMessage = stringFormat(
    deliveryTimeMessage,
    earliest,
    latest
  );
  return canDeliverTomorrow
    ? `${separator}${tomorrowMessage}`
    : `${separator}${deliverLaterMessage}`;
};

const createPriceMessage = (
  price: number,
  currency: string,
  freeMessage: string
): string => {
  const isFreeShipping = Boolean(price === 0);
  return isFreeShipping ? freeMessage : `${price} ${currency}`;
};

export const DeliveryCostAndTime: React.FunctionComponent<
  DeliveryCostAndTimeProps
> = ({ currency, deliveryEarliest, deliveryLatest, price }) => {
  const { t } = useTranslations();

  return (
    <Paragraph>
      {createPriceMessage(price, currency, t("product.details.delivery.free"))}
      {deliveryEarliest !== undefined &&
        deliveryLatest !== undefined &&
        createDeliveryTimeMessage(
          deliveryEarliest,
          deliveryLatest,
          t("general.tomorrow"),
          t("product.details.delivery.time")
        )}
    </Paragraph>
  );
};
