import { type StockLevel } from "../../../types";
import type { CncInfo } from "../../StoreStock.helper";
import { StoreStockListItemName } from "../../StoreStockListItemName";
import { StoreStockLevelElement } from "../../StoreStockStatus";
import { Messages } from "../../StoreStockStyles";

type HeaderProps = {
  displayName: string;
  expandedStoreId: string;
  stockLevel: StockLevel;
  storeId: string;
  cncInfo?: CncInfo;
};
const Header = ({
  cncInfo,
  displayName,
  expandedStoreId,
  stockLevel,
  storeId,
}: HeaderProps) => {
  return (
    <>
      <StoreStockListItemName
        id={storeId}
        checked={storeId === expandedStoreId}
        name={displayName}
      />
      <StoreStockLevelElement stockLevel={stockLevel} />
      {cncInfo !== undefined && (
        <Messages isVisible={storeId === expandedStoreId}>
          <p>{cncInfo.pickupTimeMessage}</p>
          <p>{cncInfo.specialOpeningHoursMessage}</p>
        </Messages>
      )}
    </>
  );
};

export { Header };
