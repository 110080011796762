import { typographyToCss } from "@/react-app/styles/helpers";
import styled from "@emotion/styled/macro";

export const InputWrapper = styled.span`
  ${typographyToCss({
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  })};

  && label {
    ${typographyToCss({
      fontSize: 15,
      lineHeight: 1,
    })};
    color: currentColor;
    padding-top: 2px;
  }
`;

export const StoreLabel = styled.label`
  && {
    margin: 0;
  }
`;
