import { setStorageItem } from "../../../utils/LocalStorage/local-storage";
import { getXXLCookie } from "../../../utils/Cookie";
import type { HelpfulnessVotesType } from "../Api/RatingsAndReviewsAPI";
import {
  HelpfulnessVotes,
  markReviewHelpful,
  markReviewUnhelpful,
  unmarkReviewHelpfulness,
} from "../Api/RatingsAndReviewsAPI";
import type { HelpfulnessVotesCountProp } from "./HelpfulnessVote";
import { log } from "@xxl/logging-utils";

export type UpdateServerWithNewHelpfulnessVoteProps = {
  helpfulnessVote: HelpfulnessVotesType;
  reviewId: string;
};

export const getSessionStorageKey = (reviewId: string): string =>
  `RatingsAndReviewsHelpfulnessVote-${reviewId}`;

export const updateServerWithNewHelpfulnessVote = async (
  { helpfulnessVote, reviewId }: UpdateServerWithNewHelpfulnessVoteProps,
  aws_appsync_apiKey: string,
  aws_appsync_graphqlEndpoint: string
): Promise<void> => {
  const xxlCookie = await getXXLCookie();
  if (xxlCookie === null || xxlCookie.sessionId === undefined) {
    throw new Error("Missing session id");
  }
  const mutationInput = {
    reviewId: reviewId,
    sessionId: xxlCookie.sessionId,
  };
  try {
    switch (helpfulnessVote) {
      case HelpfulnessVotes.HELPFUL:
        await markReviewHelpful(
          mutationInput,
          aws_appsync_apiKey,
          aws_appsync_graphqlEndpoint
        );
        break;
      case HelpfulnessVotes.UNHELPFUL:
        await markReviewUnhelpful(
          mutationInput,
          aws_appsync_apiKey,
          aws_appsync_graphqlEndpoint
        );
        break;
      default:
        await unmarkReviewHelpfulness(
          mutationInput,
          aws_appsync_apiKey,
          aws_appsync_graphqlEndpoint
        );
    }
  } catch (error) {
    log.error("Error updating server with new helpfulness vote", error);
  }
};

export const updateClientWithNewHelpfulnessVote = ({
  helpfulnessVote,
  reviewId,
}: UpdateServerWithNewHelpfulnessVoteProps): void => {
  const sessionStorageKey = getSessionStorageKey(reviewId);
  if (helpfulnessVote !== null) {
    setStorageItem(sessionStorageKey, helpfulnessVote);
  } else {
    window.sessionStorage.removeItem(sessionStorageKey);
  }
};

type CalculateHelpfulnessVotesProps = {
  newHelpfulnessVote: HelpfulnessVotesType;
  currentHelpfulnessVote: HelpfulnessVotesType;
  helpfulnessVotesCount: HelpfulnessVotesCountProp;
};

export const calculateHelpfulnessVotes = ({
  newHelpfulnessVote,
  currentHelpfulnessVote,
  helpfulnessVotesCount,
}: CalculateHelpfulnessVotesProps): HelpfulnessVotesCountProp => {
  const { helpfulCount, unhelpfulCount } = helpfulnessVotesCount;
  switch (newHelpfulnessVote) {
    case HelpfulnessVotes.HELPFUL:
      return {
        helpfulCount: helpfulCount + 1,
        unhelpfulCount:
          currentHelpfulnessVote === HelpfulnessVotes.UNHELPFUL
            ? unhelpfulCount - 1
            : unhelpfulCount,
      };
    case HelpfulnessVotes.UNHELPFUL:
      return {
        helpfulCount:
          currentHelpfulnessVote === HelpfulnessVotes.HELPFUL
            ? helpfulCount - 1
            : helpfulCount,
        unhelpfulCount: unhelpfulCount + 1,
      };
    default:
      switch (currentHelpfulnessVote) {
        case HelpfulnessVotes.HELPFUL:
          return { helpfulCount: helpfulCount - 1, unhelpfulCount };
        case HelpfulnessVotes.UNHELPFUL:
          return { helpfulCount, unhelpfulCount: unhelpfulCount - 1 };
        default:
          return { helpfulCount, unhelpfulCount };
      }
  }
};

export const getClientStoredHelpfulnessVote = (
  sessionStorageKey: string
): HelpfulnessVotesType => {
  switch (window.sessionStorage.getItem(sessionStorageKey)) {
    case HelpfulnessVotes.HELPFUL.toString():
      return HelpfulnessVotes.HELPFUL;
    case HelpfulnessVotes.UNHELPFUL.toString():
      return HelpfulnessVotes.UNHELPFUL;
    default:
      return null;
  }
};
