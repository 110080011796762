import styled from "@emotion/styled/macro";
import { MQ } from "../../styles/helpers";
import spaces from "../../styles/theme/spaces";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 110px;

  ${MQ("tablet")} {
    flex-direction: row;
    height: 54px;
  }
`;

export const StyledText = styled.p`
  margin: ${spaces.smallRegular} 0 ${spaces.smallRegular};
  line-height: 1;

  button {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-family: inherit;
    line-height: 1;
    background-color: transparent;
    border: 0;
    outline: none;
    text-decoration: underline;
    cursor: pointer;

    &:active {
      border: 0;
    }

    &:focus {
      outline: unset;
    }
  }
`;
