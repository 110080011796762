import { ThumbnailArrows } from "./ThumbnailArrows";
import { ThumbnailSlider, type ThumbnailSliderProps } from "./ThumbnailSlider";

type ThumbnailsSliderProps = ThumbnailSliderProps & {
  handleNextClick: () => void;
  handlePreviousClick: () => void;
};

export const ThumbnailsSlider = ({
  currentSlide,
  handleNextClick,
  handlePreviousClick,
  instanceRef,
  productImages,
}: ThumbnailsSliderProps) => (
  <ThumbnailArrows
    currentSlide={currentSlide}
    handleNextClick={handleNextClick}
    handlePreviousClick={handlePreviousClick}
    sliderLength={productImages.length - 1}
  >
    <ThumbnailSlider
      currentSlide={currentSlide}
      instanceRef={instanceRef}
      productImages={productImages}
    />
  </ThumbnailArrows>
);
