import type { PropsWithChildren } from "react";
import { forwardRef } from "react";
import { KEEN_SLIDER_CLASSES } from "react-app/src/components/Common/Slider/Slider.helper";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { mobileTabletAndLaptopMediaQuery } from "react-app/src/utils/xxl-screen";
import { NextImage } from "../../common/NextImage/NextImage";
import { IMAGE_SIZE } from "../constants";
import {
  PrimaryImageSlide,
  ProductImageSliderWrapperDesktop,
} from "./ProductImage.styles";
import { LastItemWrapper, UpsaleLinkComponent } from "./UpsaleLinkComponent";

type ProductImagesProps = {
  handleClickOpen: (img: string) => void;
  isLastItem: boolean;
  productImages: string[];
  productName: string;
  shouldShowUpsaleLink: boolean;
};

const ProductImages = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ProductImagesProps>
>(
  (
    {
      children,
      handleClickOpen,
      isLastItem,
      productImages,
      productName,
      // shouldShowUpsaleLink, https://xxlsports.atlassian.net/browse/XD-16674
    },
    sliderRef
  ) => {
    const { t } = useTranslations();
    return (
      <ProductImageSliderWrapperDesktop>
        {children}
        <div ref={sliderRef} className={KEEN_SLIDER_CLASSES.Slider}>
          {productImages.map((productImage, idx) => (
            <div key={productImage} className={KEEN_SLIDER_CLASSES.Slide}>
              <PrimaryImageSlide>
                <NextImage
                  src={productImage}
                  width={IMAGE_SIZE.DESKTOP}
                  height={IMAGE_SIZE.DESKTOP}
                  onClick={() => handleClickOpen(productImage)}
                  priority={idx === 0}
                  alt={`${productName} - ${t(
                    "product.details.alt.image.full"
                  )} ${idx + 1}`}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  sizes={`${mobileTabletAndLaptopMediaQuery} 75vw, 500px`}
                />
              </PrimaryImageSlide>
            </div>
          ))}
          {/* {shouldShowUpsaleLink && ( */}
          <LastItemWrapper isLastItem={isLastItem}>
            <UpsaleLinkComponent />
          </LastItemWrapper>
          {/* )} */}
        </div>
      </ProductImageSliderWrapperDesktop>
    );
  }
);

export { ProductImages };

ProductImages.displayName = "ProductImages";
