import { Icon } from "@/react-components/Icon";
import { Text } from "@/react-components/Text";
import { MessageContainer } from "./styles";

export const Message = ({ text }: { text: string }) => (
  <MessageContainer direction="row" spacing="6px">
    <span>
      <Icon name="Info" />
    </span>
    <Text as="span">{text}</Text>
  </MessageContainer>
);
