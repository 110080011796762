import * as React from "react";
import { LoadingCircle } from "../Common";
import type { ButtonProps } from "../../styled";
import { Button } from "../../styled";
import styled from "@emotion/styled/macro";
import { MQ } from "../../styles/helpers";

type LoadMoreButtonProps = {
  text: string;
  onClick: () => Promise<void>;
};

const ResponsiveButton = styled(Button)<ButtonProps>`
  max-width: 100%;

  ${MQ("tablet")} {
    max-width: 50%;
  }

  ${MQ("laptop")} {
    max-width: 25%;
  }
`;

export const LoadMoreButton: React.FunctionComponent<LoadMoreButtonProps> = ({
  text,
  onClick: triggerLoadMoreReviews,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const isComponentMounted = React.useRef(true);

  React.useEffect(() => {
    return (): void => {
      isComponentMounted.current = false;
    };
  }, []);

  const handleClick = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await triggerLoadMoreReviews();
    } finally {
      if (isComponentMounted.current) {
        setIsLoading(false);
      }
    }
  };

  return (
    <ResponsiveButton
      onClick={handleClick}
      disabled={isLoading}
      className="button button--secondary button--outlined button--small button--full-width"
    >
      <LoadingCircle isLoading={isLoading} />
      <span>{text}</span>
    </ResponsiveButton>
  );
};
