import { createContext, useContext } from "react";
import type {
  Product,
  SearchResultV2,
  SearchSuggestsGroup,
} from "./searchBoxHelper";
import {
  createProductResultGroup,
  isTwoColumn,
  MAX_NUMBER_OF_SUGGESTIONS,
  prepareColumnContent,
  pushResultsGroup,
  SearchSuggestionsTypeEnum,
  setContainerHeight,
} from "./searchBoxHelper";

export const SEARCH_BOX_REQUEST_SUCCESS = "SEARCH_BOX_REQUEST_SUCCESS";
export const SEARCH_BOX_CLEAR_RESULT = "SEARCH_BOX_CLEAR_RESULT";

export type AutoCompleteData = {
  categoryResults: SearchResultV2;
  brandResults: SearchResultV2;
  campaignHubResults: SearchResultV2;
  faqHubResults: SearchResultV2;
  guideResults: SearchResultV2;
  productResults: {
    items: Product[];
  };
  queries: {
    count: number;
    items?: {
      query: string;
    }[];
  };
  queryString: string;
  storeResults: SearchResultV2;
};

type State = {
  hasDoneFirstSearch: boolean;
  items?: SearchSuggestsGroup[];
  products?: SearchProductsGroup;
  isTwoColumns: boolean;
};

type StateStruct = {
  current: State;
  prev?: State;
};

type Action =
  | {
      type: typeof SEARCH_BOX_REQUEST_SUCCESS;
      payload: AutoCompleteData;
    }
  | {
      type: typeof SEARCH_BOX_CLEAR_RESULT;
    };

type DefaultValue = {
  state: State;
  dispatch: (action: Action) => void;
};

export type SearchSuggestion = {
  queryResult: string;
  url: string;
  ticket?: string;
};

type SearchProductsGroup = {
  highlightedPhrase: string;
  type: SearchSuggestionsTypeEnum;
  items: Product[];
};

const initialStaticState: State = {
  hasDoneFirstSearch: false,
  isTwoColumns: false,
};

export const getInitialState = (): StateStruct => ({
  current: initialStaticState,
});

const ReducerInternal = (state: State, action: Action): State => {
  switch (action.type) {
    case SEARCH_BOX_REQUEST_SUCCESS: {
      const allSearchResultGroups: SearchSuggestsGroup[] = [];
      const suggestionsResultArray: SearchSuggestion[] = [];

      const { payload: data } = action;
      const {
        queryString,
        queries,
        brandResults,
        campaignHubResults,
        faqHubResults,
        guideResults,
        storeResults,
        categoryResults,
      } = data;

      const containerHeight = 0;

      if (queries.count > 0 && queries.items !== undefined) {
        queries.items.forEach(({ query }, index) => {
          if (index < MAX_NUMBER_OF_SUGGESTIONS) {
            const url = `/search?query=${query}&sort=relevance`;
            const queryObj: SearchSuggestion = {
              queryResult: query,
              url,
            };
            suggestionsResultArray.push(queryObj);
          }
        });
      }

      const suggestionsResult = {
        highlightedPhrase: data.queryString,
        type: SearchSuggestionsTypeEnum.items,
        queries: suggestionsResultArray,
      };

      if (suggestionsResultArray.length > 0) {
        setContainerHeight(containerHeight, suggestionsResultArray);
        allSearchResultGroups.push(suggestionsResult);
      }

      pushResultsGroup(
        categoryResults,
        queryString,
        SearchSuggestionsTypeEnum.categories,
        allSearchResultGroups,
        containerHeight
      );
      pushResultsGroup(
        brandResults,
        queryString,
        SearchSuggestionsTypeEnum.brands,
        allSearchResultGroups,
        containerHeight
      );
      pushResultsGroup(
        storeResults,
        queryString,
        SearchSuggestionsTypeEnum.stores,
        allSearchResultGroups,
        containerHeight
      );
      pushResultsGroup(
        guideResults,
        queryString,
        SearchSuggestionsTypeEnum.guides,
        allSearchResultGroups,
        containerHeight
      );
      pushResultsGroup(
        faqHubResults,
        queryString,
        SearchSuggestionsTypeEnum.faq,
        allSearchResultGroups,
        containerHeight
      );
      pushResultsGroup(
        campaignHubResults,
        queryString,
        SearchSuggestionsTypeEnum.campaignHub,
        allSearchResultGroups,
        containerHeight
      );

      return {
        ...state,
        hasDoneFirstSearch: true,
        items: prepareColumnContent(allSearchResultGroups, containerHeight),
        products: createProductResultGroup(
          queryString,
          data.productResults.items
        ),
        isTwoColumns: isTwoColumn(containerHeight),
      };
    }
    case SEARCH_BOX_CLEAR_RESULT:
      return {
        hasDoneFirstSearch: false,
        items: [],
        isTwoColumns: false,
      };
    default:
      return state;
  }
};

export const reducer = (
  stateStruct: StateStruct,
  action: Action
): StateStruct => {
  return {
    prev: stateStruct.current,
    current: ReducerInternal(stateStruct.current, action),
  };
};

export const SearchBoxContext = createContext<DefaultValue>({
  dispatch: (): void => {
    return;
  },
  state: initialStaticState,
});

export const useSearchBoxContext = (): DefaultValue =>
  useContext(SearchBoxContext);
