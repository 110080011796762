import type { SizeOptionsComponent } from "@/components/MiniPdp/types";
import { useStateValue } from "cotton-box-react";
import type { MouseEvent, ReactNode } from "react";
import { DialogBox } from "react-app/src/components/DialogBox";
import { useSessionSource } from "react-app/src/contexts/Session";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useCampaignDataForPdp } from "react-app/src/hooks/useCampaignDataForPdp/useCampaignDataForPdp";
import { useProductData } from "react-app/src/hooks/useProductData/useProductData";
import type { PreSelectSizeStrategy } from "src/components/ProductDetailsPage/ProductSizeSelector/ProductSizeSelector";
import type { AddToCartButtonProps } from "./MiniPdpContent";
import { MiniPdpContent } from "./MiniPdpContent";

type Props = {
  addToCartButton: (props: AddToCartButtonProps) => ReactNode;
  handleClose: () => void;
  isOpen: boolean;
  isQuickShop: boolean;
  onChangeProductStyle: ({
    code,
    event,
  }: {
    code: string;
    event: MouseEvent;
  }) => void;
  preSelectedSizeCodeOrEan?: string;
  preSelectSizeStrategy?: PreSelectSizeStrategy;
  productCode: string;
  shouldBePossibleToChangeQuantity?: boolean;
  shouldBePossibleToSelectOutOfStock?: boolean;
  shouldEvaluateProductConfiguratorPrice: boolean;
  sizeOptionsComponent?: SizeOptionsComponent;
};

const MiniPdp = ({
  addToCartButton,
  handleClose,
  isOpen,
  isQuickShop,
  onChangeProductStyle,
  preSelectedSizeCodeOrEan,
  preSelectSizeStrategy,
  productCode,
  shouldBePossibleToChangeQuantity = true,
  shouldBePossibleToSelectOutOfStock = true,
  shouldEvaluateProductConfiguratorPrice,
  sizeOptionsComponent,
}: Props) => {
  const { t } = useTranslations();
  const productData = useProductData({
    onError: handleClose,
    productCode,
    shouldEvaluateProductConfiguratorPrice,
  });
  const { priceDisplay } = productData ?? {};
  const campaignInfo = useCampaignDataForPdp({ priceDisplay });
  const isLoggedIn = useStateValue(useSessionSource);

  return (
    <DialogBox
      dialogBoxHeadline={t("product.details.product.information")}
      isDialogBoxOpen={isOpen}
      handleDialogBoxClosing={handleClose}
      hasPadding={true}
      hideShadow={isQuickShop}
      dialogBoxSize="md"
    >
      <MiniPdpContent
        addToCartButton={addToCartButton}
        campaignInfo={campaignInfo}
        onChangeProductStyle={onChangeProductStyle}
        isQuickShop={isQuickShop}
        isLoggedIn={isLoggedIn}
        preSelectedSizeCodeOrEan={preSelectedSizeCodeOrEan}
        preSelectSizeStrategy={preSelectSizeStrategy}
        productData={productData}
        sizeOptionsComponent={sizeOptionsComponent}
        shouldBePossibleToChangeQuantity={shouldBePossibleToChangeQuantity}
        shouldBePossibleToSelectOutOfStock={shouldBePossibleToSelectOutOfStock}
      />
    </DialogBox>
  );
};

export { MiniPdp };
