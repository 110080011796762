import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ } from "../../styles/helpers";
import spaces from "../../styles/theme/spaces";
import { GRID_COLUMNS } from "../Filter/FilterBar/constants";

type ListWrapperProps = {
  columnAmount: number;
  isLoading: boolean;
};

export const fourGridMargin = "28px";
export const sixGridMargin = "30px";

export const ListWrapperBase = styled.ul<ListWrapperProps>`
  ${({ columnAmount, isLoading }) => css`
    display: grid;
    grid-gap: 7px;
    grid-template-columns: repeat(${columnAmount}, minmax(0, 1fr));
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    opacity: ${isLoading ? 0.3 : 1};
    transition: opacity ${isLoading ? ".150s" : "0s"};

    ${MQ("muiTablet")} {
      grid-gap: ${spaces.miniRegular};
    }

    ${MQ("laptop")} {
      grid-gap: ${spaces.smallRegular};
    }
  `}
`;

export const ListWrapper = styled(ListWrapperBase)<ListWrapperProps>(
  ({ columnAmount }) => css`
    ${MQ("laptop")} {
      ${columnAmount === GRID_COLUMNS.four
        ? `& > li:nth-of-type(n+5) {
          margin-top: -${fourGridMargin};
        }`
        : `& > li:nth-of-type(n+7) {
          margin-top: -${sixGridMargin};
        }
      `};
    }
  `
);
